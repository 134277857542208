import { AutoSelectAuthTemplate } from 'src/applications/Oversight/templates/AutoSelectAuthTemplate'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Card } from 'src/resources/elements/Card'
import { Header } from 'src/resources/elements/Header'
import NotFoundImage from 'src/resources/images/404.svg'
import { Spacing } from 'src/resources/layout'
import { OpenZendeskChat } from 'src/resources/sidebars/PlatformSidebar'
import styled from 'styled-components'

const NotFoundContainer = styled.div`
  padding: ${Spacing.basePadding8x};
  text-align: center;

  small a {
    color: ${Colors.brandBlue};
    opacity: 1;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }

  header {
    h2 {
      justify-content: center;
    }
  }

  p {
    margin-bottom: ${Spacing.basePadding3x};
  }

  img {
    margin-top: ${Spacing.basePadding6x};
  }
`

export const NotFoundPanel = ({ hideHomeLink = false }: { hideHomeLink?: boolean }) => (
  <Card>
    <NotFoundContainer>
      <Header>
        <h2>Page not found</h2>
        <p>
          <small>
            If you believe this was an error,
            <br /> please <a onClick={OpenZendeskChat}>let us know</a>
          </small>
        </p>
        {hideHomeLink && (
          <FlatButton renderAs='link' to='/'>
            Home
          </FlatButton>
        )}
      </Header>
      <img src={NotFoundImage} alt='404 Page not found' width='200px' />
    </NotFoundContainer>
  </Card>
)

export const NotFoundScene = () => (
  <AutoSelectAuthTemplate>
    <NotFoundPanel />
  </AutoSelectAuthTemplate>
)
