import { Redirect, withRouter } from 'react-router-dom'
import { BasicInfoContext } from 'src/contexts/BasicInfoContext'
import { ITeam } from 'src/types/interfaces/ITeam'

export const MainScene = withRouter(({ location: { search } }) => {
  const params = new URLSearchParams(search)
  const ref = params.get('ref')
  const dev = params.get('dev')

  if (ref) {
    return <Redirect to={ref} />
  }
  if (dev) {
    window.sessionStorage.setItem('dev', 'true')
  }

  return (
    <BasicInfoContext
      onTeamFoundRender={(team: ITeam) => {
        window.sessionStorage?.dev
          ? location.replace(`/sign-up/github`)
          : location.replace(`/a/${team.id}`)
        return null
      }}
    />
  )
})
