import { MouseEvent, useMemo, useState } from 'react'

import Confetti from 'react-confetti'
import { Portal } from 'react-portal'
import { ModalLayer } from 'src/applications/Oversight/layers'
import { modalHost } from 'src/applications/Oversight/modalHost'
import { Colors } from 'src/resources/colors'
import { Backdrop } from 'src/resources/elements/Backdrop'
import { ContentContainer } from 'src/resources/elements/ContentContainer'
import { Header } from 'src/resources/elements/Header'
import { Layer } from 'src/resources/elements/Layer'
import { Spacing } from 'src/resources/layout'
import { FullScreen } from 'src/resources/layouts/FullScreen'
import { fontSizes } from 'src/resources/typography'
import styled, { css, CSSProperties, keyframes } from 'styled-components'

import { Divider } from './Divider'
import { CloseIcon } from './Icons'

const ModalContainer = styled(FullScreen)<{ multi?: boolean }>`
  overflow: auto;
  z-index: ${ModalLayer};
  ${({ multi }) =>
    multi &&
    css`
      ${Modal} {
        margin-top: ${Spacing.basePadding4x};
        &:first-child {
          margin-top: ${Spacing.basePadding10x};
          margin-bottom: ${Spacing.basePadding4x};
        }
      }
    `}
`

const fadeInUp = keyframes`
 0% { opacity: 0; transform: translate(-50%,-50%) translateY(20px) }
 100% { opacity: 1; transform: translate(-50%,-50%) }
`

const Modal = styled.div<{
  fullscreen?: boolean
  verticalCenter?: boolean
  entranceAnimation?: boolean
}>`
  background: ${({ theme }) => theme?.modalBackgroundColor ?? Colors.white};
  border-radius: ${({ theme }) => theme?.borderRadius ?? '0.25em'};
  box-sizing: border-box;
  color: ${Colors.text};
  cursor: default;
  font-size: ${fontSizes.type15};
  font-weight: 400;
  min-width: 440px;
  position: relative;
  z-index: 3;
  transition: all 700ms ease;
  ${Header} {
    margin-bottom: ${Spacing.basePadding};
  }
  ${ContentContainer} {
    padding: 0;
    margin: 0 auto;
  }
  ${({ fullscreen }) =>
    fullscreen
      ? css``
      : css`
          margin: 80px auto;
          max-width: 90vw;
          padding: ${Spacing.basePadding3x};
        `}
  ${({ verticalCenter }) =>
    verticalCenter &&
    css`
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      margin: 0;
    `}
    
    ${({ entranceAnimation }) =>
    entranceAnimation &&
    css`
      opacity: 0;
      animation: ${fadeInUp} 0.3s 0.5s forwards ease;
    `}
`

export const CloseModal = styled.span`
  align-self: center;
  border-radius: 50%;
  bottom: 16px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: ${Spacing.basePadding2x};
  top: 16px;
  transition: background-color 0.1s ease-out;
  width: 24px;
  &:hover {
    background-color: ${Colors.gray20};
  }
`

const ContentArea = ({ contents, width }: { width?: string; contents(): JSX.Element }) => {
  if (width) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ width }}>{contents()}</div>
      </div>
    )
  }
  return contents()
}

export interface IModal {
  readonly isOpen: boolean
  close(): void
  closeImmediate(): void
  open(): void
  render(): JSX.Element
}

export const stopEventPropagation = (event: MouseEvent) => {
  event.stopPropagation()
}

export const useModal = ({
  backdropStyle,
  clickProtect,
  confetti = false,
  contentAreaWidth,
  divider = false,
  fullscreen,
  header,
  extraContentHeader,
  headerStyle,
  initialIsOpenState = false,
  minHeight,
  modalStyle,
  showCloseIcon = true,
  theme = {},
  welcome,
  width = '440px',
  zIndex = ModalLayer,
  contents,
  extraContents,
  onClose,
  showExtraContentCloseIcon = false
}: {
  backdropStyle?: CSSProperties
  clickProtect?: boolean
  confetti?: boolean
  contentAreaWidth?: string
  divider?: boolean
  fullscreen?: boolean
  header?: string | JSX.Element
  extraContentHeader?: string | JSX.Element
  headerStyle?: CSSProperties
  initialIsOpenState?: boolean
  minHeight?: string
  modalStyle?: CSSProperties
  showCloseIcon?: boolean
  theme?: any
  welcome?: boolean
  width?: string
  zIndex?: number
  contents(): JSX.Element
  extraContents?(): JSX.Element
  onClose?(): void | boolean
  showExtraContentCloseIcon?: boolean
}) => {
  const [isOpen, setIsOpen] = useState(initialIsOpenState)

  const self: IModal = useMemo(
    () => ({
      get isOpen() {
        return isOpen
      },
      close() {
        if (onClose) {
          if (onClose()) {
            self.closeImmediate()
          }
        } else {
          self.closeImmediate()
        }
      },
      closeImmediate() {
        setIsOpen(false)
      },
      open() {
        setIsOpen(true)
      },
      render() {
        if (!isOpen) {
          return null
        }

        return (
          <Portal node={modalHost}>
            <Layer style={{ zIndex }} onClick={clickProtect ? stopEventPropagation : undefined}>
              <Backdrop style={backdropStyle} />
              <ModalContainer multi={!!extraContents} data-testid='modal'>
                {confetti && (
                  <Confetti
                    colors={[
                      Colors.brandPrimary,
                      Colors.brandPrimaryMidnight,
                      Colors.brandSecondary,
                      Colors.brandAccent,
                      Colors.border
                    ]}
                    numberOfPieces={500}
                    tweenDuration={400}
                  />
                )}
                {(contents() || divider || header) && (
                  <Modal
                    style={{
                      width,
                      minHeight,
                      ...(modalStyle || {}),
                      ...(theme?.modalWrapperStyle ?? {})
                    }}
                    fullscreen={fullscreen}
                    verticalCenter={welcome}
                    entranceAnimation={welcome}
                    tabIndex={-1}
                  >
                    {showCloseIcon && (
                      <CloseModal
                        onClick={self.close}
                        style={{
                          position: fullscreen ? 'fixed' : 'absolute',
                          right: fullscreen && '24px',
                          top: fullscreen && '24px',
                          ...(theme?.closeModalButtonStyles ?? {})
                        }}
                      >
                        <CloseIcon />
                      </CloseModal>
                    )}

                    {header && (
                      <>
                        <ContentArea
                          width={contentAreaWidth}
                          contents={() => (
                            <Header
                              style={{
                                ...(contentAreaWidth ? { textAlign: 'center' } : {}),
                                ...(theme?.modalHeaderStyles ?? {})
                              }}
                            >
                              <h2
                                style={{
                                  ...(headerStyle ?? {}),
                                  ...(theme?.modalHeaderTitleStyles ?? {})
                                }}
                              >
                                {header}
                              </h2>
                            </Header>
                          )}
                        />
                      </>
                    )}
                    {divider && <Divider />}
                    <ContentArea contents={contents} width={contentAreaWidth} />
                  </Modal>
                )}
                {extraContents && (
                  <Modal style={{ width, minHeight, ...(modalStyle || {}) }}>
                    {showExtraContentCloseIcon && (
                      <CloseModal
                        onClick={self.close}
                        style={{
                          position: fullscreen ? 'fixed' : 'absolute',
                          right: fullscreen && '24px',
                          top: fullscreen && '24px'
                        }}
                      >
                        <CloseIcon />
                      </CloseModal>
                    )}
                    <ContentArea
                      width={contentAreaWidth}
                      contents={() => (
                        <>
                          {extraContentHeader && (
                            <Header>
                              <h2>{extraContentHeader}</h2>
                            </Header>
                          )}
                          {extraContents()}
                        </>
                      )}
                    />
                  </Modal>
                )}
              </ModalContainer>
            </Layer>
          </Portal>
        )
      }
    }),
    [extraContents, contents, onClose, isOpen, theme]
  )

  return self
}
