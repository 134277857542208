import { useContext, useEffect, useMemo, useRef, useState } from 'react'

import { DataChangeEvent, RowData } from '@turntable/core'
import { RowChangeDetailWithID } from '@turntable/core/dist/types/Row'
import { Table } from 'src/applications/Oversight/components/Table'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Spinner } from 'src/resources/elements/Spinner'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SQ_TEST_DATAHOOK_ROWS } from 'src/smart/queries/SQ_TEST_DATAHOOK_ROWS'
import { JsonSchemaProperty } from 'src/types/interfaces/ISchema'
import styled, { keyframes } from 'styled-components'

import { DataHookH4 } from './UpdatedEditDataHook'
import { Spacing } from 'src/resources/layout'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_DATAHOOK_CLOUDWATCH_LOGS } from 'src/smart/queries/SQ_DATAHOOK_CLOUDWATCH_LOGS'
import { EnvironmentContext } from 'src/contexts/EnvironmentContext'

const TableWrapper = styled.div`
  height: 194px;
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  overflow: hidden;
  margin-top: 8px;
`
const fadeDownIn = keyframes`
  0% {
    transform: translateY(-5px);
    opacity: 0;
    max-height: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    max-height: 1060px;
  }
`

const ResultWrapper = styled.div`
  background: ${Colors.purpleTestResult};
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%);
  border-radius: 8px;
  transform: translateY(-5px);
  opacity: 0;
  max-height: 0;
  animation: ${fadeDownIn} 0.3s 0.2s ease forwards;

  h4,
  h5,
  p {
    color: ${Colors.brandPrimary} !important;
  }
  h5 {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    line-height: 140%;
    margin-top: 6px;
  }
`

const RunTestButtonWrapper = styled.div`
  margin: 30px auto;
  justify-content: center;
  display: flex;
`

const SpinnerWrapper = styled.div`
  position: relative;
  height: 24px;
  margin-bottom: 12px;
`
const RunTestButtonContents = styled.div<{ disabled?: boolean }>`
  display: block;
  transition: 0.35s ease-in-out;
  height: 24px;

  ${({ disabled }) =>
    disabled &&
    `
    transform: translateY(-32px);
  `}
`

const RunTestButton = styled(FlatButton)<{ spinner?: boolean; disabled?: boolean }>`
  transition: max-width 0.5s ease-in-out, max-height 0.35s ease-in-out,
    border-radius 0.1s ease-in-out, transform 0.2s ease-in-out;
  overflow: hidden;
  &:hover {
    transform: translateY(-2px);
  }
  ${({ spinner }) =>
    spinner &&
    `
      max-height: 42px;
      max-width: 42px;
      border-radius: 50%;
  `}
`

const ResultWrapperContent = styled.div`
  padding: 30px;
`

const ResultH5 = styled.h5`
  margin-top: 20px;
  margin-bottom: -12px !important;
`

const CloseButton = styled.div<{ disabled?: boolean }>`
  position: absolute;
  background: #3b2fc9;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  top: 26px;
  right: 26px;
  color: #ebf0ff;
  font-size: 26px;
  line-height: 18px;
  text-align: center;
  transition: 0.35s ease-in-out;
  transform-origin: center;
  cursor: pointer;
  &:hover {
    transform: rotate(90deg);
  }
  ${({ disabled }) =>
    disabled &&
    `
      transform: scale(0);
  `}
`

const prepData = (data: RowData[], schema: { [key: string]: JsonSchemaProperty }) => {
  return data.map((row: RowData, index: number) => {
    const rawData = Object.keys(schema).map((item, column) => {
      return {
        [`${item}`]: row.cells[column].value
      }
    })
    return { rowId: index, rawData: Object.assign({}, ...rawData) }
  })
}

export const ConsoleLine = styled.div`
  margin-bottom: ${Spacing.halfBasePadding};
  position: relative;
  min-height: 15px;
`

export const LineNumber = styled.span<{ type: string }>`
  text-transform: uppercase;
  width: 65px;
  display: inline-block;
  ${({ type }) => {
    switch (type) {
      case 'error':
        return `color: ${Colors.dangerStrong};`
      case 'warn':
        return `color: ${Colors.warningStrong};`
      default:
        return `color: #1D2830;`
    }
  }}
`

export const ConsoleMessage = styled.span`
  margin-left: 6px;
`

export const ConsoleWrapper = styled.pre`
  background: white;
  padding: 12px 24px;
  overflow: scroll;
  max-height: 300px;
  margin-top: 24px;
  margin-bottom: 0;
  border-radius: 5px;
  color: ${Colors.text};
  p {
    margin-bottom: 4px;
    &:last-child {
      margin-top: 12px;
    }
  }
`
const ConsoleContainer = styled.div`
  position: relative;
`

const SpinnerContainer = styled.span<{ show?: boolean }>`
  position: absolute;
  top: 8px;
  right: 12px;
  white-space: pre;
  font-family: monospace;
  transition: 0.25s ease;
  opacity: 0;
  transform: translateY(-10px);
  pointer-events: none;
  ${({ show }) =>
    show &&
    ` opacity: 1;
      transform: translateY(0);`}
`

const parseLog = (log?: string) => {
  if (!log) return null
  // one array of all lines
  const logsByLines = log.split('\n')
  // only want FUNCTION related logs
  return logsByLines
}

const ConsoleDisplayer = ({ logs, loading }: { logs: string[]; loading: boolean }) => {
  const messagesEndRef = useRef(null)

  const scrollToTop = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTo({
        top: messagesEndRef.current.scrollHeight,
        behavior: 'smooth'
      })
    }
  }

  useEffect(() => {
    scrollToBottom()
  }, [])

  useEffect(() => {
    let timeout
    if (!loading) {
      timeout = setTimeout(scrollToTop, 500)
    }
    return clearTimeout(timeout)
  }, [loading])
  return (
    <ConsoleContainer>
      <ConsoleWrapper ref={messagesEndRef}>
        {logs?.map((line: any, index: number) => {
          if (line.startsWith('REPORT')) {
            return <p key={index}>{line}</p>
          }
          const metaMessage = line.startsWith('START') || line.startsWith('END')
          if (metaMessage) {
            return <p key={index}>{line.split(' ')[0]} LOGS</p>
          }
          try {
            const parsedLog = JSON.parse(line)
            return (
              <ConsoleLine key={index}>
                <LineNumber type={parsedLog.level}>[{parsedLog.level}]</LineNumber>
                <ConsoleMessage>
                  {typeof parsedLog.message === 'string'
                    ? parsedLog.message
                    : JSON.stringify(parsedLog.message)}
                </ConsoleMessage>
              </ConsoleLine>
            )
          } catch (clippedLogLine) {
            // OK to ignore these are clipped message lines returned in the logTail from AWS Lambda
            // We are backfilling these messages with the CloudWatch logs
            // This will happen with most logs, as even 5 lines hits the 3.5Kb logTail limit
          }
        })}
      </ConsoleWrapper>

      <SpinnerContainer show={loading}>
        <Spinner /> Fetching more logs...
      </SpinnerContainer>
    </ConsoleContainer>
  )
}

const Console = ({
  logTail,
  cloudWatchLog
}: {
  logTail: string
  cloudWatchLog: {
    logGroupName: string
    logStreamName: string
    requestId: string
    dataHookId: string
  }
}) => {
  const logs = parseLog(logTail)
  const logsComplete = logs[0].startsWith('START')
  const [timeoutState, setTimeoutState] = useState(false)
  const { result, state } = useSmartQuery(SQ_DATAHOOK_CLOUDWATCH_LOGS, {
    fetchPolicy: 'network-only',
    pollInterval: 500,
    variables: cloudWatchLog
  })

  const resultRef = useRef(result)
  resultRef.current = result
  useEffect(() => {
    if (!logsComplete) {
      const pollingTimout = setTimeout(() => {
        if (resultRef.current.logs.length === 0) {
          setTimeoutState(true)
          state.stopPolling()
        }
      }, 20000)
      return () => clearTimeout(pollingTimout)
    }
  }, [])

  if (timeoutState) {
    return <p>Timeout while fetching logs.</p>
  }
  if (!state.loading && state.error) {
    return <p>Error occured while fetching logs.</p>
  }

  if (result?.logs?.length) {
    state.stopPolling()
    const preppedLogs = result.logs.map((log) => log.message)
    return (
      <>
        <ConsoleDisplayer key={cloudWatchLog.requestId} logs={preppedLogs} loading={false} />
      </>
    )
  } else {
    return (
      <>
        <ConsoleDisplayer key={cloudWatchLog.requestId} logs={logs} loading={!logsComplete} />
      </>
    )
  }
}

export const ErrorMessageLog = ({ messages }: { messages: any[] }) => {
  return (
    <ConsoleWrapper>
      {messages.map((message, index) => (
        <ConsoleLine key={`error_line_${index}`}>
          <LineNumber type='error'>[ERROR]</LineNumber>
          <ConsoleMessage>
            {typeof message === 'object' ? JSON.stringify(message) : message}
          </ConsoleMessage>
        </ConsoleLine>
      ))}
    </ConsoleWrapper>
  )
}

export function DataHookTester({
  dataHookId,
  schema,
  deployed
}: {
  dataHookId: string
  deployed: boolean
  schema: { [key: string]: JsonSchemaProperty }
}) {
  const labels = Object.values(schema).map((item: any) => ({
    value: item.label
  }))

  const initTableData: RowData[] = [0, 1, 2, 3, 4].map((index) => ({
    _id: index,
    rowIndex: index,
    position: index,
    cells: labels.map((_, column) => ({ value: null, column })),
    validations: [],
    __typename: 'RowCells'
  }))

  const [rowEdits] = useState<RowData[] | null>(initTableData)
  const [resultRows, setResultRows] = useState<RowData[] | null>(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [requestId, setRequestId] = useState<string>()
  const [logTail, setLogTail] = useState<string>()
  const [cloudWatchLog, setCloudWatchLog] = useState<{
    logGroupName: string
    logStreamName: string
  }>()
  const [errorMessageLog, setErrorMessageLog] = useState<string>()

  const environment = useContext(EnvironmentContext)

  const testDataHookMutation = useSmartMutation(SQ_TEST_DATAHOOK_ROWS)

  const handleCellsChange = async ({ changes }: DataChangeEvent): Promise<DataChangeEvent> => {
    const updatedData = rowEdits

    updatedData[changes[0].rowIndex].cells[changes[0].patch[0].column].value = String(
      changes[0].patch[0].value
    )

    const updatedChanges = changes.map((c) => {
      const changeRow = updatedData.find((r) => r.rowIndex === c.rowIndex)
      const newChangeRow: RowChangeDetailWithID = {
        patch: changeRow.cells.map((cell, column) => ({
          value: cell.value,
          rowIndex: changeRow.rowIndex,
          column,
          position: column
        })),
        position: changeRow.rowIndex,
        rowIndex: changeRow.rowIndex,
        id: String(changeRow.rowIndex)
      }
      return newChangeRow
    })

    return { changes: updatedChanges }
  }

  const runTest = async () => {
    setLoading(true)
    const rows = prepData(rowEdits, schema)
    const updatedRows = await testDataHookMutation.run({
      environmentId: environment.id,
      id: dataHookId,
      rows
    })

    setLogTail(updatedRows.logTail)

    const formatedRows = updatedRows.rows.map((row) => {
      return {
        _id: row._id,
        position: row._id,
        rowIndex: row._id,
        validations: [],
        cells: Object.keys(schema).map((item) => ({
          value: row.cells[item].value
        }))
      }
    })

    if (updatedRows.logGroupName && updatedRows.logStreamName && updatedRows.requestId) {
      setRequestId(updatedRows.requestId)
      setCloudWatchLog({
        logGroupName: updatedRows.logGroupName,
        logStreamName: updatedRows.logStreamName
      })
    }
    if (updatedRows.error) {
      setErrorMessageLog(updatedRows.error)
    }

    setResultRows([...formatedRows])
    setTimeout(() => setLoading(false), 80)
  }

  const handleResultCellsChange = async ({
    changes
  }: DataChangeEvent): Promise<DataChangeEvent> => {
    const updatedData = resultRows
    updatedData[changes[0].rowIndex].cells[changes[0].patch[0].column].value = String(
      changes[0].patch[0].value
    )

    const edits = prepData(updatedData, schema)

    const updatedRows = await testDataHookMutation.run({
      environmentId: environment.id,
      id: dataHookId,
      rows: edits
    })

    const formatedRows = updatedRows.rows.map((row) => {
      return {
        _id: row._id,
        position: row._id,
        rowIndex: row._id,
        validations: [],
        cells: Object.keys(schema).map((key: string) => ({
          value: row.cells[key].value
        }))
      }
    })

    const updatedChanges = changes.map((c) => {
      const changeRow = formatedRows.find((r) => r.rowIndex === c.rowIndex)
      const newChangeRow: RowChangeDetailWithID = {
        patch: changeRow.cells.map((cell, column) => ({
          value: cell.value,
          rowIndex: changeRow.rowIndex,
          column,
          position: column
        })),
        position: changeRow.rowIndex,
        rowIndex: changeRow.rowIndex,
        id: String(changeRow.rowIndex)
      }
      return newChangeRow
    })

    return { changes: updatedChanges }
  }

  const ResultComponent = useMemo(() => {
    if (resultRows?.length) {
      return (
        <ResultWrapper>
          <ResultWrapperContent>
            <CloseButton disabled={loading} onClick={() => setResultRows(null)}>
              ⨯
            </CloseButton>
            <DataHookH4>Test result</DataHookH4>
            <h5>Data output</h5>
            <p>View the output from your data hook running against the sample data.</p>

            <TableWrapper>
              <Table
                tableId='results-table'
                columnConfig={labels}
                count={5}
                initData={resultRows}
                isLoading={loading}
                onCellsChange={handleResultCellsChange}
              />
            </TableWrapper>

            {errorMessageLog && (
              <>
                <ResultH5>Error Message:</ResultH5>
                <ErrorMessageLog messages={[errorMessageLog]} />
              </>
            )}

            {cloudWatchLog?.logGroupName && cloudWatchLog?.logStreamName && requestId && (
              <>
                <ResultH5>Output Logs:</ResultH5>
                <Console
                  logTail={logTail}
                  cloudWatchLog={{ ...cloudWatchLog, requestId, dataHookId }}
                  key={requestId}
                />
              </>
            )}
          </ResultWrapperContent>
        </ResultWrapper>
      )
    }
  }, [resultRows, loading, requestId])

  if (!deployed) {
    return (
      <>
        <DataHookH4>Test your Data Hook</DataHookH4>
        <p>
          Your data hook hasn't finished deploying yet... Hang tight and we'll be able to run tests
          soon!
        </p>
      </>
    )
  }

  return (
    <>
      <DataHookH4>Test your DataHook</DataHookH4>
      <p>Type your sample data into the appropriate cells, and then run the test.</p>

      <TableWrapper>
        <Table
          tableId='init-table'
          columnConfig={labels}
          count={5}
          initData={initTableData}
          onCellsChange={handleCellsChange}
        />
      </TableWrapper>
      <RunTestButtonWrapper>
        <RunTestButton onClick={runTest} disabled={!rowEdits} spinner={loading}>
          <RunTestButtonContents disabled={!loading}>
            <SpinnerWrapper>
              <Spinner color={'#fff'} />
            </SpinnerWrapper>

            <span>{resultRows ? 'Re-Run Test Data' : 'Run Test Data'}</span>
          </RunTestButtonContents>
        </RunTestButton>
      </RunTestButtonWrapper>

      {ResultComponent}
    </>
  )
}
