import {
  ChangeEvent,
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
  FocusEvent
} from 'react'

import Editor from '@monaco-editor/react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { SchemaContext } from 'src/contexts/SchemaContext'
import { Colors } from 'src/resources/colors'
import { ButtonBase, FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { FlatInput, InputLabel } from 'src/resources/elements/form/Input'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontMonospace, fontSizes } from 'src/resources/typography'
import {
  getEditorSampleCode,
  HOOK_TEMPLATES,
  showAutoCompletion
} from 'src/resources/utils/monacoEditorUtils'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_UPDATE_DATAHOOK } from 'src/smart/mutations/SM_UPDATE_DATAHOOK'
import { UpdateDataHook_updateDataHook_dataHook } from 'src/smart/mutations/types/UpdateDataHook'
import { EDeploymentState } from 'src/types/enums/EDeploymentState'
import { VendorSplitFlagNames } from 'src/vendor/vendorSplit'
import styled from 'styled-components'

import { useTeamSplitFlag } from '../hooks/useTeamSplitFlag'
import { DataHookTester } from './DataHookTester'
import { Dependency, DependencyManager } from './DependencyManager'
import { VariablesTray } from './VariablesTray'
import { BooleanInput } from 'src/resources/elements/form/BooleanInput'
import { useTeamRootUrl } from '../hooks/useTeamRootUrl'
import useReactRouter from 'use-react-router'
import AutoResizingTextArea from 'src/resources/elements/AutoResizingTextArea'
import { TimeAgo, TimeStamp } from './TimeAgo'
import { StatusPill } from './StatusPill'
import { useLocalStorageState } from 'src/resources/hooks/useLocalStorageState'
import { Tooltip } from './Tooltip'
import { Transitions } from 'src/resources/animations/transitions'
import { StatusWrapper } from 'src/resources/elements/Status'
import { TeamContext } from 'src/contexts/TeamContext'
import { storage } from 'src/resources/utils/storage'

const Grow = styled.div`
  flex-grow: 1;
  min-height: 600px;
  padding: ${Spacing.basePadding4x} ${Spacing.basePadding4x} 0;
  position: relative;

  ${InputLabel} {
    margin: 0;
    padding: 16px 62px;
    text-transform: initial;
  }
`

const DependenciesPanel = styled.div`
  padding: ${Spacing.basePadding4x};
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${Spacing.basePadding4x};
  max-width: 100%;
`

const InfoPanel = styled.div`
  padding: ${Spacing.basePadding4x} ${Spacing.basePadding4x} 0;
  h3 {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.675;
    margin: 0;
    color: ${Colors.blackLight};
  }
`

const InputContainer = styled.div`
  margin-bottom: ${Spacing.basePadding2x};
  label {
    margin-top: 0;
  }
  & + & {
    padding-top: 0;
  }
  code {
    font-family: ${fontMonospace};
  }
  ${ButtonBase} {
    margin-right: ${Spacing.basePadding};
  }
`

export const DataHookH4 = styled.h4`
  font-family: ${fontFamily.fontPrimary};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  padding-bottom: 8px;
  /* identical to box height */
  margin: 0;
  color: black;
  -webkit-font-smoothing: antialiased;
`

function distinct(a: string | null, b: string | null) {
  if (a === null || a === '') {
    return b !== null && b !== ''
  }
  return a !== b
}

const InfoPanelHeader = styled.div`
  padding: 12px 32px;
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  position: sticky;
  top: 0;
  z-index: 1;
  h3 {
    font-size: 1.125rem;
    font-weight: 600;
    line-height: 1.5;
    margin: 0;
    color: ${Colors.text};
    flex: 1;
  }
  button {
    margin-left: ${Spacing.basePadding2x};
    font-size: ${fontSizes.type12};
  }
`

const RevertButton = styled.span`
  color: ${Colors.brandPrimary};
  cursor: pointer;
  margin-left: ${Spacing.halfBasePadding};
  &:hover {
    opacity: 0.9;
  }
`
const FloatRight = styled.div`
  display: flex;
`

const SpacedMessage = styled.div`
  flex: 1;
`
const ChangesMessage = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  color: #6b7280;
  font-size: 14px;
  ${StatusWrapper} {
    margin-right: ${Spacing.basePadding};
  }
  ${TimeStamp} {
    flex: 1;
  }
`

const FlatInputTextArea = styled(FlatInput).attrs({ as: 'textarea' })`
  height: 100px;
  resize: vertical;
`

const StyledEditor = styled(Editor)`
  overflow: hidden;
`

const TemplateCopyContainer = styled.div`
  padding: 12px 0;
  > * {
    margin-right: 8px;
  }
`
const TitleWrapper = styled.div<{}>`
  position: relative;
`

const StatusLine = styled.div<{}>`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const ErrorBar = styled.div<{ visible?: boolean }>`
  background: #ffe8e6;
  color: #d33907;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: ${({ visible }) => (visible ? '40px' : '0')};
  transition: ${Transitions.baseEase};
  z-index: 1;
  overflow: hidden;
  p {
    font-size: 14px;
    padding: 4px 12px;
    margin: 0;
  }
`
// TODO: move to helper method file
const prepDependencies = (dependencies: Dependency[]) => {
  if (!dependencies) {
    return {}
  }

  const dependencyObj: { [x: string]: string } = {}

  dependencies.forEach((i) => {
    dependencyObj[`${i.name}`] = i.version
  })

  return dependencyObj
}

// TODO: move to helper method file
const unPrepDependencies = (dependencies: { [x: string]: string }[]) => {
  if (!dependencies) {
    return []
  }

  return Object.entries(dependencies).map((dep) => {
    return { name: dep[0], version: dep[1] }
  })
}

const VariablesToggle = styled.h4`
  color: ${Colors.brandPrimary};
  position: absolute;
  top: 26px;
  right: 50px;
  cursor: pointer;
  font-weight: 500;
  margin: ${Spacing.basePadding} 0;
  font-size: ${fontSizes.type16};
`

const EditorWrapper = styled.div`
  position: relative;
  clear: both;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%);
  padding-top: 30px;
  background: #1e1e1e;
`

interface IMarker {
  owner: string
  severity: number
  code?:
    | string
    | {
        value: string
      }
  message: string
  source?: string
  startLineNumber: number
  startColumn: number
  endLineNumber: number
  endColumn: number
}

const prepResource = (resource: UpdateDataHook_updateDataHook_dataHook) => {
  const unPrepDeps = unPrepDependencies(resource.packageJSON.dependencies)
  return {
    id: resource.id,
    name: resource.name,
    archived: resource.archived,
    code: resource.code,
    description: resource.description,
    deploymentState: resource.deploymentState,
    updatedAt: resource.updatedAt,
    dependencies: unPrepDeps as unknown as Dependency[]
  }
}

const unPrepResource = (
  resource: {
    dependencies: Dependency[]
    id: string
    name: string
    archived: boolean
    code: string
    description: string
    deploymentState: number
    updatedAt: string
  },
  editorLocal?: string,
  dependenciesLocal?: Dependency[]
) => {
  const code = !!editorLocal && editorLocal !== resource.code ? editorLocal : resource.code
  const dependencies =
    !!dependenciesLocal && dependenciesLocal !== resource.dependencies
      ? dependenciesLocal
      : resource.dependencies

  const packageJSON = JSON.stringify({
    dependencies: prepDependencies(dependencies)
  })

  return {
    id: resource.id,
    name: resource.name,
    archived: resource.archived,
    code,
    description: resource.description,
    deploymentState: resource.deploymentState,
    updatedAt: resource.updatedAt,
    packageJSON
  }
}

export function UpdatedEditDataHook({
  resource,
  refetchHooks
}: {
  resource: UpdateDataHook_updateDataHook_dataHook
  refetchHooks: (id?: string) => void
}) {
  const team = useContext(TeamContext)
  const teamRoot = useTeamRootUrl()
  const { history } = useReactRouter()
  const schema = useContext(SchemaContext)
  const SCHEMA_DEPLOYED_FROM_SDK = !!schema.slug
  const codeContainer = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)
  const [variablesVisible, setVariablesVisible] = useState(false)
  const [codeErrors, setCodeErrors] = useState(0)

  const updateDataHook = useSmartMutation(SM_UPDATE_DATAHOOK)
  const dataHookTesting =
    useTeamSplitFlag(VendorSplitFlagNames.DataHookTesting) ||
    team.featureFlags?.V3_DATA_HOOKS_TESTING

  const enableDataHookEditorForPlatformSDKDeployments =
    useTeamSplitFlag(VendorSplitFlagNames.EnableDataHookEditorForPlatformSDKDeployments) ||
    team.featureFlags?.ENABLE_DATA_HOOKS_EDITOR_FOR_PLATFORM_SDK_DEPLOYMENTS

  const [resourceState, setResourceState] = useState(prepResource(resource))

  const [editorLocal, setEditorLocal, removeEditorLocal] = useLocalStorageState(
    `dataHook-${resource.id}-code`,
    null
  )
  const [dependenciesLocal, setDependenciesLocal, removeDependenciesLocal] = useLocalStorageState(
    `dataHook-${resource.id}-dependencies`,
    null
  )

  const firstPropName = useMemo(() => {
    return Object.keys(schema.jsonSchema?.schema?.properties ?? {})[0] ?? 'example'
  }, [schema])

  const editorString = useMemo(() => {
    return getEditorSampleCode(firstPropName, 'default')
  }, [firstPropName])

  useEffect(() => {
    if (hasChanges) {
      saveAndRedirect()
    }
  }, [resourceState.archived])

  useEffect(() => {
    const preppedResource = prepResource(resource)
    setResourceState(preppedResource)
  }, [resource.id, resource.deploymentState])

  const hasChanges = useMemo(() => {
    return [
      resource.archived !== resourceState.archived,
      distinct(resource.description, resourceState.description),
      distinct(resource.name, resourceState.name)
    ].some((x) => x)
  }, [resourceState, resource.id])

  const deployChanges = useMemo(() => {
    return !!editorLocal || !!dependenciesLocal
  }, [resource.id, editorLocal, dependenciesLocal])

  useEffect(() => {
    refetchHooks(resource.id)
  }, [deployChanges])

  const autoSave = async (e?: FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>) => {
    if (hasChanges && e?.relatedTarget.id !== 'deploy-button') {
      const { dataHook } = await updateDataHook.run(unPrepResource(resourceState))
      if (!!editorLocal) {
        storage(`dataHook-${dataHook.id}-code`).set(editorLocal)
        removeEditorLocal()
      }
      if (!!dependenciesLocal) {
        storage(`dataHook-${dataHook.id}-dependencies`).set(dependenciesLocal)
        removeDependenciesLocal()
      }
      await refetchHooks(dataHook.id)
    }
  }

  const revert = useCallback(() => {
    setEditorLocal(null)
    setDependenciesLocal(null)
    removeEditorLocal()
    removeDependenciesLocal()
    setResourceState(prepResource(resource))
  }, [])

  const saveAndRedirect = async () => {
    if (codeErrors === 0) {
      const { schema: newSchema, dataHook } = await updateDataHook.run(
        unPrepResource(resourceState, editorLocal, dependenciesLocal)
      )

      if (schema.id !== newSchema.id) {
        removeEditorLocal()
        removeDependenciesLocal()
        history.replace(
          `${teamRoot}/templates/${newSchema.id}?tab=data-hooks&dataHookId=${dataHook.id}`
        )
      } else {
        await refetchHooks(dataHook.id)
      }
    }
  }

  useLayoutEffect(() => {
    if (codeContainer.current) {
      setHeight(codeContainer.current.clientHeight)
    }
  }, [setHeight, codeContainer])

  const changeNameArea = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      setResourceState({ ...resourceState, name: e.target.value })
    },
    [resourceState]
  )

  const changeDescription = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setResourceState({ ...resourceState, description: e.target.value })
  }, [])

  const changeArchived = (bool: boolean) => {
    setResourceState({ ...resourceState, archived: bool })
  }

  const changeCode = (functionString: string) => {
    const codeUpdates =
      functionString !== getEditorSampleCode(firstPropName, 'default') ||
      functionString !== resource.code
        ? functionString
        : ''
    setEditorLocal(codeUpdates)
  }

  const addDependency = useCallback((dependency: Dependency) => {
    const dependencyUpdates = [...resourceState.dependencies, dependency]
    setDependenciesLocal(dependencyUpdates)
  }, [])

  const removeDependency = useCallback((dependencyName: string) => {
    const dependencyUpdates = resourceState.dependencies.filter(
      (dependency) => dependency.name !== dependencyName
    )
    setDependenciesLocal(dependencyUpdates)
  }, [])

  const handleEditorWillMount = (monaco: any) => {
    showAutoCompletion({ record: { values: schema.jsonSchema?.schema?.properties } }, monaco)
  }

  const HandleBoolean = () => {
    changeArchived(!resourceState.archived)
  }

  const handleEditorValidation = (markers: IMarker[]) => {
    const errorMarkers = markers.filter((marker) => marker.severity === 8)
    setCodeErrors(errorMarkers.length)
  }

  return (
    <Wrap>
      <InfoPanelHeader>
        <TitleWrapper>
          <AutoResizingTextArea
            key={resource.id}
            onChange={changeNameArea}
            autoSave={autoSave}
            placeholder={resourceState.name || 'Untitled'}
            value={resourceState.name ?? ''}
            readonly={SCHEMA_DEPLOYED_FROM_SDK}
          />
        </TitleWrapper>
        <StatusLine>
          <ChangesMessage>
            <StatusPill
              status={resource.deploymentState}
              hasChanges={hasChanges || deployChanges}
              archived={resource.archived}
            />
            {hasChanges || deployChanges ? (
              <SpacedMessage>
                Changes pending.
                {deployChanges && <RevertButton onClick={revert}>Dismiss changes?</RevertButton>}
              </SpacedMessage>
            ) : (
              <TimeAgo
                key={resource.updatedAt}
                ago={resource.updatedAt}
                message={
                  resourceState.deploymentState === EDeploymentState.DEPLOYING
                    ? 'Started deploying'
                    : 'Saved'
                }
              />
            )}
            {SCHEMA_DEPLOYED_FROM_SDK && (
              <FloatRight>
                <StatusPill status={EDeploymentState.SDK} />
                <StatusPill status={EDeploymentState.READONLY} />
              </FloatRight>
            )}
          </ChangesMessage>

          <BooleanInput
            label='Active'
            value={!resourceState.archived}
            onClick={HandleBoolean}
            small
          />
          {!SCHEMA_DEPLOYED_FROM_SDK && (
            <FlatButton
              onMouseDown={(e: any) => e.preventDefault()}
              id='deploy-button'
              onClick={saveAndRedirect}
              data-for='error-message'
              variant={codeErrors > 0 ? 'outlined' : 'default'}
              data-tip={'you have lots of errors'}
              style={{ cursor: codeErrors > 0 ? ' not-allowed' : 'pointer' }}
            >
              {codeErrors > 0 ? "Can't deploy" : 'Deploy'}

              {codeErrors > 0 && (
                <Tooltip
                  place='left'
                  offset={{ top: 0, left: 0 }}
                  id='error-message'
                  content={`Please fix any errors in your code before trying to deploy.`}
                />
              )}
            </FlatButton>
          )}
        </StatusLine>
      </InfoPanelHeader>
      {!SCHEMA_DEPLOYED_FROM_SDK && (
        <InfoPanel>
          <InputContainer>
            <InputLabel>Description</InputLabel>
            <FlatInputTextArea
              fullWidth
              onChange={changeDescription}
              onBlur={autoSave}
              value={resourceState.description ?? ''}
            />
          </InputContainer>
        </InfoPanel>
      )}

      {!SCHEMA_DEPLOYED_FROM_SDK && (
        <DependenciesPanel>
          <DataHookH4>Dependencies</DataHookH4>
          <p>Add dependencies to your Data Hook (optional).</p>

          <DependencyManager
            add={addDependency}
            remove={removeDependency}
            dependencies={dependenciesLocal ?? resourceState.dependencies}
          />
        </DependenciesPanel>
      )}

      {(!SCHEMA_DEPLOYED_FROM_SDK || enableDataHookEditorForPlatformSDKDeployments) && (
        <Grow ref={codeContainer}>
          <DataHookH4>Javascript</DataHookH4>
          <VariablesToggle onClick={() => setVariablesVisible(!variablesVisible)}>
            {variablesVisible ? 'Hide Variables' : 'Show Variables'}
          </VariablesToggle>
          <EditorWrapper>
            <ErrorBar visible={!!codeErrors}>
              <p>
                You have {codeErrors} syntax error{codeErrors > 1 && 's'} that need
                {codeErrors === 1 && 's'} to be addressed before we can deploy your code
              </p>
            </ErrorBar>
            <StyledEditor
              height={`${height - 48}px`}
              language='javascript'
              onChange={changeCode}
              value={
                !!editorLocal
                  ? editorLocal
                  : !!resourceState.code
                  ? resourceState.code
                  : editorString
              }
              beforeMount={handleEditorWillMount}
              defaultValue={editorString}
              theme='vs-dark'
              // onMount={handleEditorDidMount}
              onValidate={handleEditorValidation}
              options={{
                minimap: {
                  enabled: false
                }
              }}
            />
            <VariablesTray
              visible={variablesVisible}
              properties={schema.jsonSchema?.schema?.properties}
            />
          </EditorWrapper>
          {!SCHEMA_DEPLOYED_FROM_SDK && (
            <TemplateCopyContainer>
              <span>Copy code from:</span>
              {HOOK_TEMPLATES.map((template, i) => {
                return (
                  <CopyToClipboard
                    key={i}
                    text={getEditorSampleCode(firstPropName, template.type)}
                  >
                    <FlatButton title={template.description}>{template.label}</FlatButton>
                  </CopyToClipboard>
                )
              })}
            </TemplateCopyContainer>
          )}
        </Grow>
      )}
      {dataHookTesting && (
        <InfoPanel>
          <DataHookTester
            deployed={resource.deploymentState === EDeploymentState.DEPLOY_SUCCESS}
            dataHookId={resource.id}
            schema={schema.jsonSchema?.schema?.properties}
          />
        </InfoPanel>
      )}
    </Wrap>
  )
}
