import { ApolloQueryResult } from '@apollo/client'
import { FLATFILE_REFINERY_URL } from 'src/config'
import { GET_CURRENT_USER } from 'src/queries/GET_CURRENT_USER'
import { CurrentUser } from 'src/queries/types/CurrentUser'
import {
  accessTokenStorage,
  graphClient,
  updateClientLink,
  superAdminAccessTokenStorage
} from 'src/resources/clients/graphClient'
import { schedule } from 'src/resources/utils/schedule'
import { IUser } from 'src/types/interfaces/IUser'
import { identifyPosthog } from 'src/resources/utils/analytics'

const ApiUserLoginUrl = `${FLATFILE_REFINERY_URL}/user-login`
const SuperAdminLoginUrl = `${FLATFILE_REFINERY_URL}/super-admin-login`

export const UserTransceiverRequestFailure = new Error('request failure')
export const UserTransceiverAuthenticationFailure = new Error('authentication failure')

type IAuthenticationResponse =
  | {
      access_token: string
    }
  | { statusCode: number; error: string }

export const UserTransceiver = {
  async mock(): Promise<IUser> {
    await schedule('1.0s')
    return {
      email: 'fake@name.com',
      id: '0',
      name: 'Fake Name'
    }
  },

  async current(): Promise<CurrentUser> {
    const response: ApolloQueryResult<CurrentUser> = await graphClient.query({
      query: GET_CURRENT_USER
    })

    return response.data
  },

  hasToken() {
    return accessTokenStorage.exists()
  },

  logout() {
    accessTokenStorage.clear()
    superAdminAccessTokenStorage.clear()
  },

  async loginRequest(options: {
    password: string
    rememberMe: boolean
    username: string
  }): Promise<Response | { ok: false; error: Error }> {
    try {
      const body =
        `username=${encodeURIComponent(options.username?.toLowerCase() ?? '')}&` +
        `password=${encodeURIComponent(options.password ?? '')}&` +
        `rememberMe=${options.rememberMe ? 'on' : 'off'}`
      return await fetch(ApiUserLoginUrl, {
        body,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST'
      })
    } catch (e) {
      return { ok: false, error: e }
    }
  },

  async loginAsSuperAdmin({
    superAdminId,
    targetUserEmail
  }: {
    superAdminId: string
    targetUserEmail: string
  }): Promise<Response | { ok: false; error: Error }> {
    try {
      const body = new URLSearchParams({
        superAdminId,
        targetUserEmail
      }).toString()

      return await fetch(SuperAdminLoginUrl, {
        body,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST'
      })
    } catch (e) {
      return { ok: false, error: e }
    }
  },

  async login(
    options:
      | {
          password: string
          rememberMe: boolean
          username: string
          type: 'password'
        }
      | { superAdminId: string; targetUserEmail: string; type: 'superAdmin' }
  ): Promise<IUser> {
    let response
    if (options.type === 'superAdmin') {
      const { superAdminId, targetUserEmail } = options
      response = await this.loginAsSuperAdmin({ superAdminId, targetUserEmail })
    } else {
      const { rememberMe, username, password } = options
      response = await this.loginRequest({ rememberMe, username, password })
    }

    if (response.ok) {
      const body: IAuthenticationResponse = await response.json()

      if ('error' in body) {
        throw new Error(`authentication failed: HTTP ${body.statusCode}: ${body.error}`)
      }

      if (options.type === 'superAdmin') {
        superAdminAccessTokenStorage.set(accessTokenStorage.get())
      }

      const { access_token } = body

      updateClientLink(access_token)

      const currentUserData = await UserTransceiver.current()
      identifyPosthog(
        { email: currentUserData.me.email, user_id: currentUserData.me.id },
        'login - frontend'
      )
      return currentUserData.me as IUser
    } else if (response.status === 401) {
      throw UserTransceiverAuthenticationFailure
    } else {
      // eslint-disable-next-line no-console
      console.error(response)
      throw UserTransceiverRequestFailure
    }
  },

  async restoreSuperAdminSession() {
    const superAdminAccessToken = superAdminAccessTokenStorage.get()
    if (!superAdminAccessToken) return

    accessTokenStorage.set(superAdminAccessToken)
    superAdminAccessTokenStorage.clear()
    window.location.assign('/')
  }
}
