import { useCallback, useContext } from 'react'

import { useToasts } from 'react-toast-notifications'
import { EnvironmentContext, RefetchEnvironmentContext } from 'src/contexts/EnvironmentContext'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup, ButtonWrap } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, IFormSubmitEvent, TForm } from 'src/resources/elements/form/Form'
import { Select, SelectOption } from 'src/resources/elements/form/Select'
import { ContentHeader } from 'src/resources/elements/Header'
import { SubContentContainer } from 'src/resources/elements/SubContentContainer'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_UPDATE_ENVIRONMENT } from 'src/smart/mutations/SM_UPDATE_ENVIRONMENT'
import { UpdateEnvironment_updateEnvironment_webhook } from 'src/smart/mutations/types/UpdateEnvironment'
import { HelpSection, URLTestFormInput } from 'src/applications/Oversight/forms/EditEmbedForm'

type IEditWebhook = Pick<UpdateEnvironment_updateEnvironment_webhook, 'subscribedEvents' | 'url'>

const EditWebhookForm: TForm<IEditWebhook> = Form

const ALL: SelectOption = { label: 'All events', value: 'all' }

export function EditWebhookPanel() {
  const { addToast } = useToasts()
  const environment = useContext(EnvironmentContext)
  const refetchEnvironment = useContext(RefetchEnvironmentContext)
  const updateEnvironment = useSmartMutation(SM_UPDATE_ENVIRONMENT)
  const handleSubmit = useCallback(
    async (event: IFormSubmitEvent<IEditWebhook>) => {
      try {
        await updateEnvironment.run({
          input: {
            id: environment.id,
            webhook: event.data
          }
        })
        addToast('Successfully updated webhook', {
          appearance: 'success',
          autoDismiss: true
        })
        refetchEnvironment()
      } catch (e) {
        addToast('Failed to update webhook', {
          appearance: 'error',
          autoDismiss: true
        })
      }
    },
    [environment, updateEnvironment]
  )

  return (
    <SubContentContainer>
      <EditWebhookForm
        disableIfUnchanged
        initialValue={environment.webhook}
        onSubmit={handleSubmit}
      >
        <ContentHeader
          header='Manage webhook'
          description='Configure a URL to receive HTTP POST requests every time a subscribed event occurs'
        />
        <URLTestFormInput label='Webhook URL' name='url' />
        <ErrorMessage name='url' />
        <Select
          isMulti
          label='Subscribed Events'
          name='subscribedEvents'
          revealDelete
          allOptionsSelectedValue={ALL.value}
          options={[
            ALL,
            { label: 'Record status modified (Portal 3.0 and Workspaces)', value: 'recordStatus' },
            { label: 'New records added (Workspaces)', value: 'workspacesNewRecords' },
            { label: 'Import submitted (Portal 3.0)', value: 'portalImportSubmitted' }
          ]}
        />
        <ErrorMessage name='subscribedEvents' />
        <ButtonWrap>
          <ButtonGroup left>
            <FormButton secondary reset>
              Revert
            </FormButton>
            <FormButton primary submit>
              Save changes
            </FormButton>
          </ButtonGroup>
        </ButtonWrap>
      </EditWebhookForm>
      <HelpSection
        style={{ marginTop: '1rem' }}
        text='Need help? Visit our '
        url='https://flatfile.com/docs/rest-webhooks-3-0/'
        linkMessage='documentation.'
      />
    </SubContentContainer>
  )
}
