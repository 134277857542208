/* Used for grouping transactions for performance tracking via Sentry */

export const trimMatchPath = (pathname: string) => {
  return pathname
    .replace(/^\/a\/\d+\/env\/[^\/]+/, '/a/:teamId/env/:slug')
    .replace(/\/workspaces\/[^\/]+/, '/workspaces/:workspaceId')
    .replace(/\/uploads\/[^\/]+/, '/uploads/:uploadId')
}

export const trimTransactionName = (pathname: string) => {
  if (pathname.includes('/match')) {
    return trimMatchPath(pathname)
  }

  if (pathname.includes('/templates')) {
    return pathname.replace(
      /^\/a\/\d+\/env\/[^\/]+\/templates\/.*/,
      '/a/:teamId/env/:slug/templates'
    )
  }

  if (pathname.startsWith('/sign-up')) {
    return '/sign-up'
  }

  return pathname
}
