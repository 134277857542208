import { MockedFeaturesMap } from '@splitsoftware/splitio/types/splitio'
import { ConfigVariableNames } from 'src/config'
import { localStorageOverride } from 'src/utils/localStorageOverride'

export enum VendorSplitFlagNames {
  CreateTemplatesFromUI = 'CreateTemplatesFromUI',
  DataHookTesting = 'DataHookTesting',
  DefaultToPlatformSDK = 'DefaultToPlatformSDK',
  DownloadLabelsXlsx = 'download_labels_xlsx',
  EmbedCustomization = 'v3_customization',
  EmbedWhitelabel = 'whitelabel',
  EnableDataHookEditorForPlatformSDKDeployments = 'EnableDataHookEditorForPlatformSDKDeployments',
  ExportSheetButton = 'export_sheet_button',
  FlexportStyling = 'Custom_Styling_Flexport',
  GithubAuth = 'github_auth',
  GraphQLMutations = 'Feature_Concierge_GraphQL',
  HideCollaborators = 'HideCollaborators',
  HideDropFile = 'HideDropFile',
  ImportTimeline = 'Feature_Import_Timeline',
  LegacyWebhookURL = 'legacy_webhook_url',
  MergeDuplicates = 'Merge_Duplicates',
  PortalFreeTrial = 'portal_free_trial',
  Relational = 'Relational',
  RelationalSelfLinkedTemplates = 'RelationalSelfLinkedTemplates',
  EnableSchemaEditorForPlatformSDKDeployments = 'EnableSchemaEditorForPlatformSDKDeployments',
  Webhook = 'Webhook',
  Workspaces = 'workspaces_access',
  WorkspaceSftp = 'Feature_Concierge_SFTP',
  WorkspaceSheetApi = 'Feature_Concierge_Sheet_API'
}

interface ISplitFlagNames {
  [key: string]: VendorSplitFlagNames
}

export const defaultVendorSplitTreatments: { [key: string]: string } = {
  [VendorSplitFlagNames.CreateTemplatesFromUI]: 'on',
  [VendorSplitFlagNames.DefaultToPlatformSDK]: 'off',
  [VendorSplitFlagNames.GithubAuth]: 'off',
  [VendorSplitFlagNames.GraphQLMutations]: 'off',
  [VendorSplitFlagNames.HideCollaborators]: 'off',
  [VendorSplitFlagNames.HideDropFile]: 'off',
  [VendorSplitFlagNames.ExportSheetButton]: 'off',
  [VendorSplitFlagNames.ImportTimeline]: 'off',
  [VendorSplitFlagNames.PortalFreeTrial]: 'on',
  [VendorSplitFlagNames.WorkspaceSheetApi]: 'off',
  [VendorSplitFlagNames.LegacyWebhookURL]: 'off',
  [VendorSplitFlagNames.FlexportStyling]: 'off'
}

const vendorSplitLocalhostModeTreatmentOverride = (key: string) =>
  localStorageOverride(
    key,
    defaultVendorSplitTreatments[(VendorSplitFlagNames as ISplitFlagNames)[key]] || 'off',
    {
      nestUnder: ConfigVariableNames.VENDOR_SPLIT_FORCE_LOCALHOST
    }
  )

const features: MockedFeaturesMap = {}

const vendorSplitFlagKeys = Object.keys(VendorSplitFlagNames)

vendorSplitFlagKeys.forEach((key) => {
  const val = vendorSplitLocalhostModeTreatmentOverride(key)
  if (val !== null) {
    const splitFlag = (VendorSplitFlagNames as ISplitFlagNames)[key]
    features[splitFlag] = val
  }
})

export const getVendorSplitLocalhostFeatures = (): MockedFeaturesMap => features

export function processVendorSplitFlags(
  isReady: boolean,
  treatments: SplitIO.TreatmentsWithConfig
): Record<VendorSplitFlagNames, boolean> {
  const flags: Partial<Record<VendorSplitFlagNames, boolean>> = {}
  for (const flag of Object.values(VendorSplitFlagNames)) {
    flags[flag as VendorSplitFlagNames] = isReady
      ? flag in treatments && treatments[flag].treatment === 'on'
      : defaultVendorSplitTreatments[flag] === 'on'
  }
  return flags as unknown as Record<VendorSplitFlagNames, boolean>
}
