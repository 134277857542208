import { createContext } from 'react'

import { VendorSplitFlagNames } from 'src/vendor/vendorSplit'

export interface VendorSplitState {
  loading: boolean
  flags: Record<VendorSplitFlagNames, boolean>
}

export const VendorSplitTeamContext = createContext<VendorSplitState>({
  loading: true,
  flags: {
    [VendorSplitFlagNames.CreateTemplatesFromUI]: true,
    [VendorSplitFlagNames.DataHookTesting]: false,
    [VendorSplitFlagNames.DefaultToPlatformSDK]: false,
    [VendorSplitFlagNames.DownloadLabelsXlsx]: false,
    [VendorSplitFlagNames.EmbedCustomization]: false,
    [VendorSplitFlagNames.EmbedWhitelabel]: false,
    [VendorSplitFlagNames.EnableDataHookEditorForPlatformSDKDeployments]: false,
    [VendorSplitFlagNames.ExportSheetButton]: false,
    [VendorSplitFlagNames.FlexportStyling]: false,
    [VendorSplitFlagNames.GithubAuth]: false,
    [VendorSplitFlagNames.GraphQLMutations]: false,
    [VendorSplitFlagNames.HideCollaborators]: false,
    [VendorSplitFlagNames.HideDropFile]: false,
    [VendorSplitFlagNames.ImportTimeline]: false,
    [VendorSplitFlagNames.LegacyWebhookURL]: false,
    [VendorSplitFlagNames.MergeDuplicates]: false,
    [VendorSplitFlagNames.PortalFreeTrial]: true,
    [VendorSplitFlagNames.Relational]: false,
    [VendorSplitFlagNames.RelationalSelfLinkedTemplates]: false,
    [VendorSplitFlagNames.EnableSchemaEditorForPlatformSDKDeployments]: false,
    [VendorSplitFlagNames.Webhook]: false,
    [VendorSplitFlagNames.Workspaces]: false,
    [VendorSplitFlagNames.WorkspaceSftp]: false,
    [VendorSplitFlagNames.WorkspaceSheetApi]: false
  }
})
