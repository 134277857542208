import { useMutation, useQuery } from '@apollo/client'
import { useEffect } from 'react'
import { ErrorBoundary } from '@sentry/react'
import prettyBytes from 'pretty-bytes'
import { withRouter } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import { SpreadSheetToolbar } from 'src/applications/Oversight/controls/SpreadSheetToolbar'
import { useBatchTimelineModal } from 'src/applications/Oversight/hooks/useBatchTimelineModal'
import { useConfirmModal } from 'src/applications/Oversight/hooks/useConfirmModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { Table } from 'src/applications/Oversight/components/Table'
import { TableOverflow } from 'src/applications/Oversight/panels/ImportListDataPanel'
import { FLATFILE_REFINERY_URL } from 'src/config'
import {
  ActiveImportDetail,
  ActiveImportDetailContext,
  EImportDetailView
} from 'src/contexts/ActiveImportDetailContext'
import { GET_BATCH } from 'src/queries/GET_BATCH'
import { GET_ORIGINAL_DATA } from 'src/queries/GET_ORIGINAL_DATA'
import { GET_ROWS } from 'src/queries/GET_ROWS'
import { REMOVE_BATCH } from 'src/queries/REMOVE_BATCH'
import { GetBatch, GetBatchVariables, GetBatch_getBatch } from 'src/queries/types/GetBatch'
import { GetBatches_getBatches_data_headersMatched } from 'src/queries/types/GetBatches'
import { GetOriginalData, GetOriginalDataVariables } from 'src/queries/types/GetOriginalData'
import { GetRows } from 'src/queries/types/GetRows'
import { RemoveBatch, RemoveBatchVariables } from 'src/queries/types/RemoveBatch'
import { Colors } from 'src/resources/colors'
import { Icon } from 'src/resources/elements/Icon'
import { Message } from 'src/resources/elements/Message'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { Spinner } from 'src/resources/elements/Spinner'
import { Spacing } from 'src/resources/layout'
import { BreadcrumbsFullTemplate } from 'src/resources/layouts/BreadcrumbsFullTemplate'
import { Shadows } from 'src/resources/shadows'
import { EViewType } from 'src/types/enums/EViewType'
import { EBatchType, IBatch } from 'src/types/interfaces/IBatch'
import { getBatchFilename } from 'src/utils/getBatchFilename'
import styled from 'styled-components'
import useReactRouter from 'use-react-router'

import { ImportDataPanel } from './ImportDataPanel'

const UL = styled.ul`
  padding-inline-start: ${Spacing.basePadding2x};
`

const EmptyState = styled.div`
  background: ${Colors.white};
  border-radius: 4px;
  box-shadow: ${Shadows.large};
  margin: 10vh auto;
  max-width: 500px;
  padding: ${Spacing.contentPadding};

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }
`

const CenteredText = styled.div`
  width: 100%;
  text-align: center;
`

export const createHeaderObj = (headers: string[]) => {
  return headers.map((header) => {
    return { value: header }
  })
}

export const removeBatchModal = (
  batchId: string,
  onSuccess: () => void,
  onFailure: () => void
): { open: () => void; render: () => void; close: () => void } => {
  const [removeBatch] = useMutation<RemoveBatch, RemoveBatchVariables>(REMOVE_BATCH)
  const modal = useConfirmModal({
    header: 'Are you sure?',
    contents: (
      <>
        This will delete both the original and processed file and cannot be undone. The data will
        no longer be available.
      </>
    ),
    onConfirm: async () => {
      const response = await removeBatch({
        variables: { batchId }
      })
      const {
        data: { removeBatch: removeBatchProperty }
      } = response
      !removeBatchProperty ? onFailure() : onSuccess()
    }
  })
  return modal
}
export const NoManagedConfigCard = () => {
  return (
    <EmptyState>
      <Message>
        <h2>Access data in the dashboard</h2>
        <p>
          In order to view uploaded data in the dashboard, you’ll need to configure the importer to
          use server-side processing. <br />
          <UL>
            <li>
              <a href='https://flatfile.io/developers/javascript/options/#managed' target='_blank'>
                Documentation
              </a>
            </li>
            <br />
            <li>
              <a href='https://help.flatfile.io/support/home' target='_blank'>
                Help center
              </a>
            </li>
          </UL>
        </p>
      </Message>
    </EmptyState>
  )
}

export const ImportDetailTable = ({ batchId }: { batchId: string }) => {
  const { addToast } = useToasts()
  const { history } = useReactRouter()
  const teamRoot = useTeamRootUrl()
  const { data, loading, error } = useQuery<GetBatch, GetBatchVariables>(GET_BATCH, {
    fetchPolicy: 'cache-and-network',
    variables: { batchId }
  })

  const batchTimelineModal = useBatchTimelineModal({ batchId })
  const importDetailRemoveBatchModal = removeBatchModal(
    batchId,
    () => {
      importDetailRemoveBatchModal.close()
      addToast('Import deleted', { appearance: 'success', autoDismiss: true })
      history.push(`${teamRoot}/imports`)
    },
    () => {
      importDetailRemoveBatchModal.close()
      addToast('Batch deletion failed', { appearance: 'error', autoDismiss: true })
    }
  )

  if (error || loading) {
    return QueryAlert({ error, loading })
  }

  const batch = data.getBatch

  const originalFileName = batch?.uploads?.[0]?.fileName || ''

  const isCsv = /.csv$/.test(originalFileName)

  const links = [
    {
      to: `${teamRoot}/imports`,
      label: 'All imports'
    },
    {
      current: true,
      label: getBatchFilename(data?.getBatch)
    }
  ]

  return (
    <BreadcrumbsFullTemplate crumbs={links}>
      <ActiveImportDetailContext>
        <ActiveImportDetail>
          {({ activeImportDetailViewType, setActiveImportDetailViewType }) => (
            <>
              {batchTimelineModal.render()}
              {importDetailRemoveBatchModal.render()}
              <SpreadSheetToolbar
                batch={batch}
                activeViewType={activeImportDetailViewType}
                setActiveViewType={setActiveImportDetailViewType}
                onViewHistory={batchTimelineModal.open}
                onRemoveBatch={importDetailRemoveBatchModal.open}
              />
              {(() => {
                if (!batch.embedId && !batch.managed) {
                  return <NoManagedConfigCard />
                }
                if (batch.manual) {
                  return (
                    <ErrorBoundary fallback={() => QueryAlert({ error: 'An error occurred' })}>
                      <ManualImportDetailTable batch={batch} />
                    </ErrorBoundary>
                  )
                }

                if (activeImportDetailViewType === EImportDetailView.final) {
                  if (batch.embedId && batch.headersMatched && batch.headersMatched.length > 0) {
                    return (
                      <ErrorBoundary fallback={() => QueryAlert({ error: 'An error occurred' })}>
                        <ImportDataPanel
                          batchId={batchId}
                          workspaceId={batch.workspaceId}
                          hideChrome
                          viewType={EViewType.GLOBAL}
                        />
                      </ErrorBoundary>
                    )
                  }

                  if (!batch.submittedAt) {
                    return <ImportSubmitError />
                  }

                  return (
                    <ErrorBoundary fallback={() => QueryAlert({ error: 'An error occurred' })}>
                      <FinalImportDetailTable batch={batch} />
                    </ErrorBoundary>
                  )
                }

                if (activeImportDetailViewType === EImportDetailView.original) {
                  if (batch.embedId) {
                    return (
                      <ErrorBoundary fallback={() => QueryAlert({ error: 'An error occurred' })}>
                        <ImportDataPanel
                          batchId={batchId}
                          workspaceId={batch.workspaceId}
                          hideChrome
                          viewType={EViewType.NUMERICALLY_INDEXED}
                        />
                      </ErrorBoundary>
                    )
                  }
                  if (
                    batch.type === EBatchType.fileUpload ||
                    (batch.type === EBatchType.legacyUpload && isCsv)
                  ) {
                    return (
                      <ErrorBoundary fallback={() => QueryAlert({ error: 'An error occurred' })}>
                        <OriginalImportDetailTable batch={batch} />
                      </ErrorBoundary>
                    )
                  } else {
                    return <DownloadFile batch={batch} />
                  }
                }
                return null
              })()}
            </>
          )}
        </ActiveImportDetail>
      </ActiveImportDetailContext>
    </BreadcrumbsFullTemplate>
  )
}

export const ImportLoadingMessage = () => {
  return (
    <Message center>
      <Spinner />
      Please wait, loading...
    </Message>
  )
}

export const ImportLoadingError = () => {
  return (
    <Message center>
      <h2>No preview available</h2>
      <p>Something went wrong while loading rows</p>
    </Message>
  )
}

export const ImportEmptyMessage = () => {
  return (
    <Message center>
      <CenteredText>No data available for this import</CenteredText>
    </Message>
  )
}

export const ImportSubmitError = () => {
  return (
    <EmptyState>
      <h4>
        <Icon name='info-circle' /> Import not submitted
      </h4>
      <p>The user has not yet completed the import</p>
    </EmptyState>
  )
}

export const DownloadFile = ({ batch }: { batch: GetBatch_getBatch }) => {
  const upload = batch.uploads[0]
  return (
    <EmptyState>
      <h4>
        <Icon name='info-circle' /> No preview available
      </h4>
      <p>
        To view the original data, download{' '}
        <a href={`${FLATFILE_REFINERY_URL}/batch/${batch.id}/original-file`} download>
          {batch.originalFile}
        </a>{' '}
        ({upload ? prettyBytes(upload.fileSize ?? 0) : undefined})
      </p>
    </EmptyState>
  )
}

export const OriginalImportDetailTable = ({ batch }: { batch: IBatch }) => {
  const { data, loading, error } = useQuery<GetOriginalData, GetOriginalDataVariables>(
    GET_ORIGINAL_DATA,
    {
      variables: { batchId: batch.id }
    }
  )

  useEffect(() => {
    console.log('Original import data rows:', data?.getOriginalData?.dataRows?.length || 0)
  }, [data])

  if (error) {
    return <ImportLoadingError />
  }

  if (loading) {
    return <ImportLoadingMessage />
  }

  const headers = createHeaderObj(data.getOriginalData.dataHeaders)

  const rows =
    (data.getOriginalData.dataRows &&
      data.getOriginalData.dataRows.map((row, index) => {
        return { cells: row.map((value) => ({ value })), rowIndex: index, position: index }
      })) ||
    []

  if (!rows?.length) {
    return <ImportEmptyMessage />
  }

  return (
    <TableOverflow>
      <Table initData={rows} count={rows?.length} columnConfig={headers} readOnly />
    </TableOverflow>
  )
}

export const ImportDetailPanel = withRouter(
  ({
    match: {
      params: { batchId }
    }
  }) => <ImportDetailTable batchId={batchId} />
)

export const FinalImportDetailTable = ({ batch }: { batch: IBatch }) => {
  const { data, error, loading } = useQuery<GetRows>(GET_ROWS, {
    variables: { batchId: batch.id }
  })

  useEffect(() => {
    console.log('Final import data rows:', data?.getRows?.data?.length || 0)
  }, [data])

  if (error) {
    return <ImportLoadingError />
  }

  if (loading) {
    return <ImportLoadingMessage />
  }

  const flatHeaders = batch.headersMatched
    ? batch.headersMatched.map(
        (header: GetBatches_getBatches_data_headersMatched) => header.matched_key
      )
    : []
  const headers = createHeaderObj(flatHeaders)

  const rows =
    (data.getRows &&
      data.getRows.data.map((row, index) => {
        const mappedHeaders = { ...row.mapped, ...row.mapped?.$custom }
        return {
          cells: flatHeaders.map((header) => ({ value: mappedHeaders[header] || '' })),
          rowIndex: index,
          position: index
        }
      })) ||
    []

  if (!rows?.length) {
    return <ImportEmptyMessage />
  }

  return (
    <TableOverflow>
      <Table initData={rows} count={rows?.length} columnConfig={headers} readOnly />
    </TableOverflow>
  )
}

export const ManualImportDetailTable = ({ batch }: { batch: IBatch }) => {
  const { data, error, loading } = useQuery<GetRows>(GET_ROWS, {
    variables: { batchId: batch.id },
    skip: !batch.managed
  })

  useEffect(() => {
    console.log('Manual import data rows:', data?.getRows?.data?.length || 0)
  }, [data])

  if (error) {
    return <ImportLoadingError />
  }

  if (loading) {
    return <ImportLoadingMessage />
  }

  const rowData = data?.getRows?.data
  const flatHeaders = rowData?.length > 0 ? Object.keys(rowData[0].mapped) : []
  const headers = createHeaderObj(flatHeaders)

  const rows = rowData
    ? rowData.map((row, index) => {
        const mappedRow = flatHeaders.map((header) => ({ value: row.mapped[header] || '' }))
        return {
          cells: mappedRow.some((cell) => cell.value) && mappedRow,
          rowIndex: index,
          position: index
        }
      })
    : []

  if (!rows?.length) {
    return <ImportEmptyMessage />
  }

  return (
    <TableOverflow>
      <Table initData={rows} count={rows?.length} columnConfig={headers} readOnly />
    </TableOverflow>
  )
}
