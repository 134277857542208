import { env } from 'src/env'
import { localStorageOverride } from 'src/utils/localStorageOverride'

export enum ConfigVariableNames {
  CHARGEBEE_SITE = 'CHARGEBEE_SITE',
  DEFAULT_PAGE_SIZE = 'DEFAULT_PAGE_SIZE',
  FEATURE_FLAGS = 'FEATURE_FLAGS',
  FILTER_MODE = 'FILTER_MODE',
  FLATFILE_DEVELOPERS_BASE = 'FLATFILE_DEVELOPERS_BASE',
  FLATFILE_GEOGRAPHIC_REGION_EMOJI_CODEPOINTS = 'FLATFILE_GEOGRAPHIC_REGION_EMOJI_CODEPOINTS',
  FLATFILE_GEOGRAPHIC_REGION_LABEL = 'FLATFILE_GEOGRAPHIC_REGION_LABEL',
  FLATFILE_PRODUCT_HOMEPAGE = 'FLATFILE_PRODUCT_HOMEPAGE',
  FLATFILE_REFINERY_URL = 'FLATFILE_REFINERY_URL',
  FLATFILE_REFINERY_WS_URL = 'FLATFILE_REFINERY_WS_URL',
  FLATFILE_SFTP_ENDPOINT = 'FLATFILE_SFTP_ENDPOINT',
  PAGE_QUERY_PARAM = 'PAGE_QUERY_PARAM',
  SENTRY_CONFIG = 'SENTRY_CONFIG',
  VENDOR_COHERE_API_KEY = 'VENDOR_COHERE_API_KEY',
  VENDOR_SPLIT_AUTHORIZATION_KEY = 'VENDOR_SPLIT_AUTHORIZATION_KEY',
  VENDOR_SPLIT_FORCE_LOCALHOST = 'VENDOR_SPLIT_FORCE_LOCALHOST',
  WAIT_TIME_BEFORE_POLLING = 'WAIT_TIME_BEFORE_POLLING'
}

export enum Environment {
  Development = 'development',
  Production = 'production',
  OnPrem = 'onprem'
}

export const LIVE_ENVIRONMENT_SLUG = 'prod'

export const SENTRY_CONFIG: {
  dsn?: string
  release?: string
  enabled: boolean
  debug: boolean
  environment: string
} = {
  debug: env.SENTRY_DEBUG === 'true',
  dsn: env.SENTRY_DSN,
  enabled: env.SENTRY_ENABLED === 'true',
  environment: env.SENTRY_ENVIRONMENT || env.FLATFILE_ENV || 'local',
  release: env.SENTRY_RELEASE
}

export const OVERSIGHT_IS_PRODUCTION_DOMAIN =
  env.FLATFILE_ENV === Environment.Production ||
  env.FLATFILE_ENV === Environment.OnPrem ||
  window.location.origin.includes('.flatfile.')

export const FLATFILE_IS_CLOUD_ENV = env.FLATFILE_ENV !== Environment.OnPrem

let REGIONAL_REFINERY_URL, REGIONAL_WSS_URL
let REGIONAL_EMOJI_CODEPOINTS, REGIONAL_GEO_LABEL

for (const [match, values] of Object.entries({
  '.eu0.': {
    api: 'https://api.eu0.flatfile.com',
    wss: 'wss://api.eu0.flatfile.com',
    emojiCodepoints: '55356-56814-55356-56810',
    geoLabel: 'European Union (Ireland)'
  },
  '.de0.': {
    api: 'https://api.de0.flatfile.com',
    wss: 'wss://api.de0.flatfile.com',
    emojiCodepoints: '55356-56809-55356-56810',
    geoLabel: 'European Union (Frankfurt)'
  },
  '.uk0.': {
    api: 'https://api.uk0.flatfile.com',
    wss: 'wss://api.uk0.flatfile.com',
    emojiCodepoints: '55356-56812-55356-56807',
    geoLabel: 'United Kingdom'
  },
  '.aus0.': {
    api: 'https://api.aus0.flatfile.com',
    wss: 'wss://api.aus0.flatfile.com',
    emojiCodepoints: '55356-56806-55356-56826',
    geoLabel: 'Australia'
  },
  'app.flatfile': {
    api: 'https://api.us.flatfile.io',
    wss: 'wss://api.us.flatfile.io'
  },
  localhost: {
    api: 'http://localhost:3000',
    wss: 'ws://localhost:3000'
  }
})) {
  if (window.location.origin.includes(match)) {
    const { api, wss, emojiCodepoints, geoLabel } = values
    REGIONAL_REFINERY_URL = api
    REGIONAL_WSS_URL = wss
    REGIONAL_EMOJI_CODEPOINTS = emojiCodepoints
    REGIONAL_GEO_LABEL = geoLabel
  }
}

export const FLATFILE_REFINERY_URL: string = localStorageOverride(
  ConfigVariableNames.FLATFILE_REFINERY_URL,
  env.FLATFILE_REFINERY_URL || REGIONAL_REFINERY_URL,
  {
    options: [
      'http://localhost:3000',
      'https://api.staging.flatfile.zone',
      'https://api.us.flatfile.io',
      'https://api.eu0.flatfile.com',
      'https://api.de0.flatfile.com',
      'https://api.uk0.flatfile.com'
    ]
  }
)

function isProd(url?: string | undefined): boolean {
  if (!url) return false
  return url.endsWith('.io') || url.endsWith('.com')
}

const PROD_APP = isProd(location.host)
const PROD_REFINERY = isProd(FLATFILE_REFINERY_URL)

export const USER_IS_DEVELOPER = FLATFILE_IS_CLOUD_ENV && (!PROD_APP || !PROD_REFINERY)

export const FLATFILE_SFTP_ENDPOINT: string = localStorageOverride(
  ConfigVariableNames.FLATFILE_SFTP_ENDPOINT,
  env.FLATFILE_SFTP_ENDPOINT || 'sftp://sftp.us.flatfile.io',
  {
    options: ['sftp://sftp.us.flatfile.io']
  }
)

export const FLATFILE_REFINERY_WS_URL: string = localStorageOverride(
  ConfigVariableNames.FLATFILE_REFINERY_WS_URL,
  env.FLATFILE_REFINERY_WS_URL || REGIONAL_WSS_URL,
  {
    options: [
      'ws://localhost:3000',
      'wss://api.staging.flatfile.zone',
      'wss://api.us.flatfile.io',
      'wss://api.uk0.flatfile.com',
      'wss://api.eu0.flatfile.com',
      'wss://api.de0.flatfile.com'
    ]
  }
)

export const CHARGEBEE_SITE: string = env.CHARGEBEE_SITE || 'flatfile-test'
export const CHARGEBEE_PUBLISHABLE_KEY: string = env.CHARGEBEE_PUBLISHABLE_KEY || ''

export const DEFAULT_PAGE_SIZE: number = env.DEFAULT_PAGE_SIZE
  ? parseInt(env.DEFAULT_PAGE_SIZE, 10)
  : 10

export const PAGE_QUERY_PARAM: string = env.PAGE_QUERY_PARAM || 'page'

export const FLATFILE_PRODUCT_HOMEPAGE: string =
  env.FLATFILE_PRODUCT_HOMEPAGE || 'https://flatfile.com'

export const FLATFILE_DEVELOPERS_BASE: string =
  env.FLATFILE_DEVELOPERS_BASE || 'https://flatfile.com/docs/'

export const VENDOR_COHERE_API_KEY = localStorageOverride(
  ConfigVariableNames.VENDOR_COHERE_API_KEY,
  env.VENDOR_COHERE_API_KEY || 'N6b-dZPS9CaQcMmy-UOIRrme'
)

export const VENDOR_SPLIT_AUTHORIZATION_KEY = localStorageOverride(
  ConfigVariableNames.VENDOR_SPLIT_AUTHORIZATION_KEY,
  env.VENDOR_SPLIT_AUTHORIZATION_KEY || 'localhost'
)

export const VENDOR_SPLIT_FORCE_LOCALHOST =
  localStorageOverride(
    ConfigVariableNames.VENDOR_SPLIT_FORCE_LOCALHOST,
    env.VENDOR_SPLIT_FORCE_LOCALHOST || 'off'
  ) === 'on'

const FEATURE_FLAGS_KEY = 'FEATURE FLAGS'
export const FEATURE_FLAGS_ENABLED = localStorageOverride(FEATURE_FLAGS_KEY, 'off') === 'on'

const FEATURE_FLAG_NESTING = {
  nestUnder: FEATURE_FLAGS_KEY
}

export const FEATURE_FLAGS = {
  UPLOADS:
    localStorageOverride(
      'FEATURE_FLAG_UPLOADS',
      env.FEATURE_FLAG_UPLOADS || 'off',
      FEATURE_FLAG_NESTING
    ) === 'on' && FEATURE_FLAGS_ENABLED,
  TASKS:
    localStorageOverride(
      'FEATURE_FLAG_TASKS',
      env.FEATURE_FLAG_TASKS || 'off',
      FEATURE_FLAG_NESTING
    ) === 'on' && FEATURE_FLAGS_ENABLED,
  SEARCH_WORKSPACE:
    localStorageOverride(
      'FEATURE_FLAG_SEARCH_WORKSPACE',
      env.FEATURE_FLAG_SEARCH_WORKSPACE || 'off',
      {
        nestUnder: FEATURE_FLAGS_KEY
      }
    ) === 'on' && FEATURE_FLAGS_ENABLED,
  EXPORT:
    localStorageOverride(
      'FEATURE_FLAG_EXPORT',
      env.FEATURE_FLAG_EXPORT || 'off',
      FEATURE_FLAG_NESTING
    ) === 'on' && FEATURE_FLAGS_ENABLED
}

export const FLATFILE_GEOGRAPHIC_REGION_EMOJI_CODEPOINTS: string = localStorageOverride(
  ConfigVariableNames.FLATFILE_GEOGRAPHIC_REGION_EMOJI_CODEPOINTS,
  env.FLATFILE_GEOGRAPHIC_REGION_EMOJI_CODEPOINTS || REGIONAL_EMOJI_CODEPOINTS || '55356-57104' // globe with meridians
)

export const FLATFILE_GEOGRAPHIC_REGION_LABEL: string = localStorageOverride(
  ConfigVariableNames.FLATFILE_GEOGRAPHIC_REGION_LABEL,
  env.FLATFILE_GEOGRAPHIC_REGION_LABEL || REGIONAL_GEO_LABEL
)

export const WAIT_TIME_BEFORE_POLLING = env.WAIT_TIME_BEFORE_POLLING
