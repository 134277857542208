import { useEffect } from 'react'

import * as Sentry from '@sentry/react'
import { withRouter } from 'react-router-dom'
import { ITeam } from 'src/types/interfaces/ITeam'
import { IUser } from 'src/types/interfaces/IUser'
import posthog from 'posthog-js'
import { env } from 'src/env'

export interface PersonalizedUser {
  id: string
  name?: string
  email?: string
  intercomHash?: string
  role?: string
  companyId?: string
  companyName?: string
  companySize?: number
  plan?: string
}

export const Analytics = withRouter(({ history }) => {
  useEffect(() => {
    const unlisten = history.listen((_location, _action) => {
      const segment = (window as any).analytics
      if (segment) {
        segment.page()
      }
    })
    return function cleanup() {
      unlisten()
    }
  }, [])

  return null
})

export const identifySegment = (user: IUser) => {
  const segment = (window as any).analytics
  if (segment) {
    segment.identify(
      user.id,
      {
        displayName: user.name,
        email: user.email,
        name: user.name,
        userId: user.id,
        jobTitle: user.jobTitle || 'Unknown'
      },
      {
        integrations: {
          Salesforce: true,
          Freshsales: false,
          Intercom: {
            user_hash: user.intercomHash
          }
        }
      }
    )
  }
  Sentry.setUser(user)
}

export const identifyPosthog = (userInfo: {}, eventType?: string) => {
  if (env.POST_HOG_API_KEY && env.POST_HOG_HOST) {
    posthog.capture(eventType || 'setUserInfo', userInfo)
    posthog.people.set({ ...userInfo })
  }
}

export const identifyPersonalizationSegment = (user: PersonalizedUser) => {
  const segment = (window as any).analytics
  if (segment) {
    segment.identify(
      user.id,
      {
        company: {
          employee_count: user.companySize,
          id: user.companyId,
          name: user.companyName
        },
        displayName: user.name,
        email: user.email,
        name: user.name,
        title: user.role
      },
      {
        integrations: {
          Salesforce: true,
          Freshsales: false,
          Intercom: {
            user_hash: user.intercomHash
          }
        }
      }
    )
  }
  Sentry.setUser(user)
}

export const trackSignup = (_user: IUser) => {
  posthog.identify(_user.email + '#' + _user.id)
  identifyPosthog({ email: _user.email, user_id: _user.id }, 'signup - frontend')
  const segment = (window as any).analytics
  if (segment) {
    segment.track('User Signed Up (frontend)')
  }
}

export const trackCompanyCreated = (
  _user: IUser,
  _team: Pick<ITeam, 'id' | 'name'>,
  plan: string
) => {
  const segment = (window as any).analytics
  identifyPosthog({ team_id: _team?.id }, 'company created - frontend')
  if (segment) {
    segment.track('Created Company (frontend)', {
      plan
    })
  }
}
