import { Footer } from 'src/applications/Embed/fragments/Footer'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { Ellipsis } from 'src/resources/effects/Ellipsis'
import DefaultVerticalLine from 'src/applications/Oversight/components/VerticalLine'
import { Spacing } from 'src/resources/layout'
import { Colors } from 'src/resources/colors'
import { fontSizes } from 'src/resources/typography'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'
import styled, { css } from 'styled-components'
import { useTranslation } from 'react-i18next'

// Helpers
const FlexCenter = `
  display: flex;
  flex-direction: row;
  align-items: center;
`

const FlexJustifyCenter = `
  ${FlexCenter}
  justify-content: center;
`

const FlexColumnCenter = `
  ${FlexJustifyCenter}
  flex-direction: column;
`

// Shared Elements
const FlexContainer = styled.div`
  ${FlexColumnCenter}
  height: 100vh;
`

const FlexEndFooter = styled(Footer)`
  justify-content: flex-end;
`

const GoBackButton = styled.div<{ useMarginLeft?: boolean }>`
  ${FlexCenter}
  font-family: inherit !important;
  font-size: ${themed('buttonSize')};
  font-weight: ${themed('buttonWeight')};
  line-height: ${themed('buttonLineHeight')};
  color: ${themed('textColor')};
  cursor: pointer;
  margin-right: ${Spacing.basePadding3x};
  ${({ useMarginLeft }) => useMarginLeft && `margin-left: ${Spacing.basePadding3x};`}
  ${({ theme }) => theme?.flexport && FlexportButtonTheme}
  ${({ theme }) => theme?.sage && SageButtonTheme}
`

// Shared Components
const VerticalLine = () => {
  return (
    <DefaultVerticalLine
      height='20px'
      margin={{ left: Spacing.basePadding, right: Spacing.basePadding }}
    />
  )
}

const CancelButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation()
  return (
    <FlatButton margin={{ top: Spacing.basePadding4x }} variant='outlined' onClick={onClick}>
      {t('elements.buttons.cancel')}
    </FlatButton>
  )
}

const EllipsisLoader = ({ text }: { text: string }) => {
  return (
    <>
      {text} <Ellipsis />
    </>
  )
}

// 1. Initial Scene
const RelativeFooter = styled(Footer)`
  ${FlexJustifyCenter}
  background-color: ${Colors.transparent};
  position: relative;

  div {
    height: 12px;
    width: 12px;
  }

  svg {
    vertical-align: top;
  }
`

const HelpContent = styled.div`
  color: ${themed('textColor')};

  > :first-child {
    margin-top: 0;
  }

  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    line-height: 1.7;
    margin: 1em 0 15px 0;
  }

  > h1 {
    font-size: 2.5em;
  }

  > h2 {
    font-size: 2em;
  }

  > h3 {
    font-size: 1.5em;
  }

  > h4 {
    font-size: 1.2em;
    font-weight: 500;
  }

  > h5 {
    font-size: 1em;
    font-weight: 500;
  }

  > h6 {
    font-size: 1em;
    font-weight: 500;
  }
  
  ul,
  ol {
    list-style: none;
    padding: 0;
    padding-left: 24px;
    margin: initial;
  }
  
  li {
    position: relative;
  }
  
  li:before {
    content: '\\00b7';
    font-size: 20px;
    left: -16px;
    position: absolute;
    top: -3px;
  }
  
  p + ul,
  ul + p {
    margin-top: 16px;
  }
}
  
  a {
    color: ${themed('primary')}
`

const IconContainer = styled.div`
  margin-right: 6px;
`

// 5. Review Scene
const EmptyState = styled.div`
  padding: 32px;
  border: 1px solid #dfe3eb;
  border-radius: 3px;
  text-align: center;
`

const ControlBar = styled.div`
  ${FlexCenter}
  margin-bottom: ${Spacing.basePadding3x};
`

const DismissButton = styled(FlatButton)`
  margin: 0 0 0 30px;
`

const FlexportButtonTheme = css`
  position: relative;

  &:after {
    background-color: ${themed('secondary')};
    top: calc(100% - 2px);
    content: '';
    height: ${themed('borderWidth')};
    left: 0;
    position: absolute;
    width: 100%;
  }
`

const SageButtonTheme = css`
  border: 2px solid ${themed('primary')};
  color: ${themed('primary')};
  font-size: 14px;
  height: 40px;
  padding: 0 24px;
  position: relative;
  &:hover {
    background-color: ${themed('buttonHover')};
    color: white;
  }
`

// 7. Final Scene
const ButtonGroup = styled.div`
  ${FlexJustifyCenter}
  padding: ${Spacing.basePadding2x} 0;
  column-gap: ${Spacing.basePadding2x};
`

const StaticTab = styled.div`
  font-size: ${fontSizes.type14};
`

const SingleTabContainer = styled.div`
  ${FlexJustifyCenter}
  margin-top: ${Spacing.basePadding6x};

  > div {
    max-width: 200px;
  }
`

export {
  RelativeFooter,
  HelpContent,
  IconContainer,
  FlexEndFooter,
  EmptyState,
  ControlBar,
  DismissButton,
  GoBackButton,
  FlexContainer,
  ButtonGroup,
  StaticTab,
  SingleTabContainer,
  VerticalLine,
  EllipsisLoader,
  CancelButton
}
