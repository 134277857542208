import { useMutation } from '@apollo/client'
import { Span } from '@sentry/types'
import { withRouter } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Carousel } from 'src/applications/Oversight/components/Carousel'
import { SignUpNavigation } from 'src/applications/Oversight/components/SignUpNavigation'
import { GeographicRegionSelect } from 'src/applications/Oversight/controls/GeographicRegionSelect'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { useSignUpCode } from 'src/applications/Oversight/scenes/SignUp/useSignUpCode'
import { SplitScreenTemplate } from 'src/applications/Oversight/templates/SplitScreenTemplate'
import { getUserContext } from 'src/contexts/UserContext'
import { REGISTER_USER } from 'src/queries/REGISTER_USER'
import { RegisterUser, RegisterUserVariables } from 'src/queries/types/RegisterUser'
import { updateClientLink } from 'src/resources/clients/graphClient'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { FormCheckBox } from 'src/resources/elements/form/CheckBox'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { Header } from 'src/resources/elements/Header'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { trackSignup } from 'src/resources/utils/analytics'
import { UserTransceiver } from 'src/transceivers/UserTransceiver'
import styled from 'styled-components'

const TextWithLink = styled.div<{ fontSize?: string; align?: string; marginTop?: boolean }>`
  display: flex;
  justify-content: ${({ align }) => (align ? align : null)};
  align-items: center;
  color: ${Colors.blackLight};
  font-size: ${({ fontSize }) => (fontSize ? fontSize : fontSizes.type13)};
  font-weight: 500;
  padding: ${Spacing.basePadding2x} 0;

  p {
    margin: 0 ${Spacing.halfBasePadding} 0;
  }

  a {
    color: ${Colors.blue};
    font-weight: 600;
  }

  ${({ marginTop }) => marginTop && `margin-top: ${Spacing.basePadding}`};
`

const LoginButton = styled<any>(FlatButton)`
  color: ${Colors.blue}!important;

  &:hover {
    background-color: white !important;
  }
`

export const SignUpScene = () => <SignUpPanel />

// Sentry performance tracking
const signUpSpans: Partial<Span>[] = [
  {
    op: 'sign-up-loaded'
  },
  {
    op: 'sign-up-form-onsubmit'
  },
  {
    op: 'sign-up-form-success'
  }
]

export const SignUpPanel = withRouter(({ history, location }) => {
  const { setValue } = getUserContext()
  const initialFormValue = useSignUpCode(location.search)

  const [registerUser] = useMutation<RegisterUser, RegisterUserVariables>(REGISTER_USER)

  const { addToast } = useToasts()

  const SentryTransaction = useSentryTransaction({
    spans: signUpSpans
  })

  submitSentrySpan(SentryTransaction, 'sign-up-loaded')

  const SignUpForm: TForm<{
    fullName: string
    email: string
    password: string
    accept: boolean
  }> = Form

  return (
    <SplitScreenTemplate rightSide={<Carousel />}>
      <SignUpNavigation step={1} />
      <div>
        <Header>
          <h1>Create your account</h1>
        </Header>
        <SignUpForm
          initialValue={initialFormValue}
          onSubmit={async (event) => {
            UserTransceiver.logout()

            const { fullName, email, password, accept } = event.data
            submitSentrySpan(SentryTransaction, 'sign-up-form-onsubmit')

            let formErrors = {}

            if (!fullName?.trim()?.length) {
              formErrors = {
                ...formErrors,
                fullName: 'Please enter a valid name.'
              }
            }

            if (!email || email.indexOf('@') === -1) {
              formErrors = {
                ...formErrors,
                email: 'Please enter a valid email.'
              }
            }

            if (!password) {
              formErrors = {
                ...formErrors,
                password: 'Please enter a password.'
              }
            } else if (password.length < 8) {
              formErrors = {
                ...formErrors,
                password: 'Password must be at least 8 characters long.'
              }
            }

            if (!accept) {
              formErrors = {
                ...formErrors,
                accept: 'You must accept the terms of service to continue.'
              }
            }

            if (Object.keys(formErrors).length > 0) {
              event.formContext.setValue({
                errors: formErrors
              })
              return
            }

            if (!accept) {
              addToast('Please accept the terms of service', {
                appearance: 'error',
                autoDismiss: true
              })
              return
            }

            const {
              data: {
                registerUser: { user, accessToken }
              }
            } = await registerUser({
              variables: { fullName, email, password, urlSource: window.location.href }
            })

            if (user && accessToken) {
              submitSentrySpan(SentryTransaction, 'sign-up-form-success')
            }

            trackSignup(user)
            setValue({ user })
            updateClientLink(accessToken)
            history.replace(`/sign-up/personalize${location.search}`)
          }}
        >
          <GeographicRegionSelect path='/sign-up' />
          <InputGroup>
            <Input label='Full name' name='fullName' placeholder='Jane Smith' tabIndex={1} />
            <ErrorMessage name='fullName' />
          </InputGroup>
          <InputGroup>
            <Input
              autoComplete='email'
              label='Company email'
              name='email'
              placeholder='jane@smithsoftware.com'
              tabIndex={2}
            />
            <ErrorMessage name='email' />
          </InputGroup>
          <InputGroup>
            <Input
              autoComplete='new-password'
              label='Password'
              name='password'
              type='password'
              placeholder='• • • • • • • •'
              tabIndex={3}
            />
            <ErrorMessage name='password' />
            <FormCheckBox tabIndex={4} type='primary' name='accept'>
              <TextWithLink fontSize={fontSizes.type15}>
                <p>Accept the platform </p>
                <a href='/terms' target='_blank' tabIndex={5}>
                  Terms of Service
                </a>
              </TextWithLink>
            </FormCheckBox>
            <ErrorMessage name='accept' />
            <ButtonGroup stretch>
              <FormButton primary submit tabIndex={6}>
                Continue
              </FormButton>
            </ButtonGroup>
            <TextWithLink align='center' marginTop>
              <p>Already have an account? </p>
              <LoginButton
                color='white'
                renderAs='link'
                tabIndex={7}
                to='/login'
                aria-label='I already have a Flatfile account'
              >
                Log in
              </LoginButton>
            </TextWithLink>
          </InputGroup>
        </SignUpForm>
      </div>
    </SplitScreenTemplate>
  )
})
