import { useCallback, useEffect, useMemo, useState } from 'react'
import { DataChangeEvent, RowData } from '@turntable/core'
import fromEntries from 'fromentries'
import { Table } from 'src/applications/Oversight/components/Table'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_REPLACE_WORKSPACE_GRAPHQL_AUTH } from 'src/smart/mutations/SM_REPLACE_WORKSPACE_GRAPHQL_AUTH'
import { SQ_GET_WORKSPACE_GRAPHQL_AUTH } from 'src/smart/queries/SQ_GET_WORKSPACE_GRAPHQL_AUTH'
import { GetWorkspaceGraphQLAuth_getWorkspaceGraphQLAuth } from 'src/smart/queries/types/GetWorkspaceGraphQLAuth'
import { IWorkspace } from 'src/types/interfaces/IWorkspace'
import styled from 'styled-components'

const Column = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;

  h4,
  p {
    color: ${Colors.blackLight};
    margin: 0;
  }

  h4 {
    padding: ${Spacing.basePadding2x};
  }
  p {
    padding: 0 ${Spacing.basePadding2x} ${Spacing.basePadding2x};
  }
`

const ButtonWrap = styled.div`
  padding: ${Spacing.basePadding2x};
`

const HeadersTableWrapper = styled.div<{ height: number }>`
  flex-grow: 1;
  height: ${({ height }) => height + 2}px;
  max-height: 240px;
  overflow: hidden;
  z-index: 0;
  margin-right: -1px;
  margin-left: -1px;
`

function extractRowsFromAuth(auth?: GetWorkspaceGraphQLAuth_getWorkspaceGraphQLAuth): RowData[] {
  return Object.keys(auth?.headersSanitized ?? {})
    .sort()
    .map(
      (key, i): RowData => ({
        rowIndex: i + 1,
        position: i,
        cells: [{ value: key }, { value: auth.headersSanitized[key] }]
      })
    )
}

type Headers = Record<string, string>

const TABLE_HEADERS = [{ value: 'Name' }, { value: 'Value' }]
const ROW_HEIGHT = 32

export function GraphQLTabDetail({
  graphQLEndpoint,
  workspace
}: {
  graphQLEndpoint: string
  workspace: IWorkspace
}) {
  const graphQLAuth = useSmartQuery(SQ_GET_WORKSPACE_GRAPHQL_AUTH, {
    variables: {
      workspaceId: workspace.id,
      graphQLEndpoint
    }
  })

  const [isLoading, setIsLoading] = useState(false)
  const [replacementHeaders, setReplacementHeadersRaw] = useState<Headers>({})

  const displayRows: RowData[] = useMemo(() => {
    const { result } = graphQLAuth
    return result ? extractRowsFromAuth(result) : []
  }, [graphQLAuth.result])

  const replacementHeadersModified = useMemo(() => {
    return Object.values(replacementHeaders).some((x) => x)
  }, [replacementHeaders])

  useEffect(() => {
    if (graphQLAuth.result) {
      setReplacementHeaders(
        fromEntries(Object.entries(graphQLAuth.result.headersSanitized).map(([k]) => [k, '']))
      )
    }
  }, [graphQLAuth.result])

  const setReplacementHeaders = useCallback(
    (newHeaders: Headers) => {
      setReplacementHeadersRaw(newHeaders)
      setIsLoading(true)
      setTimeout(() => setIsLoading(false))
      /* Turntable needs the isLoading prop to change in order to get new data */
    },
    [setReplacementHeadersRaw]
  )

  const replaceHeadersMutation = useSmartMutation(SM_REPLACE_WORKSPACE_GRAPHQL_AUTH)
  const replaceHeaders = useCallback(async () => {
    await replaceHeadersMutation.run({
      workspaceId: workspace.id,
      graphQLEndpoint,
      headersSecret: replacementHeaders
    })
    await graphQLAuth.state.refetch({ workspaceId: workspace.id, graphQLEndpoint })
  }, [replaceHeadersMutation, graphQLAuth.result, graphQLEndpoint, workspace, replacementHeaders])

  const replacementHeaderKeys = useMemo(
    () =>
      Object.keys(replacementHeaders)
        .filter((key) => typeof replacementHeaders[key] === 'string')
        .sort((a, b) => (a === '' ? 1 : b === '' ? -1 : a.localeCompare(b)))
        .concat(typeof replacementHeaders[''] === 'string' ? [] : ''),
    [replacementHeaders]
  )

  const replacementHeaderRows = useMemo(() => {
    return replacementHeaderKeys.map((key, position) => ({
      rowIndex: position + 1,
      position,
      cells: [{ value: key }, { value: replacementHeaders[key] }]
    }))
  }, [replacementHeaders, replacementHeaderKeys])

  const handleCellChange = useCallback(
    async (event: DataChangeEvent) => {
      event.changes.forEach(({ position: row, patch }) => {
        patch?.forEach(({ column: col, value }) => {
          const changeType: 'key' | 'val' = col === 0 ? 'key' : 'val'
          switch (changeType) {
            case 'key':
              const oldKey = replacementHeaderKeys[row] ?? ''
              const newKey = value
              setReplacementHeaders({
                ...replacementHeaders,
                [oldKey]: undefined,
                [newKey as string]: replacementHeaders[oldKey] ?? ''
              })
              break
            case 'val':
              const key = replacementHeaderKeys[row]
              setReplacementHeaders({ ...replacementHeaders, [key]: value as string })
              break
          }
        })
      })
      return event
    },
    [replacementHeaders]
  )

  if (graphQLAuth.alert) {
    return queryAlert(graphQLAuth)
  }

  return (
    <Column>
      <h4>Headers:</h4>
      <p>
        Header values are clipped to 10 characters for display, but the full headers are sent when
        making requests.
      </p>
      {displayRows.length > 0 ? (
        <HeadersTableWrapper height={(displayRows.length + 1) * ROW_HEIGHT}>
          <Table
            tableId='display-headers'
            readOnly={true}
            count={displayRows.length}
            initData={displayRows}
            columnConfig={TABLE_HEADERS}
            isLoading={isLoading}
          />
        </HeadersTableWrapper>
      ) : (
        <p>No headers set for endpoint</p>
      )}
      <h4>Set new headers:</h4>
      <HeadersTableWrapper height={(replacementHeaderRows.length + 1) * ROW_HEIGHT}>
        <Table
          tableId='replacement-headers'
          count={replacementHeaderRows.length}
          initData={replacementHeaderRows}
          columnConfig={TABLE_HEADERS}
          onCellsChange={handleCellChange}
          isLoading={isLoading}
        />
      </HeadersTableWrapper>
      <ButtonWrap>
        <FlatButton onClick={replaceHeaders} disabled={!replacementHeadersModified}>
          Replace
        </FlatButton>
      </ButtonWrap>
    </Column>
  )
}
