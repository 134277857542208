import { snakeCase } from 'lodash'

import omit from 'lodash/omit'
import qs from 'query-string'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useConfirmUploadDataSourceFieldsMutation } from 'src/queries/hooks/useConfirmUploadDataSourceFields'
import { useUpdateUploadDataSourceFieldMutation } from 'src/queries/hooks/useUpdateUploadDataSourceFieldMutation'
import { ConfirmUploadDataSourceFields_confirmUploadDataSourceFields } from 'src/queries/types/ConfirmUploadDataSourceFields'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_BATCH } from 'src/smart/queries/SQ_BATCH'
import { SQ_SHEET } from 'src/smart/queries/SQ_SHEET'
import { SQ_VIEW_FIELDS } from 'src/smart/queries/SQ_VIEW_FIELDS'
import { SQ_VIEW_SCHEMA } from 'src/smart/queries/SQ_VIEW_SCHEMA'
import { ESourceFieldErrorType } from 'src/types/enums/ESourceFieldErrorType'
import { EViewType } from 'src/types/enums/EViewType'
import { JsonSchemaProperty } from 'src/types/interfaces/ISchema'
import { deserialize as deserializeSchema } from 'src/utils/schema-normalizer'

import {
  DataSourceField,
  ISourceFieldValidation,
  IUpdateDataSourceField
} from '../classes/DataSourceField'

export const useDataSourceFields = (batchId: string, sheetId?: string) => {
  const [customProperties, setCustomProperties] = useState<JsonSchemaProperty[]>([])
  const [dataSourceFields, setDataSourceFields] = useState<DataSourceField[]>(null)
  const [linkValidation, setLinkValidation] = useState<ISourceFieldValidation[]>(null)
  const [matchesUpdated, setMatchesUpdated] = useState<boolean>(false)
  const [updateUploadDataSourceField, { loading: updateLoading }] =
    useUpdateUploadDataSourceFieldMutation()
  const [confirmUploadDataSourceFields, { loading: confirmLoading }] =
    useConfirmUploadDataSourceFieldsMutation()

  const batchQuery = useSmartQuery(SQ_BATCH, {
    fetchPolicy: 'network-only',
    variables: { batchId }
  })

  const customFieldsFromQuery: JsonSchemaProperty[] | null = useMemo(() => {
    const cf = qs.parse(location.search)?.customFields
    if (cf && typeof cf === 'string') {
      try {
        return JSON.parse(cf)
      } catch (e) {
        console.error('Unable to parse custom fields passed via runtime configuration.')
      }
    }
    return null
  }, [location.search])

  useEffect(() => {
    if (
      customFieldsFromQuery &&
      'length' in customFieldsFromQuery &&
      customFieldsFromQuery.length > 0
    ) {
      setCustomProperties((value) => [
        ...value,
        ...customFieldsFromQuery.map((f) => ({ ...f, sdkProvided: true, custom: true }))
      ])
    }
  }, [customFieldsFromQuery])

  const preventUserCustomFieldsQuery: JsonSchemaProperty[] | null = useMemo(() => {
    const pudcf = qs.parse(location.search)?.preventUserDefinedFields
    if (pudcf && typeof pudcf === 'string') {
      try {
        return JSON.parse(pudcf)
      } catch (e) {
        console.error(
          'Unable to parse prevent user custom fields boolean passed via runtime configuration.'
        )
      }
    }
    return null
  }, [location.search])

  useEffect(() => {
    if (preventUserCustomFieldsQuery && preventUserCustomFieldsQuery.length > 0) {
      setCustomProperties((value) => [
        ...value,
        ...preventUserCustomFieldsQuery.map((f) => ({ ...f, sdkProvided: true, custom: true }))
      ])
    }
  }, [preventUserCustomFieldsQuery])

  const { result: batch } = batchQuery

  const headerRowSelectedView = batch?.views?.find(
    (view) => view.type === EViewType.HEADER_ROW_SELECTED
  )

  const viewId = headerRowSelectedView ? parseInt(headerRowSelectedView.id, 10) : undefined
  const viewDataSourceFields = useSmartQuery(SQ_VIEW_FIELDS, {
    fetchPolicy: 'network-only',
    skip: typeof viewId !== 'number',
    variables: { viewId }
  })

  const viewSchema = useSmartQuery(SQ_VIEW_SCHEMA, {
    skip: typeof viewId !== 'number',
    variables: { viewId }
  })

  const sheet = useSmartQuery(SQ_SHEET, {
    skip: typeof sheetId !== 'string',
    variables: { sheetId }
  })

  const {
    schemaProperties,
    findJsonProp,
    requiredProperties,
    templateName,
    templateId,
    linkedTemplates,
    allowCustomFields,
    preventUserDefinedCustomFields
  } = useMemo(() => {
    const props = viewSchema.result
      ? (deserializeSchema({ ...viewSchema.result }, true)
          .jsonSchemaPropArray as JsonSchemaProperty[])
      : null

    return {
      schemaProperties: props,
      templateName: viewSchema.result?.name,
      templateId: viewSchema.result?.id,
      linkedTemplates: viewSchema.result?.linkedSchemas,
      requiredProperties: (viewSchema.result?.jsonSchema.schema.required ?? []) as string[],
      allowCustomFields: (viewSchema.result?.jsonSchema?.schema?.allowCustomFields ??
        false) as boolean,
      preventUserDefinedCustomFields: preventUserCustomFieldsQuery,
      findJsonProp: (matchKey?: string) =>
        matchKey ? props?.find((p) => p.field === matchKey) : null
    }
  }, [viewSchema.result])

  useEffect(() => {
    if (sheet.result?.schemaVariant?.jsonSchema?.schema?.properties) {
      setCustomProperties(
        deserializeSchema({ ...sheet.result.schemaVariant }).jsonSchemaPropArray.map(
          (prop: any) => ({
            ...prop,
            custom: true
          })
        )
      )
    }
  }, [sheet.result])

  useEffect(() => {
    if (batch?.schema?.id !== batch?.template?.id) {
      setCustomProperties(
        deserializeSchema({ ...batch.schema }).jsonSchemaPropArray.map((prop: any) => ({
          ...prop,
          custom: true
        }))
      )
    }
  }, [batch?.schema])

  const confirmDataSourceFields = useCallback<
    () => Promise<ConfirmUploadDataSourceFields_confirmUploadDataSourceFields>
  >(async () => {
    const customDataSourceFields = dataSourceFields.filter((dataSourceField) =>
      customProperties.find((prop) => prop.field === dataSourceField.matchKey)
    )

    const { data } = await confirmUploadDataSourceFields({
      variables: { viewId, ...(customDataSourceFields.length ? { customDataSourceFields } : {}) }
    })

    return data.confirmUploadDataSourceFields
  }, [dataSourceFields, customProperties])

  const updateDataSourceField = useCallback<
    (f: string | number, p: IUpdateDataSourceField) => Promise<void>
  >(
    async (fieldId, payload) => {
      fieldId = parseInt(fieldId.toString(), 10)
      const dataSourceField = dataSourceFields.find((f) => f.id === fieldId)

      if (!dataSourceField) {
        console.log('invalid field', fieldId, dataSourceFields)
        return
      }

      const existingCustomProperty = customProperties.find(
        (prop) => prop.field === payload.matchKey
      )
      if (payload.customMatchKey || existingCustomProperty) {
        if (existingCustomProperty) {
          dataSourceField.update({
            matchKey: payload.matchKey,
            label: existingCustomProperty.label,
            customMatchKey: true
          })
        } else {
          const customDataSourceField = {
            field: `custom_${snakeCase(payload.matchKey)}`,
            label: payload.matchKey
          }
          if (!findJsonProp(customDataSourceField.field)) {
            dataSourceField.update({
              matchKey: customDataSourceField.field,
              customMatchKey: true
            })
            setDataSourceFields(dataSourceFields.slice())
            if (!customProperties.find((prop) => prop.field === customDataSourceField.field)) {
              setCustomProperties((customProps) =>
                customProps.concat([
                  {
                    ...customDataSourceField,
                    type: 'string',
                    required: false,
                    primary: false,
                    unique: false,
                    custom: true
                  }
                ])
              )
            }
            return
          }
          payload.matchKey = customDataSourceField.field
        }
      }

      if (payload.matchKey) {
        const jsonProp = findJsonProp(payload.matchKey)

        if (jsonProp?.$schema && payload.matchKey.startsWith(`${jsonProp.$schema.matchKey}::`)) {
          payload.matchKeySub = payload.matchKey.replace(`${jsonProp.$schema.matchKey}::`, '')
          payload.matchKey = jsonProp.$schema.matchKey
        }
      }

      const { data } = await updateUploadDataSourceField({
        variables: {
          id: fieldId,
          ...payload
        }
      })

      const updates = { ...data.updateUploadDataSourceField.data }

      dataSourceField.update({
        ...omit(updates, ['id']),
        jsonProp: findJsonProp(updates.matchKey)
      })
      if (updates.matchKey && updates.matchKeySub) {
        dataSourceField.originalMatchKey = updates.matchKey
        dataSourceField.matchKey = `${updates.matchKey}::${updates.matchKeySub}`
      }

      setDataSourceFields(dataSourceFields.slice())
      setLinkValidation(
        data.updateUploadDataSourceField.validation?.filter(
          (v) => v.type === ESourceFieldErrorType.LINKED
        )
      )
      setMatchesUpdated(true)
    },
    [dataSourceFields]
  )

  useEffect(() => {
    if (viewDataSourceFields.result?.data?.length && schemaProperties && !dataSourceFields) {
      setDataSourceFields(
        viewDataSourceFields.result?.data
          .map((field) => {
            const parsedField = {
              ...field,
              ...(field.matchKey && field.matchKeySub
                ? {
                    matchKey: `${field.matchKey}::${field.matchKeySub}`,
                    originalMatchKey: field.matchKey
                  }
                : {})
            }
            return new DataSourceField({
              ...parsedField,
              jsonProp: findJsonProp(parsedField.matchKey)
            })
          })
          .slice()
      )
      setLinkValidation(
        viewDataSourceFields.result?.validation?.filter(
          (v) => v.type === ESourceFieldErrorType.LINKED
        )
      )
    }
  }, [viewDataSourceFields.result?.data.length, schemaProperties])

  if (viewDataSourceFields.alert || viewSchema.alert || batchQuery.alert) {
    return {}
  }

  return {
    confirmDataSourceFields,
    dataSourceFields,
    loading: updateLoading || confirmLoading,
    requiredProperties,
    schemaProperties: schemaProperties.concat(customProperties),
    allowCustomFields,
    preventUserDefinedCustomFields,
    updateDataSourceField,
    linkValidation,
    workspaceDataEngine: batch?.workspace?.dataEngine,
    templateName,
    templateId,
    linkedTemplates,
    matchesUpdated
  }
}
