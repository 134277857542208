import { RowData } from '@turntable/core'
import { RowChangeDetailWithID } from '@turntable/core/dist/types/Row'

import { CellDataPropsWithColumn, IAtomMessage } from './atoms/Atom'
import { Schema } from './Schema'
import { VirtualRecordFragment } from './VirtualRecordFragment'

export class VirtualRecord extends VirtualRecordFragment {
  constructor(schema: Schema, state: PrimitiveRecord, protected meta: IRecordMeta) {
    super(schema)
    this.state = state
  }

  /**
   * Return a turntable compatible view of this record.
   *
   * @returns
   */
  public toTurntableProps(): RowData {
    const cells = this.toCellDataProps()
    return {
      cells,
      rowIndex: this.meta.ordinal, // visual row number
      position: this.meta.pointer, // sort position
      id: this.meta.id
    }
  }

  /**
   * Return a turntable patch compatible format of this record.
   *
   * @returns
   */
  public toTurntablePatch(keys?: string[]): RowChangeDetailWithID {
    const { ordinal: rowIndex, pointer: position } = this.meta
    const cells = this.toCellDataProps(keys).map((cell) => ({
      ...cell,
      rowIndex,
      position
    }))

    return {
      patch: cells,
      rowIndex: this.meta.ordinal, // visual row number
      position: this.meta.pointer, // sort position
      id: this.meta.id
    }
  }

  /**
   * Get messages for a specific key
   *
   * @param key
   * @returns
   */
  protected getMessages(key: string): IAtomMessage[] {
    return this.meta.messages
      .filter((m) => m.key === key)
      .map(({ level, message }) => ({ level, message }))
  }

  /**
   * Returns an array of cell data properties ready for turntable
   *
   * @todo this possibly should be a private method
   * @todo support different configurations of what columns to display here
   * @returns
   */
  private toCellDataProps(keys?: string[]): CellDataPropsWithColumn<any>[] {
    return this.schema.propertyNames
      .filter(
        (p) =>
          (keys?.length ? keys.indexOf(p) !== -1 : true) && this.schema.propertyVisibleAtReview(p)
      )
      .map((key) => {
        return this.getAtom(key).toCellProps()
      })
  }
}

export type IRecordMeta = {
  id: string
  state: ERecordState
  valid: boolean
  pointer: number
  ordinal: number
  messages: IRecordMessage[]
}

export type IRecordMessage = IAtomMessage & { key: string }

export enum ERecordState {
  REVIEW = 'review',
  ACCEPTED = 'accepted',
  DISMISSED = 'dismissed'
}

export type Primitive = boolean | string | number | null

export type PrimitiveRecord = Record<string, Primitive>
