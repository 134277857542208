import { ReactElement, ReactNode } from 'react'

import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { getBasicInfoContext } from 'src/contexts/BasicInfoContext'
import { getUserContext } from 'src/contexts/UserContext'
import { Message } from 'src/resources/elements/Message'
import { Spinner } from 'src/resources/elements/Spinner'
import { identifyPosthog } from 'src/resources/utils/analytics'

type TSessionRequiredProps = RouteComponentProps & {
  children?: () => ReactNode
  onUnauthenticatedUrl: string
}

export const SessionRequired = withRouter<
  TSessionRequiredProps,
  (props: TSessionRequiredProps) => ReactElement
>(({ children, onUnauthenticatedUrl }: TSessionRequiredProps) => {
  const { user } = getUserContext().value
  const {
    success,
    data: { myTeams }
  } = getBasicInfoContext().value

  if (!user) {
    return <Redirect to={onUnauthenticatedUrl} />
  } else {
    identifyPosthog({ email: user.email, user_id: user.id })
  }

  if (!success) {
    return (
      <Message center>
        <Spinner />
        Please wait, loading...
      </Message>
    )
  }

  if (!myTeams || myTeams.length === 0) {
    return <Redirect to='/sign-up/personalize' />
  }

  if (!children) {
    return <></>
  }

  return <>{children()}</>
})
