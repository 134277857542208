import { memo, useMemo } from 'react'
import TurnTable, {
  SortOptions,
  ColumnConfigProps,
  OnCellsChange,
  OnSelectedRows,
  RowData,
  OnSort
} from '@turntable/core'
import { EmptyTableState } from 'src/applications/Oversight/components/EmptyTableState'
import { IRowCounts } from 'src/utils/emptyTableMethods'

import styled, { useTheme } from 'styled-components'
import { Loader } from 'src/applications/Embed/fragments/Loader'

const DataTableWrapper = styled.div<{ tableHeight?: string }>`
  position: relative;
  height: ${({ tableHeight }) => tableHeight ?? `100%`};
  width: 100%;
  max-width: 100%;
  z-index: 0;
`

export interface IDataTable {
  additionalRows?: number
  onCellsChange?: OnCellsChange
  onDataLoad?: any
  onSelectedRows?: OnSelectedRows
  onSort?: OnSort
  pageSize?: number
  // cells are still selectable in readOnly view - just not editable
  readOnly?: boolean
  rowCounts?: IRowCounts
  selectedRows?: number[]
  tableHeaders?: ColumnConfigProps[]
  tableRows?: RowData[]
  totalCount: number
  tableHeight?: string
  sortOverride?: { sortOrder: SortOptions; sortColumn: number }
  isLoading?: boolean
  isLoadingFullPage?: boolean
}

const MemoizedTable = memo(TurnTable)

export const DataTable = ({
  additionalRows,
  rowCounts,
  onCellsChange,
  onDataLoad,
  onSelectedRows,
  onSort,
  readOnly,
  tableHeaders,
  tableRows,
  totalCount,
  selectedRows,
  pageSize,
  tableHeight,
  sortOverride,
  isLoading,
  isLoadingFullPage
}: IDataTable) => {
  const theme: any = useTheme()

  const emptyState = useMemo(() => {
    if (isLoading) {
      return <Loader top={0} height='100%' message='Loading...' />
    }
    return <EmptyTableState rowCounts={rowCounts} />
  }, [isLoading, rowCounts])

  return (
    <DataTableWrapper tableHeight={tableHeight}>
      {tableHeaders && tableRows && (
        <MemoizedTable
          additionalRows={additionalRows}
          columnConfig={tableHeaders}
          totalRowCount={totalCount}
          initData={tableRows}
          onCellsChange={onCellsChange}
          onDataLoad={onDataLoad}
          onSort={onSort}
          onSelectedRows={onSelectedRows}
          pageSize={pageSize}
          readOnly={readOnly}
          selectedRows={selectedRows}
          sortOverride={sortOverride}
          theme={theme?.dataTable}
          isLoading={!!isLoading}
          isLoadingFullPage={!!isLoadingFullPage}
          emptyState={emptyState}
        />
      )}
    </DataTableWrapper>
  )
}
