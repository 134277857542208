import i18next from 'i18next'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import { localeEn, localeFr } from 'src/applications/Embed/translations'

// this determines at the root level whether or not this is an embed or a workspace import
const isPortalV3Url = window.location.pathname.includes('/e/')
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: 'en',
    preload: ['en'],
    nsSeparator: ':',
    keySeparator: '.',
    nonExplicitSupportedLngs: true,
    resources: {
      en: { translation: localeEn },
      fr: { translation: localeFr }
    },
    backend: {
      crossDomain: true,
      allowMultiLoading: false
      // for all available options read the backend's repository readme file
    },
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    detection: {
      order: ['navigator', 'querystring', 'cookie', 'localStorage', 'htmlTag']
    }
  })
  .then()

// this will set the language to the default english if it is a workspace import
if (!isPortalV3Url) {
  i18next.changeLanguage('en').then()
}
// use 'cimode' instead of 'en' to force the keys to display instead of the locale
// i18next.changeLanguage('en').then()

export default i18n
