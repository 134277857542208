import {
  BooleanRenderer,
  LookupEditor,
  LookupRenderer,
  LookupOption,
  ColumnConfigProps
} from '@turntable/core'
import { IHeaderRow } from 'src/applications/Oversight/components/TableCells'
import { LinkIcon } from 'src/resources/elements/Icons'
import { SmartFetchWorkbookRows_fetchRowsWithSchema_rows } from 'src/smart/queries/types/SmartFetchWorkbookRows'
import { SmartGetSchema_getSchema_jsonSchema } from 'src/smart/queries/types/SmartGetSchema'

import { EPropertyType, Schema } from './data/Schema'
import { DALTransport } from './data/transports/DALTransport'
import { JsonSchemaProperty } from 'src/types/interfaces/ISchema'

/**
 * @deprecated use DALTransport directly
 */
export const formatV3RowsMatrixWithSchemaProperties = (
  rowData: SmartFetchWorkbookRows_fetchRowsWithSchema_rows[],
  schemaProperties: any,
  start = 0
) => {
  // todo: this should be done at a higher level
  const schema = new Schema({ properties: schemaProperties })
  return rowData.map((validatedRow, index) => {
    return DALTransport.fromDAL(schema, validatedRow, start + index).toTurntableProps()
  })
}

/**
 *
 * @param jsonSchema
 * @todo replace with schema-normalizer.tsx
 * @deprecated Use VirtualRecord directly
 * @returns
 */
export const transformEnums = (jsonSchema: {
  enumLabel: string[]
  enum: any[]
}): LookupOption[] => {
  return jsonSchema.enum.map((value, index) => {
    return { label: jsonSchema.enumLabel[index], value }
  })
}

export const getTableLabelByKey = (
  key: string,
  schema: SmartGetSchema_getSchema_jsonSchema['schema'],
  typeaheadSearch?: (term?: string) => Promise<LookupOption[]>
) => {
  const label = schema.properties[key].label
  const isEnum = 'enum' in (schema.properties[key] ?? {})
  const isBoolean = schema.properties[key].type === 'boolean'
  const isRequired = schema.required?.includes(key)
  const isTemplateLink = schema.properties[key].type === EPropertyType.SCHEMA_REF
  const icon = isTemplateLink ? <LinkIcon /> : null
  const description = schema.properties[key].description

  if (isEnum) {
    const transformedEnums = transformEnums(schema.properties[key])

    const CategoryLookupEditor = (props: any) => (
      <LookupEditor
        singleSelectionOnly={true}
        options={transformedEnums}
        {...props}
        value={props.value === null ? [] : props.value}
      />
    )

    const lookupRenderer = (props: any) => (
      <LookupRenderer singleSelectionOnly={true} options={transformedEnums} {...props} />
    )
    return {
      required: isRequired,
      disableClipboardEvents: true,
      renderer: lookupRenderer,
      editor: CategoryLookupEditor,
      value: label,
      defaultValue: [] as any[],
      description
    }
  }

  if (isBoolean) {
    return {
      disableEditor: true,
      disableClipboardEvents: true,
      renderer: BooleanRenderer,
      value: label,
      defaultValue: false,
      description
    }
  }

  if (isTemplateLink) {
    const TemplateLookupEditor = (props: any) => {
      return (
        <LookupEditor
          singleSelectionOnly={true}
          {...props}
          value={props.value === null ? [] : props.value}
          options={typeaheadSearch}
          refetchOnChange
        />
      )
    }
    const lookupRenderer = (props: any) => <LookupRenderer singleSelectionOnly={true} {...props} />

    return {
      required: isRequired,
      disableClipboardEvents: true,
      icon,
      renderer: lookupRenderer,
      editor: TemplateLookupEditor,
      value: label,
      default: '',
      defaultValue: [] as any[],
      description
    }
  }

  return { value: label, defaultValue: '', description }
}

export const useTableColumnsBySchema = (
  schema: SmartGetSchema_getSchema_jsonSchema['schema'],
  createSearchFunction?: (schemaId: string) => (term?: string) => Promise<LookupOption[]>
): { labels: ColumnConfigProps[]; headers: IHeaderRow[] } =>
  Object.entries(schema.properties).reduce(
    (acc, [key, value]: [string, JsonSchemaProperty]) => {
      if (value.visibility?.review === false) {
        return acc
      }

      const typeaheadSearch =
        value.hasOwnProperty('$schemaId') && createSearchFunction?.(value.$schemaId)
      acc.labels.push(getTableLabelByKey(key, schema, typeaheadSearch))
      acc.headers.push({ value: key })
      return acc
    },
    { headers: [], labels: [] }
  )
