import { FlatfileButton } from '@flatfile/react'
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Link } from 'react-router-dom'
import { EmbedKeys } from 'src/applications/Oversight/components/EmbedKeys'
import { useConfirmModal } from 'src/applications/Oversight/hooks/useConfirmModal'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { FLATFILE_REFINERY_URL } from 'src/config'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { CodeSnippet } from 'src/resources/elements/CodeSnippet'
import { BooleanTextInput } from 'src/resources/elements/form/BooleanInput'
import { ButtonWrap } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, getFormContext, TForm } from 'src/resources/elements/form/Form'
import { IInputProps, Input, InputLabel } from 'src/resources/elements/form/Input'
import { SelectOption } from 'src/resources/elements/form/Select'
import { Icon } from 'src/resources/elements/Icon'
import { PlusIcon } from 'src/resources/elements/Icons'
import { Tab, Tabstrip } from 'src/resources/elements/Tabstrip'
import { Spacing } from 'src/resources/layout'
import { ErrorBadge } from 'src/resources/sidebars/Menu'
import { fontAwesomeIconName } from 'src/resources/types/fontAwesomeIconName'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { submitForm } from 'src/resources/utils/submitForm'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_TEST_REST_WEBHOOK_ENDPOINT } from 'src/smart/queries/SQ_TEST_REST_WEBHOOK_ENDPOINT'
import { SmartEmbed_getEmbed } from 'src/smart/queries/types/SmartEmbed'
import { ISchemaStub } from 'src/types/interfaces/ISchema'
import { sortAndMapSchemas } from 'src/utils/sortAndMapSchemas'
import { VendorSplitFlagNames } from 'src/vendor/vendorSplit'
import styled, { createGlobalStyle } from 'styled-components'
import { useTeamSplitFlag } from '../hooks/useTeamSplitFlag'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'

const EditEmbedWrapper = styled.div`
  padding: ${Spacing.basePadding4x};
`

const AlignedInlineFlexDiv = styled.div`
  display: flex;
  align-items: flex-end;
`

const EditEmbedHeadline = styled.h1`
  font-size: ${fontSizes.type24};
  font-family: ${fontFamily.fontPrimary};
  margin: 0;
  font-weight: 700;
`
const EditEmbedSubheadWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: ${Spacing.basePadding3x};
  margin: ${Spacing.basePadding} 0 0;
`

const EditEmbedSubhead = styled.p`
  font-size: ${fontSizes.type15};
  margin: 0;
  strong {
    font-weight: 600;
  }
`

export const EditEmbedSectionWrapper = styled.div`
  padding: ${Spacing.basePadding4x} 0;
  border-top: 1px solid ${Colors.border};
  display: flex;
`

const EditInputSectionWrapper = styled(EditEmbedSectionWrapper)`
  border-top-width: 2px;
`

const HelpSectionWrapper = styled(EditEmbedSectionWrapper)`
  justify-content: center;
  padding-bottom: 0;
  a {
    color: ${Colors.brandLightBlue};
    font-family: ${fontFamily.fontSecondary};
    font-weight: 400;
  }
  p {
    margin: 0;
  }
`

const EditEmbedInfoWrapper = styled.div`
  width: 261px;
  margin-right: 96px;
  h2 {
    font-size: ${fontSizes.type15};
    margin: 0;
    font-weight: 700;
  }
  p {
    margin-top: 8px;
    font-size: ${fontSizes.type14};
  }
`

export const EditEmbedContentWrapper = styled.div`
  width: 100%;
  max-width: 328px;
  ${Tabstrip} {
    padding: 0;
  }
`

export const EditEmbedContentWrapperXL = styled.div`
  width: 100%;
  max-width: 528px;
  ${Tabstrip} {
    padding: 0;
  }
`

export const InputContainer = styled.div`
  & + & {
    margin-top: ${Spacing.basePadding3x};
  }
  &:last-of-type {
    margin-bottom: ${Spacing.basePadding2x};
  }
  &:first-of-type ${InputLabel} {
    margin-top: 0;
  }
`

const FlatfileSDKOverrides = createGlobalStyle`
  body .flatfile-sdk {
    padding: 0px;
    iframe {
      height: calc(100%);
      width: calc(100%);
      border-radius: 0;
    }
    .flatfile-close {
      z-index: 1;
      &:after {
        color: inherit;
      }
      color: ${themed('borderColor')};
      &:hover {
        color: ${Colors.warningStrong};
      }
    }
  }
`
enum TestURLStatusEnum {
  initial,
  loading,
  success,
  failed
}

interface URLTestFormInput extends IInputProps {
  legacy?: boolean
  onSuccess?: () => void
  onFailure?: (message?: string) => void
}

type TestURLStatusTupleType = { status: TestURLStatusEnum; message?: string }

export const URLTestFormInput = ({
  legacy = false,
  onSuccess,
  onFailure,
  ...props
}: URLTestFormInput) => {
  const formContext = getFormContext()
  const team = useContext(TeamContext)

  const [statusTuple, setStatusTuple] = useState<TestURLStatusTupleType>({
    status: TestURLStatusEnum.initial
  })

  const testingURL = useMemo(() => {
    if (statusTuple.status !== TestURLStatusEnum.loading) {
      return ''
    }
    return formContext.value.data[props.name]
  }, [formContext, statusTuple])
  const testEndpoint = useSmartQuery(SQ_TEST_REST_WEBHOOK_ENDPOINT, {
    skip: statusTuple.status !== TestURLStatusEnum.loading,
    fetchPolicy: 'network-only',
    variables: { uri: testingURL, teamId: team.id, legacy }
  })

  useEffect(() => {
    if (statusTuple.status === TestURLStatusEnum.loading && testEndpoint.result) {
      if (testEndpoint.result?.success) {
        onSuccess?.()
        setStatusTuple({ status: TestURLStatusEnum.success })
      } else {
        onFailure?.(testEndpoint.result?.message)
        setStatusTuple({ status: TestURLStatusEnum.failed, message: testEndpoint.result?.message })
      }
    }
  }, [testEndpoint])

  const submitCallback = useCallback(
    (e: { preventDefault: () => void }) => {
      e.preventDefault()
      if (statusTuple.status !== TestURLStatusEnum.initial) {
        setStatusTuple({ status: TestURLStatusEnum.initial })
      } else {
        setStatusTuple({ status: TestURLStatusEnum.loading })
      }
    },
    [formContext, statusTuple]
  )

  const cleanStatusCallback = useCallback(
    (event: any) => {
      if (statusTuple.status !== TestURLStatusEnum.initial) {
        setStatusTuple({ status: TestURLStatusEnum.initial })
      }
      if (props.onKeyDown) {
        props.onKeyDown(event)
      }
    },
    [statusTuple]
  )

  return (
    <div>
      <AlignedInlineFlexDiv>
        <Input {...props} onKeyDown={cleanStatusCallback} />
        <ButtonWrap style={{ marginLeft: Spacing.basePadding }}>
          <FlatButton
            disabled={statusTuple.status === TestURLStatusEnum.loading}
            style={{ minHeight: Spacing.basePadding5x }}
            color={
              statusTuple.status === TestURLStatusEnum.loading
                ? 'secondary'
                : statusTuple.status === TestURLStatusEnum.success
                ? 'success'
                : statusTuple.status === TestURLStatusEnum.failed
                ? 'danger'
                : 'secondary'
            }
            onClick={submitCallback}
          >
            {statusTuple.status === TestURLStatusEnum.initial
              ? `Test ${props.label}`
              : statusTuple.status === TestURLStatusEnum.loading
              ? 'Loading...'
              : statusTuple.status === TestURLStatusEnum.success
              ? 'Success'
              : statusTuple.status === TestURLStatusEnum.failed
              ? 'Failed'
              : ''}
          </FlatButton>
        </ButtonWrap>
      </AlignedInlineFlexDiv>
      {!statusTuple.message ? null : (
        <div
          style={{
            fontSize: '0.7em',
            fontFamily: fontFamily.fontSecondary,
            color: Colors.grayDark
          }}
        >
          <p>
            Invalid Webhook URL ({statusTuple.message}) •{' '}
            <a href='https://flatfile.com/docs/rest-webhooks-3-0/'>Learn More</a>
          </p>
        </div>
      )}
    </div>
  )
}

export interface IEditEmbedFormData {
  archived: boolean
  name: string
  helpContent: string
  schema: string
  schemaName: string
  id: string
  legacyWebhookURL?: string
}

interface ICodeSnippet {
  code: string
  fileName?: string
  framework: string
  install?: string
  tabName: string
}

export const HelpSection = ({ ...props }) => {
  return (
    <HelpSectionWrapper style={props.style}>
      <p>
        {props.text}
        <a href={props.url} target='_blank' rel='noopener noreferrer'>
          {props.linkMessage}
        </a>
      </p>
    </HelpSectionWrapper>
  )
}

const StyledEditorTab = styled.div`
  background: rgb(50, 55, 63);
  border-top-left-radius: ${Spacing.basePadding};
  border-top-right-radius: ${Spacing.basePadding};
  border-top: 2px solid ${Colors.blue};
  border-bottom: 2px solid rgb(50, 55, 63);
  box-shadow: inset 0 -2px 2px rgba(80, 88, 104);
  color: white;
  display: inline-block;
  font-size: 13px;
  margin-bottom: -14px;
  min-width: 180px;
  padding: ${Spacing.basePadding} ${Spacing.basePadding2x};
  position: relative;
  width: auto;
  margin-top: 10px;

  i {
    filter: grayscale(1) contrast(0.5);
    margin-right: ${Spacing.basePadding};
  }
`

function EditorTab({ icon = 'file-alt', title }: { icon?: fontAwesomeIconName; title: string }) {
  return (
    <StyledEditorTab>
      <label>
        <Icon name={icon} />
        {title}
      </label>
    </StyledEditorTab>
  )
}
interface Controllable<T> {
  state: T
  setState: Dispatch<SetStateAction<T>>
}

export const CodeSwitcher = ({
  activeTab,
  snippets
}: {
  activeTab: Controllable<string>
  snippets: ICodeSnippet[]
}) => {
  return (
    <Tabstrip>
      {snippets.map((snippet) => (
        <Tab
          key={`tab-${snippet.tabName}`}
          active={activeTab.state === snippet.tabName}
          onClick={() => activeTab.setState(snippet.tabName)}
        >
          {snippet.tabName}
        </Tab>
      ))}
    </Tabstrip>
  )
}

const EnableFormAfterSubmit = (trigger: any) => {
  const formContext = getFormContext()
  useEffect(() => {
    formContext.setValue({ submitting: false })
  }, [trigger])
  return <></>
}

export const CodeSnippetSwitcher = ({
  activeTab,
  snippets
}: {
  activeTab: Controllable<string>
  snippets: ICodeSnippet[]
}) => {
  return (
    <>
      {snippets
        .filter((snippet) => snippet.tabName === activeTab.state)
        .map((snippet) => (
          <Fragment key={`group-${snippet.tabName}`}>
            {snippet.install && (
              <>
                <EditorTab icon='terminal' title='Installation' />
                <CodeSnippet
                  width='500px'
                  key={`bash-${snippet.tabName}`}
                  copyButton
                  framework='bash'
                >
                  {snippet.install}
                </CodeSnippet>
              </>
            )}
            {typeof snippet.fileName === 'string' && <EditorTab title={snippet.fileName} />}
            <CodeSnippet
              width='500px'
              key={`code-${snippet.tabName}`}
              copyButton
              framework={snippet.framework}
            >
              {snippet.code}
            </CodeSnippet>
          </Fragment>
        ))}
    </>
  )
}

export const EditEmbedForm = ({
  showPrivateKey,
  setShowPrivateKey,
  embed,
  schemas,
  onSubmit,
  onResetPrivateKey,
  privateKey
}: {
  showPrivateKey: boolean
  setShowPrivateKey: (value: boolean) => void
  embed: SmartEmbed_getEmbed & { testingToken: string }
  schemas: ISchemaStub[]
  onSubmit: (data: any) => void | Promise<void>
  onResetPrivateKey: () => void
  privateKey?: string
}) => {
  const EditEmbedFormElement: TForm<IEditEmbedFormData> = Form
  const embedFormRef = useRef<HTMLFormElement>()
  const isLegacyWebhookURLSplitEnabled = useTeamSplitFlag(VendorSplitFlagNames.LegacyWebhookURL)
  const schemaOption: SelectOption = useMemo(
    () => sortAndMapSchemas(schemas).find((item) => item.value === embed.schemas[0].id),
    [schemas, embed.schemas]
  )
  const embedSchema = schemas.find((item) => item.id === embed.schemas[0].id)

  const EMBED_IS_FROM_SDK = !!embedSchema.slug

  const schemaName = schemaOption.label

  const confirmReset = () => {
    onResetPrivateKey()
    setShowPrivateKey(true)
    confirmResetModal.close()
  }

  const confirmResetModal = useConfirmModal({
    header: 'Are you sure?',
    onConfirm: confirmReset,
    contents: (
      <>
        This will generate a new private key and cannot be undone. The previous private key will no
        longer be available.
      </>
    )
  })

  const autoSaveEmbed = useCallback(() => {
    window.setTimeout(() => {
      submitForm(embedFormRef.current)
    })
  }, [embedFormRef])

  const showConfirmModal = () => {
    confirmResetModal.open()
  }

  const teamRoot = useTeamRootUrl()

  const [schema] = embed.schemas

  const mountUrl = new URL(window.location.origin)
  console.log({ mountUrl })
  // enforce HTTPS at all times:
  if (!mountUrl.protocol.includes('https') && !mountUrl.host.includes('localhost')) {
    mountUrl.protocol = 'https:'
  }

  return (
    <EditEmbedWrapper data-testid='edit-portal-form'>
      {confirmResetModal.render()}
      <EditEmbedFormElement
        onSubmit={onSubmit}
        formRef={embedFormRef}
        initialValue={{
          legacyWebhookURL: embed.legacyWebhookURL,
          archived: embed.archived,
          name: embed.name,
          helpContent: embed.helpContent,
          schema: schema.id,
          schemaName,
          id: embed.id
        }}
      >
        <EnableFormAfterSubmit trigger={embed.updatedAt} />
        <FlatfileSDKOverrides />
        <FlatfileButton
          token={embed.testingToken}
          mountUrl={mountUrl.origin}
          apiUrl={FLATFILE_REFINERY_URL}
          onInit={({ meta, session }) => {
            console.log(`Flatfile importer is launched with batchId: ${meta.batchId}`)
            session.openInEmbeddedIframe({})
          }}
          onComplete={async (payload) => {
            console.log(JSON.stringify(await payload.data(), null, 4))
          }}
          onData={(chunk, next) => {
            console.log(
              `CHUNK ${chunk.currentChunkIndex}`,
              chunk.records.map((r) => r.data)
            )

            next()
          }}
          onError={(err) => console.error(err)}
          render={(importer) => {
            return (
              <FlatButton
                style={{ float: 'right' }}
                onClick={() => {
                  importer.launch()
                }}
              >
                Test
              </FlatButton>
            )
          }}
        />

        <BooleanTextInput
          style={{ float: 'right', lineHeight: '36px', marginRight: '20px' }}
          checkedIcon={<PlusIcon />}
          onChange={autoSaveEmbed}
          label='Archive this Portal?'
          name='archived'
          checkedText='Restore this Portal'
          uncheckedText='Archive this Portal'
          data-testid='edit-portal-archive-toggle'
        />
        <EditEmbedHeadline data-testid='edit-portal-headline'>{embed.name}</EditEmbedHeadline>
        <EditEmbedSubheadWrapper>
          <EditEmbedSubhead>
            All files uploaded to this Portal will appear in your{' '}
            <Link to={`${teamRoot}/imports/?s=${schema.id}`}>Imports</Link>.
          </EditEmbedSubhead>
        </EditEmbedSubheadWrapper>
        <EditInputSectionWrapper>
          <EditEmbedInfoWrapper>
            <h2>Portal details</h2>
            <p>
              Your private key can only be copied now. If you need the private key again, you'll
              need to reset it.
            </p>
          </EditEmbedInfoWrapper>
          <EditEmbedContentWrapper>
            <EmbedKeys
              embed={embed}
              newSDK={EMBED_IS_FROM_SDK}
              showPrivateKey={showPrivateKey}
              embedPrivateKey={privateKey}
              schemaName={schemaName}
              embedFunctions={{
                confirmModal: showConfirmModal,
                autoSave: autoSaveEmbed
              }}
              disabled={embed.archived}
              isEditable
            />
          </EditEmbedContentWrapper>
        </EditInputSectionWrapper>
        <EditEmbedSectionWrapper>
          <EditEmbedInfoWrapper>
            <h2>Embedding your Portal</h2>
          </EditEmbedInfoWrapper>
          <EditEmbedContentWrapper>
            <p>
              Visit our{' '}
              <a href='https://flatfile.com/docs/guides/embed/#embed-a-portal' target='_blank'>
                developer documentation
              </a>{' '}
              for a guide on how to embed your Portal into your application.
            </p>
          </EditEmbedContentWrapper>
        </EditEmbedSectionWrapper>
        {!isLegacyWebhookURLSplitEnabled ? null : (
          <EditEmbedSectionWrapper>
            <EditEmbedInfoWrapper>
              <h2>
                Manage webhooks <ErrorBadge>Legacy</ErrorBadge>
              </h2>
              <p>
                Configure a URL to receive HTTP POST requests to your webhook URL every time data
                is submitted to this Portal.
              </p>
            </EditEmbedInfoWrapper>
            <EditEmbedContentWrapperXL>
              <URLTestFormInput
                key='legacyWebhookUrl'
                disabled={embed.archived}
                onBlur={autoSaveEmbed}
                legacy
                label='Webhook URL'
                name='legacyWebhookURL'
                placeholder='http://example.com/flatfile/webhook'
                data-testid='portal-legacyWebhookURL'
              />
              <ErrorMessage name='legacyWebhookURL' />
            </EditEmbedContentWrapperXL>
          </EditEmbedSectionWrapper>
        )}
      </EditEmbedFormElement>
    </EditEmbedWrapper>
  )
}
