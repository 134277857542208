import { withRouter } from 'react-router'
import { SplitScreenTemplate } from 'src/applications/Oversight/templates/SplitScreenTemplate'
import { Carousel } from 'src/applications/Oversight/components/Carousel'
import {
  NavContainer,
  NavItem,
  StyledTooltip
} from 'src/applications/Oversight/components/SignUpNavigation'
import { Header } from 'src/resources/elements/Header'
import { EnrichUserDetailsForm } from 'src/applications/Oversight/forms/EnrichUserDetailsForm'
import { useContext } from 'react'
import { SessionContext } from 'src/contexts/SessionContext'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_TEAM } from 'src/smart/queries/SQ_TEAM'
import { queryAlert } from 'src/resources/elements/QueryAlert'

export const GithubScene = () => <GithubPersonalizePanel />

export const GithubPersonalizePanel = withRouter(() => {
  const { activeTeamId } = useContext(SessionContext)
  const teamQuery = useSmartQuery(SQ_TEAM, {
    fetchPolicy: 'network-only',
    variables: {
      teamId: activeTeamId
    }
  })

  if (teamQuery.alert) {
    return queryAlert(teamQuery)
  }

  const { users } = teamQuery.result

  return (
    <SplitScreenTemplate rightSide={<Carousel initialSlides={[3, 2]} />}>
      <NavContainer>
        <NavItem active={false} complete={true} data-tip='Create account' data-for='s1' />
        <NavItem active={true} complete={false} data-tip='Personalize' data-for='s2' />
        <StyledTooltip id='s1' place='top' type='dark' effect='float' />
        <StyledTooltip id='s2' place='top' type='dark' effect='float' />
      </NavContainer>
      <div>
        <Header>
          <h1>Personalize your experience</h1>
        </Header>
        <EnrichUserDetailsForm user={users[0]} teamId={activeTeamId} />
      </div>
    </SplitScreenTemplate>
  )
})
