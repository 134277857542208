import { useQuery } from '@apollo/client'
import { Span } from '@sentry/types'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { EditSchemaForm } from 'src/applications/Oversight/forms/SchemaForm'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { SchemaContext } from 'src/contexts/SchemaContext'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_SCHEMA } from 'src/queries/GET_SCHEMA'
import { useCloneSchemaMutation } from 'src/queries/hooks/useCloneSchemaMutation'
import { useUpdateSchemaMutation } from 'src/queries/hooks/useUpdateSchemaMutation'
import { CloneSchema_cloneSchema } from 'src/queries/types/CloneSchema'
import { GetSchema, GetSchemaVariables } from 'src/queries/types/GetSchema'
import { humanReadableGraphQLError } from 'src/resources/clients/graphClient'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { EFieldAction } from 'src/types/enums/EFieldAction'
import { ESchemaTab } from 'src/types/enums/ESchemaTab'
import { ISchema } from 'src/types/interfaces/ISchema'
import {
  deserialize as deserializeSchema,
  serialize as serializeSchema
} from 'src/utils/schema-normalizer'
import { VendorSplitFlagNames } from 'src/vendor/vendorSplit'
import useReactRouter from 'use-react-router'
import { LinkedSchema } from '../forms/EditSchemaPropertyForm'
import { useTeamSplitFlag } from '../hooks/useTeamSplitFlag'

// Sentry performance tracking
const schemaEditSpans: Partial<Span>[] = [
  {
    op: 'edit-template-loaded'
  },
  {
    op: 'edit-template-onsubmit'
  },
  {
    op: 'edit-template-success'
  }
]

interface ISubmitEvent extends Event {
  data: ISchema
}

export const SchemaEditPanel = ({
  fieldAction,
  fieldId,
  schemaId,
  showHeader,
  showArchiveToggle,
  onSchemaIdUpdate
}: {
  fieldAction?: EFieldAction
  fieldId?: string
  schemaId: string
  showHeader?: boolean
  showArchiveToggle?: boolean
  onSchemaIdUpdate(id: number | string, tab: string, dataHook?: string): void
}) => {
  const variables = {
    schemaId
  }
  const team = useContext(TeamContext)
  const { history } = useReactRouter()
  const { addToast } = useToasts()
  const [updateSchema] = useUpdateSchemaMutation()
  const [cloneSchema] = useCloneSchemaMutation()
  const [jsonSchemaErrors, setJsonSchemaErrors] = useState([])
  const { loading, error, data, refetch } = useQuery<GetSchema, GetSchemaVariables>(GET_SCHEMA, {
    variables,
    fetchPolicy: 'network-only'
  })
  const [schema, setSchema] = useState<ISchema | null>(null)
  const tabState = useSearchParam.string('tab', ESchemaTab.Configure)

  const enableSchemaEditorForPlatformSDKDeployments =
    useTeamSplitFlag(VendorSplitFlagNames.EnableSchemaEditorForPlatformSDKDeployments) ||
    team.featureFlags?.ENABLE_SCHEMA_EDITOR_FOR_PLATFORM_SDK_DEPLOYMENTS

  const SCHEMA_DEPLOYED_FROM_SDK = !!schema?.slug
  const SCHEMA_IS_EDITABLE =
    !SCHEMA_DEPLOYED_FROM_SDK || enableSchemaEditorForPlatformSDKDeployments
  const disabled = !(SCHEMA_IS_EDITABLE || loading)

  const sentryTransaction = useSentryTransaction({
    spans: schemaEditSpans
  })

  submitSentrySpan(sentryTransaction, 'edit-template-loaded')

  const { getSchema: newSchema = null } = data ?? {}

  const duplicateSchema = useCallback(
    async (cloneSchemaVariables: { schemaId: string; environmentSlug?: string }) => {
      const response = await cloneSchema({ variables: cloneSchemaVariables })
      const cloned: CloneSchema_cloneSchema = response.data.cloneSchema
      if (cloneSchemaVariables.environmentSlug) {
        history.push(
          `/a/${team.id}/env/${cloneSchemaVariables.environmentSlug}/templates/${cloned.id}`
        )
        return
      }
      onSchemaIdUpdate(cloned.id, ESchemaTab.Configure)
      setSchema(null)
      await refetch({ schemaId: cloned.id })
      addToast('Cloned successfully', {
        appearance: 'success',
        autoDismiss: true
      })
    },
    [cloneSchema, onSchemaIdUpdate, setSchema, refetch, addToast]
  )

  // de-serializing schema
  const deSerializedSchema: ISchema = useMemo(() => {
    if (schema !== null) {
      return deserializeSchema({ ...schema })
    }
  }, [schema])

  const saveSchema = async (
    schemaEventData: ISchema,
    resetForm: () => void,
    tab: string = null
  ) => {
    const newJsonSchemaHasNoProperties =
      Object.keys(schemaEventData.jsonSchema?.schema?.properties ?? {}).length === 0
    const nullishJSONSchemas = newJsonSchemaHasNoProperties && schema.jsonSchema === null
    const jsonSchemaChanged =
      !nullishJSONSchemas &&
      JSON.stringify(schemaEventData.jsonSchema) !== JSON.stringify(schema.jsonSchema)
    const { previewFieldKey } = schemaEventData

    if (
      !jsonSchemaChanged &&
      schemaEventData.name === schema.name &&
      schemaEventData.archived === schema.archived &&
      previewFieldKey === schema.previewFieldKey &&
      schemaEventData.linkedSchemas === schema.linkedSchemas
    ) {
      return
    }

    let updatedSchema = null
    try {
      const newData: Partial<ISchema> = {}

      newData.previewFieldKey = previewFieldKey ?? null

      if (jsonSchemaChanged) {
        newData.jsonSchema = schemaEventData.jsonSchema

        newData.previewFieldKey = Object.keys(newData.jsonSchema.schema.properties).includes(
          previewFieldKey
        )
          ? previewFieldKey
          : null
      }
      if (schemaEventData.name !== schema.name) {
        newData.name = schemaEventData.name
      }
      if (schemaEventData.archived !== schema.archived) {
        newData.archived = schemaEventData.archived
      }

      //update this schema
      updatedSchema = (
        await updateSchema({
          variables: {
            ...newData,
            schemaId
          }
        })
      ).data.updateSchema

      //update linked schemas
      const linkedSchemasToUpdate = schemaEventData.linkedSchemas.filter(
        (schema: LinkedSchema) => 'previewFieldKey' in schema
      )
      linkedSchemasToUpdate.forEach(async (schema: LinkedSchema) => {
        const updatedData = { schemaId: schema.id, previewFieldKey: schema.previewFieldKey }
        await updateSchema({
          variables: updatedData
        })
      })
      setJsonSchemaErrors([])
    } catch (error) {
      const errorMessage = humanReadableGraphQLError(error)
      setJsonSchemaErrors(errorMessage.split('\n'))
      throw error
    }

    addToast('Updated successfully', {
      appearance: 'success',
      autoDismiss: true
    })

    if (updatedSchema.id === schema.id) {
      await refetch(variables)
    } else {
      resetForm()
      onSchemaIdUpdate(updatedSchema.id, tab)
    }
  }

  useEffect(() => {
    if (
      newSchema !== null &&
      (newSchema.id !== schema?.id || newSchema.updatedAt !== schema?.updatedAt)
    ) {
      setSchema(newSchema)
    }
  }, [newSchema])

  const schemaFormRef = useRef<HTMLFormElement>()

  const resetForm = useCallback(() => schemaFormRef.current.reset(), [schemaFormRef])

  if (!schema || error) {
    return QueryAlert({ loading: !schema, error })
  }

  return (
    <SchemaContext.Provider value={deSerializedSchema}>
      <EditSchemaForm
        formRef={schemaFormRef}
        disabled={disabled}
        fieldAction={fieldAction}
        fieldId={fieldId}
        jsonSchemaErrors={jsonSchemaErrors}
        key={schema.id}
        schema={deSerializedSchema}
        showArchiveToggle={showArchiveToggle}
        showHeader={showHeader}
        onSchemaIdUpdate={onSchemaIdUpdate}
        tabState={tabState}
        onSubmit={async (event: ISubmitEvent) => {
          if (tabState !== ESchemaTab.Json) {
            const schemaEventData = serializeSchema({ ...event.data })
            await saveSchema(schemaEventData, resetForm, tabState)
          } else {
            await saveSchema(
              { ...schema, jsonSchema: event.data?.jsonSchema },
              resetForm,
              tabState
            )
          }
        }}
        onClone={async (environmentSlug?: string) => {
          await duplicateSchema({
            schemaId: schema.id,
            environmentSlug
          })
        }}
      />
    </SchemaContext.Provider>
  )
}
