import { ReactNode, useContext, useEffect } from 'react'
import { PreflightBatch_preflightBatch_config } from 'src/smart/mutations/types/PreflightBatch'
import { EColorName } from 'src/types/enums/EColorName'
import { createGlobalStyle, ThemeProvider } from 'styled-components'
import { SageTheme } from 'src/applications/Embed/themes/Sage/SageTheme'
import { FlexportTheme } from 'src/applications/Embed/themes/Flexport/FlexportTheme'
import { Colors } from 'src/resources/colors'
import { FileUploadIcon, StyledEmbedUploadIcon } from 'src/applications/Embed/themes/Default/Icons'
import { defaultTheme, CustomTheme } from '@turntable/core'
import { FeatureFlagContext } from 'src/applications/Embed/fragments/FeatureFlagContext'

export const GlobalStyles: React.FC<{ primaryColor?: string; fontFamily: string }> = (props) => {
  const CustomStyles = createGlobalStyle`
    ${
      props?.fontFamily &&
      `
        body, button, input, textarea, select {
          font-family: ${props?.fontFamily}, 'Proxima Nova', sans-serif !important;
        
        a {
          color: ${props?.primaryColor};
        }
      `
    }
    
`
  return <CustomStyles />
}

export const ThemeWrapper: ({
  config,
  children,
  onInit
}: {
  config: Partial<PreflightBatch_preflightBatch_config>
  children: ReactNode
  onInit?: (theme: any) => void
}) => JSX.Element = ({ config, children, onInit }) => {
  const features = useContext(FeatureFlagContext)
  const sageStyling = features.includes('SAGE_CUSTOM_STYLING')
  const flexportStyling = features.includes('FLEXPORT_CUSTOM_STYLING')
  const { theme } = config ?? {}
  const primary = theme?.colors.find((color) => color.name === EColorName.PRIMARY)?.hex

  const V3theme = {
    ...DefaultTheme,
    ...(primary ? { primary } : {}),
    ...(theme?.font?.fontFamily
      ? { fontFamily: `${theme.font.fontFamily}, Avenir, Helvetica, Arial, sans-serif` }
      : {}),
    ...(sageStyling ? { sage: true, ...SageTheme } : {}),
    ...(flexportStyling ? { flexport: true, ...FlexportTheme } : {})
  }

  useEffect(() => {
    onInit?.(V3theme)
  }, [config])

  const typekitEmbed = (
    <>
      <GlobalStyles fontFamily={theme?.font?.fontFamily} />
      {theme?.font?.typeKitId && (
        <link
          rel='stylesheet'
          type='text/css'
          href={`https://use.typekit.net/${theme.font.typeKitId}.css`}
        />
      )}
    </>
  )

  return (
    <ThemeProvider theme={V3theme}>
      {theme?.font && <>{typekitEmbed}</>}
      {V3theme?.customFontStack && (
        <>
          <GlobalStyles fontFamily={V3theme.customFontStack} />
        </>
      )}
      {V3theme?.externalCSS && (
        <style>{`
        @import url(${V3theme.externalCSS});
      `}</style>
      )}
      {V3theme?.externalCSS && (
        <style>{`
        @import url(${V3theme.externalCSS});
      `}</style>
      )}
      {children}
    </ThemeProvider>
  )
}

export const DefaultTheme = {
  // General
  showDataSourceStatus: true,
  showMatchIndicator: true,
  buttonLetterSpacing: '0.015em',
  placeholderColor: '#090B2B',
  rowHoverColor: '#E8EDF4',
  borderWidth: '1px',
  borderColor: Colors.border,
  primary: Colors.brandPrimary,
  primaryAccent: Colors.brandAccent2,
  secondary: '',
  contentFontSize: '16px',
  contentSmallFontSize: '16px',
  textColor: Colors.text,
  gutterSpacing: '40px',
  sidebarBackground: Colors.grayDarkBackground,
  defaultText: Colors.brandText,
  borderRadius: '5px',
  contentMaxWidth: '900px',
  sidebarMaxWidth: '600px',
  largeBorderRadius: '20px',
  boxShadow: true,
  boxShadowSpec: '0px -3px 7px rgba(0, 0, 0, 0.1)',
  largeBoxShadow: '0 0 0 1px hsla(0, 0%, 0%, 0.1),0 4px 11px hsla(0, 0%, 0%, 0.1)',
  cardsHaveBorder: true,
  invertedFileUpload: false,
  customFontStack: '',
  // Buttons
  buttonHeight: '40px',
  buttonLineHeight: '16px',
  buttonSize: '16px',
  buttonWeight: '500',
  buttonPadding: '10px 12px',
  buttonHover: '',
  // Footer
  footerBackgroundColor: 'rgba(255, 255, 255, 0.9)',
  footerHeight: '120px',
  footerGutter: '40px',
  footerButtonPadding: '20px 0',
  footerAlignment: 'flex-end',
  footerDirection: 'row',
  footerButtonHeight: '56px',
  footerButtonWidth: '448px',
  // Modals
  closeModalButtonStyles: {},
  modalContentStyle: {},
  modalHeaderStyles: {},
  modalHeaderTitleStyles: {},
  modalPrimaryButtonStyle: {},
  modalSecondaryButtonStyle: {},
  modalBackgroundColor: '#FFF',
  modalMaxWidth: '512px',
  // Page Header
  invertedIcon: false,
  titleFontSize: '21px',
  titleFontWeight: '600',
  titleFontColor: '#33475b',
  headerIconBoxSize: '64px',
  headerIconSize: '28px',
  // Menu select
  dropdownHeader_textTransform: 'uppercase',
  dropdownHeader_textColor: Colors.brandSecondaryText,
  dropdownHeader_fontSize: '12px',
  dropdownHeader_fontWeight: '600',
  dropdownHeader_letterSpacing: '0.15em',
  // Table
  dataTable: defaultTheme as CustomTheme,
  textOverrides: {
    headerInitial: 'textOverrides.headerInitial',
    dragAndDropHint: 'textOverrides.dragAndDropHint',
    fileTypeHint: 'textOverrides.fileTypeHint',
    headerSource: 'textOverrides.headerSource',
    headerSourceDesc: 'textOverrides.headerSourceDesc',
    headerCheck: 'textOverrides.headerCheck',
    headerCheckDesc: 'textOverrides.headerCheckDesc',
    headerCheckAction: '',
    headerMatch: 'textOverrides.headerMatch',
    headerMatchDesc: 'textOverrides.headerMatchDesc',
    headerReview: 'textOverrides.headerReview',
    headerReviewDesc: 'textOverrides.headerReviewDesc',
    templateFieldsLabel: 'textOverrides.templateFieldsLabel',
    ctaLabelFinal: 'textOverrides.ctaLabelFinal',
    rowFilter_allRows: 'textOverrides.rowFilter_allRows',
    rowFilter_valid: 'textOverrides.rowFilter_valid',
    rowFilter_error: 'textOverrides.rowFilter_error'
  },
  // TH on matching, header selection, etc.
  tableListHeader_FontSize: '16px',
  tableListHeader_FontWeight: '600',
  tableListHeader_LineHeight: '1.5',
  tableListHeader_LetterSpacing: '0',
  tableListHeader_Color: Colors.text,
  // TD in matching, header selection, etc.
  tableListItem_FontSize: '16px',
  tableListItem_FontWeight: '400',
  tableListItem_LineHeight: '1.5',
  tableListItem_LetterSpacing: '0',
  tableListItem_Color: Colors.text,
  iconOverrides_Upload: FileUploadIcon,
  iconOverrides_Header: <StyledEmbedUploadIcon />,
  externalCSS: ''
}

export type DefaultTheme = typeof DefaultTheme

export function themed<A extends keyof DefaultTheme>(
  attr: A
): (props: { theme: DefaultTheme }) => DefaultTheme[A] {
  return ({ theme }) => (theme?.[attr] === undefined ? DefaultTheme[attr] : theme[attr])
}
