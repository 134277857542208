import { useCallback, useEffect, useState } from 'react'
import { Spacer } from 'src/applications/Oversight/components/ActionsContainer'
import { BatchTimes } from 'src/applications/Oversight/components/BatchTimes'
import { ExportSheetButton } from 'src/applications/Oversight/components/ExportSheetButton'
import { FileDownloadDropdown } from 'src/applications/Oversight/components/FileDownloadDropdown'
import { ImportMetadata } from 'src/applications/Oversight/components/ImportMetadata'
import { SchemaCell } from 'src/applications/Oversight/components/SchemaCell'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import VerticalLine from 'src/applications/Oversight/components/VerticalLine'
import { useTeamSplitFlag } from 'src/applications/Oversight/hooks/useTeamSplitFlag'
import { FLATFILE_REFINERY_URL } from 'src/config'
import { EImportDetailView } from 'src/contexts/ActiveImportDetailContext'
import { GetBatch_getBatch } from 'src/queries/types/GetBatch'
import { Colors } from 'src/resources/colors'
import { ButtonBase, FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { GridCell, GridRow } from 'src/resources/elements/GridRow'
import { Header } from 'src/resources/elements/Header'
import { Icon } from 'src/resources/elements/Icon'
import { Spinner } from 'src/resources/elements/Spinner'
import { Tab, Tabstrip } from 'src/resources/elements/Tabstrip'
import { TrashCircleButton } from 'src/resources/elements/TrashCircleButton'
import { Spacing } from 'src/resources/layout'
import { fontSizes } from 'src/resources/typography'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_GET_FILE_ACCESS_TOKEN } from 'src/smart/queries/SQ_GET_FILE_ACCESS_TOKEN'
import { IBatch } from 'src/types/interfaces/IBatch'
import { getBatchFilename } from 'src/utils/getBatchFilename'
import { VendorSplitFlagNames } from 'src/vendor/vendorSplit'
import styled from 'styled-components'

export const SpreadSheetToolBarStyle = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${ButtonBase} + ${ButtonBase} {
    margin-left: ${Spacing.basePadding};
  }

  ${GridRow} {
    background: ${Colors.white};
    border: none;
    padding: 0 ${Spacing.basePadding2x} ${Spacing.basePadding2x};

    ${GridCell} {
      padding-top: 0;
    }
  }
`

export const FileStats = styled.div`
  display: flex;
  margin-right: 12px;

  & > div {
    margin-left: 12px;
    margin-right: 12px;
  }
`

export const FileName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 32px;

  h1 {
    font-size: ${fontSizes.type14};
    line-height: 1;
    margin: 0;
    margin-bottom: 4px;
  }
  p {
    color: ${Colors.brandSecondaryText};
    font-size: ${fontSizes.type13};
    line-height: 1;
    margin: 0;
  }
`

const FileNameContainer = styled.div`
  display: flex;

  > ${Header} > h1 {
    display: block;
    font-size: ${fontSizes.type20};
    line-height: 1.8;
    letter-spacing: 0.0125em;
  }
`
const TopRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${Spacing.basePadding4x};
`

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.border};
  margin-bottom: -1px;
`

export const SpreadSheetToolbar = ({
  activeViewType,
  batch,
  setActiveViewType,
  onViewHistory,
  onRemoveBatch
}: {
  activeViewType: EImportDetailView
  batch?: GetBatch_getBatch
  setActiveViewType: (type: EImportDetailView) => void
  onViewHistory(): void
  onRemoveBatch(): void
}) => {
  const tabs = Object.keys(EImportDetailView)
  const [downloadFileType, setDownloadFileType] = useState(null)
  const [downloadFileFormat, setDownloadFileFormat] = useState(null)
  const [fetchingAccessToken, setFetchingAccessToken] = useState(false)
  const downloadOriginal = useCallback(
    () => setDownloadFileType('original'),
    [setDownloadFileType]
  )

  const {
    state: { refetch: refetchFileAccessToken }
  } = useSmartQuery(SQ_GET_FILE_ACCESS_TOKEN, {
    fetchPolicy: 'network-only',
    skip: true, // this query is only used via refetch - todo convert to lazy query/mutation
    variables: { batchId: batch.id, type: downloadFileType }
  })

  useEffect(() => {
    const getFileAccessToken = async () => {
      setFetchingAccessToken(true)
      const { data } = await refetchFileAccessToken({
        batchId: batch.id,
        type: downloadFileType
      })

      const fileType = data?.getFileAccessToken?.type
      const downloadToken = data?.getFileAccessToken?.token

      if (fileType && downloadToken && fileType === downloadFileType) {
        const url = `${FLATFILE_REFINERY_URL}/batch/${batch.id}/${
          fileType === 'processed' ? `processed.${downloadFileFormat}` : 'original-file'
        }?token=${downloadToken}`
        setDownloadFileType(null)
        setDownloadFileFormat(null)
        window.open(url, '_blank')
      }
      setFetchingAccessToken(false)
    }

    if (!!downloadFileType) {
      getFileAccessToken()
    }
  }, [downloadFileType])

  const downloadFile = (type: string, format: 'xlsx' | 'csv' = 'csv') => {
    setDownloadFileType(type)
    setDownloadFileFormat(format)
  }

  const historyTooltip = 'View history'

  const flagImportTimeline = useTeamSplitFlag(VendorSplitFlagNames.ImportTimeline)
  const exportSheetButton = useTeamSplitFlag(VendorSplitFlagNames.ExportSheetButton)

  const showHistory = flagImportTimeline && batch?.id

  const showFinal =
    activeViewType === EImportDetailView.final &&
    batch.submittedAt &&
    (batch.managed || !!batch.embedId)

  const showOriginal =
    activeViewType === EImportDetailView.original &&
    !batch.manual &&
    (batch.managed || !!batch.embedId)

  const sheetId = batch.workspace?.primaryWorkbook?.sheets[0]?.id

  return (
    <SpreadSheetToolBarStyle>
      <TopRow data-testid='toolbar-top-row'>
        <FileNameContainer>
          <Header>
            <h1>{getBatchFilename(batch)}</h1>
          </Header>
        </FileNameContainer>
        {fetchingAccessToken ? (
          <Spinner />
        ) : (
          <>
            <Spacer grow />
            {showHistory && (
              <FlatButton
                data-for={historyTooltip}
                data-tip={historyTooltip}
                color='white'
                onClick={onViewHistory}
              >
                <Icon name='history' />
                <Tooltip id={historyTooltip} />
              </FlatButton>
            )}
            {showFinal ? (
              sheetId && exportSheetButton ? (
                <ExportSheetButton sheetId={sheetId} validationState='review' />
              ) : (
                <FileDownloadDropdown
                  offset={[-12, 10]}
                  onDownloadFile={downloadFile}
                  showOriginalFileOption={true}
                >
                  <ButtonBase>Download file</ButtonBase>
                </FileDownloadDropdown>
              )
            ) : null}
            {showOriginal ? (
              <FlatButton onClick={downloadOriginal}>Download original</FlatButton>
            ) : null}

            {(showHistory || showFinal || showOriginal) && (
              <VerticalLine
                height='36px'
                margin={{ left: Spacing.basePadding2x, right: Spacing.basePadding2x }}
              />
            )}
            <TrashCircleButton name='Delete import' showTooltip={true} onClick={onRemoveBatch} />
          </>
        )}
      </TopRow>
      <ImportMetadata endUser={batch.endUser}>
        <BatchTimes batch={batch} />
        {batch.schema && <SchemaCell schema={batch.schema} />}
      </ImportMetadata>
      {batch.managed && (
        <BottomRow data-testid='toolbar-bottom-row'>
          <Tabstrip bottomBorder={false}>
            {tabs.map((viewType: EImportDetailView, index: number) => {
              const isManualOriginal = batch.manual && viewType === EImportDetailView.original
              const tooltip = isManualOriginal
                ? 'This batch was not created from a file, so there is no original data'
                : null
              return (
                <Tab
                  key={index}
                  active={activeViewType === viewType}
                  disabled={isManualOriginal}
                  tooltip={tooltip}
                  tooltipType='info'
                  uniqueId={`spread-sheet-toolbar-tabs-${index}`}
                  onClick={() => {
                    setActiveViewType(viewType)
                  }}
                >
                  {viewType}
                </Tab>
              )
            })}
          </Tabstrip>
          <FileStatCounts batch={batch} />
        </BottomRow>
      )}
    </SpreadSheetToolBarStyle>
  )
}

const FileStatCounts = ({ batch }: { batch: Partial<IBatch> }) => {
  const { submittedAt, countRows, countColumns, countRowsInvalid } = batch
  return (
    <>
      {submittedAt && (
        <FileStats data-testid='toolbar-stats'>
          <FileName>
            <h1>{countRows || '0'}</h1>
            <p>Rows</p>
          </FileName>

          <FileName>
            <h1>{countColumns || '0'}</h1>
            <p>Columns</p>
          </FileName>

          <FileName>
            <h1>{countRowsInvalid || '0'}</h1>
            <p>Invalid rows</p>
          </FileName>
        </FileStats>
      )}
    </>
  )
}
