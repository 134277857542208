import { useLazyQuery } from '@apollo/client'
import { ReactNode, useEffect } from 'react'
import ReactTooltip from 'react-tooltip'
import { ExportViewButton } from 'src/applications/Oversight/components/ExportViewButton'
import { OrganizationAvatars } from 'src/applications/Oversight/components/OrganizationAvatars'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import { GlobalSearch } from 'src/applications/Oversight/controls/GlobalSearch'
import { useBatchTimelineModal } from 'src/applications/Oversight/hooks/useBatchTimelineModal'
import { useCreateDataRequestModal } from 'src/applications/Oversight/hooks/useCreateDataRequestModal'
import { useTeamSplitFlag } from 'src/applications/Oversight/hooks/useTeamSplitFlag'
import { useUploadFile } from 'src/applications/Oversight/hooks/useUploadFile'
import { FEATURE_FLAGS } from 'src/config'
import { getBasicInfoContext } from 'src/contexts/BasicInfoContext'
import { GET_BATCH } from 'src/queries/GET_BATCH'
import { GetBatch, GetBatchVariables } from 'src/queries/types/GetBatch'
import { Transitions } from 'src/resources/animations/transitions'
import { Colors } from 'src/resources/colors'
import { FlatButton } from 'src/resources/elements/buttons/FlatButton'
import { FlatUploadButton } from 'src/resources/elements/buttons/FlatUploadButton'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { Icon } from 'src/resources/elements/Icon'
import { AddUserIcon } from 'src/resources/elements/Icons'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { useParams } from 'src/resources/hooks/useParams'
import { useTableFilters } from 'src/resources/hooks/useTableFilters'
import { Spacing } from 'src/resources/layout'
import { fontSecondary } from 'src/resources/typography'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SQ_WORKSPACE } from 'src/smart/queries/SQ_WORKSPACE'
import { EViewType } from 'src/types/enums/EViewType'
import { VendorSplitFlagNames } from 'src/vendor/vendorSplit'
import styled from 'styled-components'
import { ExportSheetButton } from './ExportSheetButton'

const Wrap = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const ButtonWrap = styled.div`
  align-items: center;
  display: flex;
  & > * {
    margin-right: ${Spacing.baseAndHalfPadding};
    &:last-child {
      margin-right: 0;
    }
  }
`

const InviteContainer = styled.div`
  align-items: center;
  border-right: 1px solid ${Colors.border};
  display: flex;
  height: 36px;
  margin-right: ${Spacing.basePadding3x};
  padding-right: ${Spacing.basePadding2x};
`

const RoundButton = styled.div<{ border?: boolean; marginRight?: boolean }>`
  align-items: center;
  background-color: ${Colors.white};
  border-radius: 50%;
  border: 1px solid ${({ border }) => (border === false ? Colors.transparent : Colors.border)};
  box-shadow: 0 0 0 3px ${Colors.gray20};
  color: ${Colors.textAccent};
  cursor: pointer;
  display: flex;
  font-size: 2.75rem;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  line-height: 1;
  margin-right: ${({ marginRight }) => (marginRight ? Spacing.basePadding : 0)};
  transition: ${Transitions.baseEase};
  width: 40px;

  &:hover {
    color: ${Colors.brandPrimary};
  }
`

const ManageUsersButton = styled(RoundButton)`
  margin-left: -${Spacing.basePadding};
`

const StyledTooltip = styled(ReactTooltip)`
  font-family: ${fontSecondary};
`

interface IWorkspaceActionBar {
  children?: ReactNode
  inline?: boolean
  noMargin?: boolean
  noPadding?: boolean
  sheetId?: string
  title?: string | JSX.Element
  viewId?: string
  viewType?: EViewType
  workspaceActive?: boolean
  workspaceId: string
}

export const WorkspaceActionBar = ({
  inline,
  noMargin,
  noPadding,
  sheetId,
  title,
  workspaceActive,
  workspaceId
}: IWorkspaceActionBar) => {
  const { batchId } = useParams<{ batchId: string; teamId: string }>()
  // this component is rendered on the uploads view as well so it might not always have a batchId
  const [batchQuery, { data }] = useLazyQuery<GetBatch, GetBatchVariables>(GET_BATCH)
  useEffect(() => {
    if (batchId) {
      batchQuery({
        variables: { batchId }
      })
    }
  }, [])

  const {
    data: { me }
  } = getBasicInfoContext().value

  const { onUpload, onMultipleUpload, FailedUploadModal } = useUploadFile(workspaceId)
  const workspaceQuery = useSmartQuery(SQ_WORKSPACE, {
    variables: {
      workspaceId
    }
  })

  const batchTimelineModal = useBatchTimelineModal({ batchId })

  const createDataRequestModal = useCreateDataRequestModal(workspaceQuery.result)

  const hideCollaboratorsFlag = useTeamSplitFlag(VendorSplitFlagNames.HideCollaborators)

  const flagImportTimeline = useTeamSplitFlag(VendorSplitFlagNames.ImportTimeline)

  const historyTooltip = 'View history'

  const [{ validationState }] = useTableFilters()

  return workspaceQuery.alert ? (
    queryAlert(workspaceQuery)
  ) : (
    <Wrap>
      {createDataRequestModal.render()}
      {batchTimelineModal.render()}
      <PageHeaderContainer
        header={<h1>{title}</h1>}
        hasMargin={!noMargin}
        inline={inline}
        noPadding={noPadding}
      >
        <ButtonWrap data-testid='actionbar-buttonwrap'>
          {FailedUploadModal.render()}
          {flagImportTimeline && batchId && (
            <FlatButton
              data-for={historyTooltip}
              data-tip={historyTooltip}
              color='white'
              onClick={batchTimelineModal.open}
            >
              <Icon name='history' />
              <Tooltip id={historyTooltip} />
            </FlatButton>
          )}
          {data?.getBatch && workspaceActive && <ExportViewButton batch={data.getBatch} />}
          {sheetId && workspaceActive && (
            <ExportSheetButton sheetId={sheetId} validationState={validationState} />
          )}
          <InviteContainer>
            {me && <OrganizationAvatars workspaceId={workspaceId} currentUser={me} />}
            {!hideCollaboratorsFlag && (
              <>
                <ManageUsersButton
                  data-testid='invite-member'
                  data-for='invite'
                  data-tip='View and invite team members'
                  onClick={createDataRequestModal.open}
                >
                  <AddUserIcon />
                </ManageUsersButton>
                <StyledTooltip id='invite' place='top' type='dark' effect='float' />
              </>
            )}
          </InviteContainer>
          {workspaceActive ? (
            <FlatUploadButton
              onFileSelected={onUpload}
              multiple={true}
              onChange={onMultipleUpload}
            >
              Upload
            </FlatUploadButton>
          ) : (
            <FlatButton disabled={true}>Upload</FlatButton>
          )}
        </ButtonWrap>
      </PageHeaderContainer>
      {/* not sure if this is still needed*/}
      {FEATURE_FLAGS.SEARCH_WORKSPACE && <GlobalSearch />}
    </Wrap>
  )
}
