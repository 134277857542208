import { useCallback, useMemo, useRef, useState } from 'react'
import FlipMove from 'react-flip-move'
import * as Layout from 'src/applications/Embed/elements/LayoutElements'
import { Footer, FooterButton } from 'src/applications/Embed/fragments/Footer'
import { EmbedLayout } from 'src/applications/Embed/fragments/Layout'
import { OverflowData } from 'src/applications/Oversight/components/OverflowData'
import { Tooltip } from 'src/applications/Oversight/components/Tooltip'
import { useConfirmModal } from 'src/applications/Oversight/hooks/useConfirmModal'
import { useSelectMergeFieldModal } from 'src/applications/Oversight/hooks/useSelectMergeFieldModal'
import { MatchingSkeleton } from 'src/applications/Oversight/scenes/MatchingV3/animations/MatchingSkeleton'
import { DataPreview } from 'src/applications/Oversight/scenes/MatchingV3/components/DataPreview'
import { ExcludedBlock } from 'src/applications/Oversight/scenes/MatchingV3/components/ExcludedBlock'
import { Field } from 'src/applications/Oversight/scenes/MatchingV3/components/Field'
import { Header } from 'src/applications/Oversight/scenes/MatchingV3/components/Header'
import { WorkbookLoader } from 'src/applications/Oversight/scenes/MatchingV3/components/WorkbookLoader'
import { useDataSourceFields } from 'src/applications/Oversight/scenes/MatchingV3/hooks/useDataSourceFields'
import { useEnumMatchPanel } from 'src/applications/Oversight/scenes/MatchingV3/hooks/useEnumMatchPanel'
import { useErrorModal } from 'src/applications/Oversight/scenes/MatchingV3/hooks/useErrorModal'
import { Colors } from 'src/resources/colors'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { ClearIndicatorIcon } from 'src/resources/elements/Icons'
import MatchingArrow from 'src/resources/icons/MatchingArrow'
import MatchingExcludedFieldDown from 'src/resources/icons/MatchingExcludedFieldDown'
import { Spacing } from 'src/resources/layout'
import { fontFamily } from 'src/resources/typography'
import { EWorkbookStatus } from 'src/types/enums/EWorkbookStatus'
import { IMatchData } from 'src/types/interfaces/ISchema'
import styled, { useTheme } from 'styled-components'
import { IUpdateDataSourceField } from './classes/DataSourceField'

import { ErrorPill, ErrorWrapper, MatchIndicator, Table, TableRow, TableWrapper } from './styles'
import { useMappingWarningModal } from 'src/applications/Oversight/scenes/MatchingV3/hooks/useMappingWarningModal'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'
import { useTranslation } from 'react-i18next'

const MatchingForm: TForm<IMatchData> = Form

const FieldName = styled.div`
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
`

const EmptyState = styled.div`
  width: 100%;
  padding: 60px 0;
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: #4e5a65;
`

const ActionLink = styled.a`
  color: ${themed('primary')};
  font-size: 12px;
  cursor: pointer;
  display: block;
  line-height: 14px;
`

const ExcludeButton = styled.button`
  width: 18px;
  height: 18px;
  background: white;
  border: 1px solid #587a9d;
  border-radius: 3px;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
`

const FixedCell = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: ${fontFamily.fontSecondary};

  & > * {
    width: 100%;
    min-width: 200px;
    max-width: 300px;
  }
`

const UnmatchedFieldsCount = styled.span`
  display: inline-block;
  margin-left: 8px;
  background-color: ${Colors.alertBackground};
  border-radius: ${Spacing.halfBasePadding};
  width: ${Spacing.basePadding3x};
  height: ${Spacing.basePadding3x};
  color: ${Colors.alertTextAlternate};
  text-align: center;
`

const UnmatchedFieldsList = styled.div`
  position: absolute;
  transform: translateX(-${Spacing.basePadding2x});
  background: #ffffff;
  border: 1px solid #eaf0f6;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 15%);
  border-radius: ${Spacing.halfBasePadding};
  margin-top: 7px;
  padding: ${Spacing.basePadding2x};
  display: none;
  p {
    margin-bottom: 0;
  }
  ul {
    margin-top: 0;
  }
`

const FieldHeader = styled.th`
  :hover {
    ${UnmatchedFieldsList} {
      display: block;
    }
  }
`

interface IMatchingScene {
  batchId: string
  sheetId?: string
  onWorkbookComplete?: (workbookId: string) => void
  workbookId?: string
  embeds: boolean
  workbookStatus?: string
  workspaceId: string
}

export function MatchingScene({
  batchId,
  sheetId,
  onWorkbookComplete,
  embeds = false,
  workbookId,
  workbookStatus,
  workspaceId
}: IMatchingScene) {
  const tableRef = useRef<HTMLTableElement>()
  const confirmUpdateAction = useRef<() => void | Promise<void>>()
  const [submitted, setSubmitted] = useState(false)
  const [selected, setSelected] = useState<number>(null)
  const [mergeField, setMergeField] = useState<string>(null)
  const [changesApproved, setChangesApproved] = useState<boolean>(false)
  const theme: any = useTheme()

  const {
    allowCustomFields,
    preventUserDefinedCustomFields,
    confirmDataSourceFields,
    dataSourceFields,
    linkedTemplates,
    linkValidation,
    loading,
    matchesUpdated,
    requiredProperties,
    schemaProperties,
    templateId,
    templateName,
    updateDataSourceField
  } = useDataSourceFields(batchId, sheetId)

  const { t } = useTranslation()

  const confirmModal = useConfirmModal({
    header: t('scenes.MatchingV3.header'),
    confirmLabel: t('scenes.MatchingV3.confirmLabel'),
    cancelLabel: t('scenes.MatchingV3.cancelLabel'),
    confirmColor: 'primary',
    cancelColor: 'secondary',
    grayIcon: true,
    contents: (
      <>
        {t('scenes.MatchingV3.contentsBeforeBreak')}
        <br />
        {t('scenes.MatchingV3.contentsAfterBreak')}
      </>
    ),
    onConfirm: () => {
      setChangesApproved(true)
      confirmUpdateAction.current?.()
      confirmModal.close()
    },
    theme
  })

  const [EnumMatchPanel, openEnumMatchPanel] = useEnumMatchPanel()
  const [ErrorModal, openErrorModal] = useErrorModal()

  const { active, excluded } = useMemo(() => {
    if (!dataSourceFields?.length) {
      return {}
    }

    return {
      excluded: dataSourceFields.filter((field) => field.ignored),
      active: dataSourceFields.filter((field) => field.ignored !== true)
    }
  }, [dataSourceFields])

  const initialValue = useMemo(() => {
    return dataSourceFields?.reduce(
      (acc, field) => ({
        ...acc,
        ...(field.matchKey ? { [field.id]: field.matchKey } : {})
      }),
      {} as IMatchData
    )
  }, [dataSourceFields])

  const activeMatchKeys = active
    ?.filter((f) => f.matchKey || f.originalMatchKey)
    .map((f) => (f.matchKey ? f.matchKey : f.originalMatchKey))

  const uniqueFieldOptions = schemaProperties
    ?.filter(
      (schemaProperty) =>
        schemaProperty.unique &&
        activeMatchKeys?.includes(schemaProperty.field) &&
        schemaProperty?.type !== 'schema_ref'
    )
    .map((uniqueField) => ({ label: uniqueField.label, value: uniqueField.field }))

  const workbookCancelled = useMemo(
    () => workbookStatus === EWorkbookStatus.CANCELLED,
    [workbookStatus]
  )

  const handleConfirmDataSourceFields = useCallback(
    async (mergeField?: string) => {
      selectMergeFieldModal.close()
      setMergeField(mergeField)
      if (!workbookId || matchesUpdated || workbookCancelled) {
        const { success, message } = await confirmDataSourceFields()

        if (!success) {
          openErrorModal(message)
          return
        }
      }

      setSubmitted(true)
    },
    [confirmDataSourceFields, openErrorModal, setSubmitted, workbookStatus]
  )

  const selectMergeFieldModal = useSelectMergeFieldModal(
    handleConfirmDataSourceFields,
    uniqueFieldOptions,
    embeds
  )

  const [WarningModal, openWarningModal] = useMappingWarningModal(selectMergeFieldModal.open)

  const handleOnSelect = useCallback<(f: number | null) => void>(
    (fieldId) => {
      if (selected !== fieldId) {
        setSelected(fieldId)
        selectMergeFieldModal.close()
      }
    },
    [selected, setSelected]
  )

  if (!dataSourceFields?.length || !schemaProperties) {
    return <MatchingSkeleton />
  }

  const unmatchedRequiredPropertyKeys = requiredProperties?.filter(
    (matchKey) => !active.find((f) => f.matchKey === matchKey || f.originalMatchKey === matchKey)
  )
  const requiredPropertyFieldsUnmatched = schemaProperties
    ?.filter((schemaProperty) => unmatchedRequiredPropertyKeys.includes(schemaProperty.field))
    .map((unmatchedSchemaProperty) => unmatchedSchemaProperty.label)

  const handleMultiValueUpdate = async (values: Partial<IMatchData>, updateForm?: Function) => {
    const updateAction = async () => {
      await Promise.all(
        Object.entries(values).map(([fieldId, matchKey]) =>
          handleSingleFieldUpdate(fieldId, { matchKey }, updateForm)
        )
      )
    }
    if (!changesApproved && workbookId) {
      confirmUpdateAction.current = () => updateAction()
      confirmModal.open()
      return
    }
    updateAction()
  }

  const handleSingleFieldUpdate = (
    f: string | number,
    p: IUpdateDataSourceField,
    updateForm?: Function
  ) => {
    updateDataSourceField(f, p)
    updateForm?.()
  }

  const onClickContinue = async () => {
    if (requiredPropertyFieldsUnmatched.length > 0 && !embeds) {
      openWarningModal(requiredPropertyFieldsUnmatched)
    } else {
      await selectMergeFieldModal.open()
    }
  }

  return (
    <MatchingForm {...(Object.keys(initialValue).length && { initialValue })}>
      <EnumMatchPanel />
      <ErrorModal />
      <WarningModal />
      {confirmModal.render()}
      {submitted && (
        <>
          <WorkbookLoader
            existingWorkbookId={workbookId}
            batchId={batchId}
            mergeField={mergeField}
            onComplete={onWorkbookComplete}
            matchesUpdated={matchesUpdated}
            workbookCancelled={workbookCancelled}
            workspaceId={workspaceId}
          />
        </>
      )}
      {selectMergeFieldModal.render()}
      <EmbedLayout>
        <Layout.Content>
          <Layout.FlexVertical>
            <Header loading={loading} />
            {linkValidation?.length
              ? linkValidation.map((link, i) => (
                  <ErrorWrapper key={i}>
                    <ClearIndicatorIcon fill={Colors.dangerStrong} />
                    <div>
                      {link.message}
                      <br />
                      {link.uniqueProps?.map((prop: string) => (
                        <ErrorPill key={prop}>{prop}</ErrorPill>
                      ))}
                    </div>
                  </ErrorWrapper>
                ))
              : null}
            <TableWrapper>
              <Table ref={tableRef} onMouseLeave={() => handleOnSelect(null)}>
                <thead>
                  <TableRow>
                    <th />
                    <th>{t('scenes.MatchingV3.columnHeaders')}</th>
                    <th>
                      <MatchingArrow fill={Colors.horizontalRuleGray} />
                    </th>
                    <FieldHeader>
                      {t(theme?.textOverrides.templateFieldsLabel)}{' '}
                      {requiredPropertyFieldsUnmatched.length > 0 && (
                        <>
                          <UnmatchedFieldsCount>
                            {requiredPropertyFieldsUnmatched.length}
                          </UnmatchedFieldsCount>
                          <UnmatchedFieldsList>
                            <p>{t('scenes.MatchingV3.unmatchedRequiredFields')}</p>
                            <ul>
                              {requiredPropertyFieldsUnmatched.map((x, index) => {
                                return <li key={index}>{x}</li>
                              })}
                            </ul>
                          </UnmatchedFieldsList>
                        </>
                      )}
                    </FieldHeader>
                    <th />
                  </TableRow>
                </thead>
                <FlipMove typeName='tbody'>
                  {active.map((field) => {
                    return (
                      <TableRow
                        key={field.id}
                        onMouseEnter={() => handleOnSelect(field.id)}
                        onMouseOver={() => handleOnSelect(field.id)}
                      >
                        <td>
                          {theme.showMatchIndicator && <MatchIndicator active={field.isMatched} />}
                        </td>
                        <td>
                          <OverflowData dataFor={field.label} dataTip={field.label}>
                            <FieldName>{field.label}</FieldName>
                          </OverflowData>
                          {field.isEnum && (
                            <ActionLink
                              onClick={async (e) => {
                                e.preventDefault()

                                if (!field.matchKey) {
                                  return
                                }

                                const updatedValues = await openEnumMatchPanel({
                                  field,
                                  jsonSchemaProp: schemaProperties.find(
                                    (s) => s.field === field.matchKey
                                  )
                                })

                                if (updatedValues) {
                                  await updateDataSourceField(field.id, {
                                    matchKey: field.matchKey,
                                    matchMeta: updatedValues
                                  })
                                }
                              }}
                            >
                              {
                                Object.values(field.matchMeta ?? {}).filter((v) => v === null)
                                  .length
                              }{' '}
                              unmatched
                            </ActionLink>
                          )}
                        </td>
                        <td>
                          <MatchingArrow />
                        </td>
                        <td>
                          <FixedCell>
                            <Field
                              dataSourceField={field}
                              templateName={templateName}
                              templateId={templateId}
                              linkedTemplates={linkedTemplates}
                              schemaProperties={schemaProperties}
                              findDataSourceField={(fieldId) =>
                                dataSourceFields.find((d) => d.id === parseInt(fieldId, 10))
                              }
                              onUpdate={handleMultiValueUpdate}
                              onSingleFieldUpdate={handleSingleFieldUpdate}
                              allowCustomFields={
                                allowCustomFields && !preventUserDefinedCustomFields
                              }
                              activeMatchKeys={activeMatchKeys}
                              changesApproved={workbookId && changesApproved}
                            />
                          </FixedCell>
                        </td>
                        <td>
                          <ExcludeButton
                            style={{ display: selected === field.id ? 'flex' : 'none' }}
                            data-for='exclude-btn'
                            data-tip='exclude-btn'
                            onClick={async (e) => {
                              e.preventDefault()
                              handleSingleFieldUpdate(field.id, { ignored: true })
                            }}
                          >
                            <MatchingExcludedFieldDown />
                          </ExcludeButton>
                        </td>
                      </TableRow>
                    )
                  })}
                  {!active.length && (
                    <tr>
                      <td colSpan={5}>
                        <EmptyState>{t('scenes.MatchingV3.emptyState')}</EmptyState>
                      </td>
                    </tr>
                  )}
                  <tr>
                    <td colSpan={5}>
                      <ExcludedBlock
                        dataSourceFields={excluded}
                        onUpdate={handleSingleFieldUpdate}
                        onSelect={handleOnSelect}
                      />
                    </td>
                  </tr>
                </FlipMove>
              </Table>
            </TableWrapper>
          </Layout.FlexVertical>
        </Layout.Content>
        <Layout.Sidebar>
          <DataPreview field={dataSourceFields.find((f) => f.id === selected)} />
        </Layout.Sidebar>
        <Tooltip
          id='exclude-btn'
          content={t('scenes.MatchingV3.excludeColumnMatch')}
          offset={{
            top: 0,
            left: 0
          }}
        />
        <Footer boxShadow>
          <FooterButton
            disabled={loading || !!linkValidation?.length}
            onClick={onClickContinue}
            type='button'
            embeds={embeds}
          >
            {t('elements.buttons.continue')}
          </FooterButton>
        </Footer>
      </EmbedLayout>
    </MatchingForm>
  )
}
