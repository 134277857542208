import { useContext } from 'react'

import { useQuery } from '@apollo/client'
import moment from 'moment'
import { ScheduleCalendlyMeeting } from 'src/applications/Oversight/components/ScheduleCalendlyMeeting'
import { useTeamSplitFlag } from 'src/applications/Oversight/hooks/useTeamSplitFlag'
import { TeamContext } from 'src/contexts/TeamContext'
import { GET_ACCOUNT_STATUS } from 'src/queries/GET_ACCOUNT_STATUS'
import { GetAccountStatus, GetAccountStatusVariables } from 'src/queries/types/GetAccountStatus'
import { Colors } from 'src/resources/colors'
import { DoubleCheck, LogoIcon } from 'src/resources/elements/Icons'
import Hans from 'src/resources/images/Hans.png'
import { Spacing } from 'src/resources/layout'
import { fontFamily, fontSizes } from 'src/resources/typography'
import { EPlanName } from 'src/types/enums/EPlanName'
import { VendorSplitFlagNames } from 'src/vendor/vendorSplit'
import styled, { keyframes } from 'styled-components'
import { Transitions } from 'src/resources/animations/transitions'

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(5px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const TrialNoticeContainer = styled.div`
  animation: ${fadeIn} 0.25s 0.15s ease both;
  display: flex;
  justify-content: space-between;
  height: ${Spacing.basePadding10x};
  background: ${Colors.purpleAlert};
  border-radius: ${Spacing.halfBasePadding};
  border: 0.5px solid ${Colors.brandPrimaryRGBABorder};
  padding: ${Spacing.basePadding} ${Spacing.basePadding3_5x};
  margin-bottom: ${Spacing.basePadding2x};
  transition: ${Transitions.baseEase};
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  > div > h5 {
    margin-top: ${Spacing.basePadding};
    margin-bottom: ${Spacing.basePadding};
    font-weight: 600;
    font-size: ${fontSizes.type13};
    font-family: ${fontFamily.fontPrimary};
  }

  > div > p {
    text-transform: capitalize;
    font-size: ${fontSizes.type12};
  }

  &:hover {
    box-shadow: 0 2px 10px -5px rgba(0, 0, 0, 0.05), 0 2px 16px 0 rgba(0, 0, 0, 0.05),
      0 24px 16px -16px rgba(0, 0, 0, 0.08);
  }
`

const TimeLeft = styled.div<{ color?: string }>`
  display: flex;
  font-family: ${fontFamily.fontPrimary};
  font-size: ${fontSizes.type18};
  align-items: center;
  line-height: calc(${Spacing.basePadding2_5x} + 2px);
  font-weight: 700;
  color: ${(props) => (props.color ? props.color : Colors.gray80)};

  > svg {
    height: 18px;
    width: 18px;
    margin-right: ${Spacing.basePadding};
  }
`

const ContactLink = styled.div`
  display: flex;
  font-family: ${fontFamily.fontSecondary};
  font-size: ${fontSizes.type12};
  align-items: center;
  color: ${Colors.text};

  > svg {
    height: 18px;
    width: 18px;
    margin-right: ${Spacing.basePadding};
  }

  a,
  div > span {
    color: ${Colors.brandPrimary};
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type16};
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: ${Transitions.baseEase};

    svg {
      height: 14px;
      transition: ${Transitions.baseEase};
      path {
        transition: ${Transitions.baseEase};
      }
    }
  }

  &:hover {
    > svg {
      path,
      rect {
        fill: ${Colors.brandPrimary};
      }
    }
    a {
      color: ${Colors.brandPrimary};

      svg {
        transform: translateX(2px);
        path {
          fill: ${Colors.brandPrimary};
        }
      }
    }
  }
`

const ButtonContactLink = styled(ContactLink)`
  border-radius: calc(${Spacing.basePadding} - 2px);
  background-color: ${Colors.brandPrimaryRGBABorder};
  height: calc(${Spacing.basePadding5x} - 2px);
  padding: ${Spacing.basePadding} calc(${Spacing.basePadding} + 4px);
  margin-left: ${Spacing.basePadding2x};

  :hover {
    background-color: ${Colors.brandPrimary};
    cursor: pointer;
    transition: background-color 0.2s ease-out;
    div > span {
      color: ${Colors.actionHover};
    }
  }

  a,
  div > span {
    font-weight: 700;
    text-decoration: none;
  }
`

const PlanLabel = styled.div`
  background-color: ${Colors.gray20};
  border-radius: 4px;
  color: ${Colors.blue};
  padding: ${Spacing.basePadding} ${Spacing.basePadding2x};
  margin: ${Spacing.basePadding} 0;
  text-align: center;
  font-size: ${fontSizes.type13};
  font-weight: 500;

  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    margin-left: ${Spacing.basePadding};
    height: 18px;
    width: 18px;
  }
`

const Plan = ({ plan }: { plan: string }): JSX.Element => {
  if (!(plan in EPlanName)) {
    return null
  }

  return (
    <PlanLabel>
      {EPlanName[plan as keyof typeof EPlanName]}
      <DoubleCheck fill={Colors.blue} />
    </PlanLabel>
  )
}

const MeetingDetails = styled.div`
  h2,
  p {
    color: ${Colors.textLogo};
  }
  h2 {
    font-family: ${fontFamily.fontSerif};
    font-size: ${fontSizes.type48};
    font-weight: bold;
    margin-bottom: calc(${Spacing.basePadding5x} + 2px);
    margin-top: calc(${Spacing.basePadding10x} + 2px);
  }
  p {
    font-family: ${fontFamily.fontSecondary};
    font-size: ${fontSizes.type21};
    line-height: 150%;
    margin-bottom: ${Spacing.basePadding6x};
  }
`

const ImageAndTitleContainer = styled.span`
  display: flex;
  img {
    max-width: 150px;
    height: auto;
    margin-right: ${Spacing.basePadding5x};
  }
  svg {
    margin-right: ${Spacing.basePadding3x};
    align-self: flex-end;
  }
  h3,
  p {
    font-family: ${fontFamily.fontSecondary};
    letter-spacing: 0.02em;
  }
  h3 {
    font-size: ${fontSizes.type21};
    font-weight: 600;
    margin-bottom: 0;
  }
  p {
    font-size: ${fontSizes.type16};
    margin-top: 0;
  }
`

const TechnicalMeeting = () => {
  return (
    <MeetingDetails>
      <h2>Let's code together.</h2>
      <p>
        Would you like to collaborate in real time? We can spend the time answering your technical
        questions, and getting Portal 3.0 configured and working so you can test out the
        functionality before deploying to production. I'd love to connect.
      </p>
      <ImageAndTitleContainer>
        <img src={Hans} alt='Hans Hoffman' />
        <span>
          <h3>Hans Hoffman</h3>
          <p>Engineer</p>
        </span>
      </ImageAndTitleContainer>
    </MeetingDetails>
  )
}

const SDRMeeting = () => {
  return (
    <MeetingDetails>
      <h2>Get into production.</h2>
      <p>
        Had a chance to test out the product? Ready to get into production? Let's have a quick chat
        so we can turn on the full set of features to get your users going with a smooth data
        onboarding process.
      </p>
      <ImageAndTitleContainer>
        <LogoIcon height='45px' />
        <span>
          <h3>The Flatfile Team</h3>
        </span>
      </ImageAndTitleContainer>
    </MeetingDetails>
  )
}

export const TrialExpired = () => {
  return (
    <MeetingDetails>
      <h2>Your trial has expired.</h2>
      <p>
        Had a chance to test out the product? Ready to get into production? Let's have a quick chat
        so we can turn on the full set of features to get your users going with a smooth data
        onboarding process.
      </p>
      <ImageAndTitleContainer>
        <LogoIcon height='45px' />
        <span>
          <h3>The Flatfile Team</h3>
        </span>
      </ImageAndTitleContainer>
    </MeetingDetails>
  )
}

export const TrialNoticeCard = (): JSX.Element => {
  const team = useContext(TeamContext)
  const concierge =
    useTeamSplitFlag(VendorSplitFlagNames.Workspaces) ||
    team.featureFlags?.PRODUCT_ACCESS_WORKSPACES

  const { data } = useQuery<GetAccountStatus, GetAccountStatusVariables>(GET_ACCOUNT_STATUS, {
    fetchPolicy: 'no-cache',
    variables: { teamId: team.id }
  })

  const githubUser = data?.getAccountStatus.plan?.startsWith('portal-developer-trial')

  if (
    !data?.getAccountStatus ||
    (data?.getAccountStatus?.status.toLowerCase() === 'active' && !githubUser) ||
    concierge ||
    data?.getAccountStatus?.plan === null
  ) {
    return null
  }

  const daysRemaining = data.getAccountStatus.trialEndsAt
    ? moment(data.getAccountStatus.trialEndsAt).diff(moment().subtract(1, 'day'), 'days')
    : 0

  const inTrial = data.getAccountStatus?.status === 'InTrial' || githubUser

  const EvaluationMessage =
    daysRemaining < 1
      ? 'Evaluation period ended'
      : `Your trial expires in ${daysRemaining}  day${daysRemaining > 1 ? 's' : ''}`

  return (
    <TrialNoticeContainer>
      {data?.getAccountStatus?.plan && inTrial && <Plan plan={data.getAccountStatus.plan} />}
      <TimeLeft color={Colors.brandPrimary}>{EvaluationMessage}</TimeLeft>
      <div>
        <ContactLink>
          {githubUser ? (
            <ScheduleCalendlyMeeting
              calendlyUrl={'https://calendly.com/hans-flatfile/30min?hide_event_type_details=1'}
              children={<TechnicalMeeting />}
              linkText='Chat with an engineer'
            />
          ) : (
            <a href='https://flatfile.io/request-a-demo'>Schedule a call with us</a>
          )}
        </ContactLink>
        {(data.getAccountStatus.plan as string) !== 'portal-enterprise' && (
          <ButtonContactLink>
            <ScheduleCalendlyMeeting
              calendlyUrl={'https://calendly.com/flatfile-sales-dev/data-onboarding-consultation'}
              children={<SDRMeeting />}
              linkText='Get production ready'
            />
          </ButtonContactLink>
        )}
      </div>
    </TrialNoticeContainer>
  )
}
