import { FC } from 'react'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'
import { Layer } from 'src/resources/elements/Layer'
import styled from 'styled-components'

export const Wrapper = styled.div`
  background: ${themed('modalBackgroundColor')};
  height: 100vh;
`

export const MainDisplay = styled(Layer)`
  background: white;
  overflow-y: auto;
`

export const FullContent = styled.div`
  flex: 1 0 100%;
  padding: ${themed('gutterSpacing')};
`

export const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - (${themed('footerHeight')} + ${themed('gutterSpacing')}));
`

const ContentWrapper = styled.div`
  max-width: ${themed('contentMaxWidth')};
  padding: ${themed('gutterSpacing')};
  margin: 0 0 0 auto;
`

const ContentColumn = styled.div`
  flex: 1 0 60%;
`

export const Container = styled.div<{ hasSidebar: boolean; hasFooter: boolean }>`
  height: calc(100vh - ${({ theme, hasFooter }) => (hasFooter ? theme.footerHeight : '0px')});
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  ${({ hasSidebar }) => !hasSidebar && `overflow-y: auto;`}
  ${ContentColumn} {
    ${({ hasSidebar, theme }) =>
      !hasSidebar ? `flex: 0 0 ${theme.contentMaxWidth};` : 'overflow-y: auto;'}
  }
  ${FlexVertical} {
    ${({ hasFooter, theme }) =>
      hasFooter && `height: calc(100vh - ${theme.footerHeight} - ${theme.gutterSpacing});`}
  }
`

export const Content: FC = ({ children }) => (
  <ContentColumn>
    <ContentWrapper>{children}</ContentWrapper>
  </ContentColumn>
)

const SidebarWrapper = styled.div`
  max-width: ${themed('sidebarMaxWidth')};
  padding: ${themed('gutterSpacing')};
  margin: 0 auto 0 0;
`

const SidebarColumn = styled.div`
  flex: 0 0 40%;
  max-width: ${({ theme }) =>
    theme.contentMaxWidth === 'initial' ? theme.sidebarMaxWidth : '40%'};
  background-color: ${themed('sidebarBackground')};
`

export const Sidebar: FC = ({ children }) => (
  <SidebarColumn>
    <SidebarWrapper>{children}</SidebarWrapper>
  </SidebarColumn>
)
