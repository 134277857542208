import { Redirect, RouteComponentProps, Switch, withRouter } from 'react-router'

const signUpCode = btoa(
  JSON.stringify({
    cn: 'FlatDesignFiles',
    cs: '50-200-employees',
    e: 'test@flatfile.io',
    m: {},
    n: 'Test User',
    r: 'designer'
  })
)

export const TestHotLinkScene = withRouter(({ match: { path } }: RouteComponentProps) => (
  <Switch>
    <Redirect path={`${path}/sign-up-code`} to={`/sign-up?code=${signUpCode}`} />
    <Redirect to='/' />
  </Switch>
))
