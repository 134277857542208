import { Redirect, Route, Switch } from 'react-router-dom'
import { DevRoutes } from 'src/applications/Oversight/routes/DevRoutes'
import { HotLinkRoutes } from 'src/applications/Oversight/routes/HotLinkRoutes'
import { FLATFILE_PRODUCT_HOMEPAGE, LIVE_ENVIRONMENT_SLUG } from 'src/config'

import { SignupRoutes } from './routes/SignupRoutes'
import { TeamRoutes } from './routes/TeamRoutes'
import { WorkspaceInvitationScene } from './scenes/Concierge/WorkspaceInvitationScene'
import { ForgotPasswordScene } from './scenes/ForgotPasswordScene'
import { InviteScene } from './scenes/InviteScene'
import { LoginScene } from './scenes/LoginScene'
import { LogoutScene } from './scenes/LogoutScene'
import { MainScene } from './scenes/MainScene'
import { NotFoundScene } from './scenes/NotFoundScene'
import { RequestAccessScene } from './scenes/RequestAccessScene'
import { ResetPasswordScene } from './scenes/ResetPasswordScene'

export const routes = (
  <Switch>
    <Route component={MainScene} exact path='/' />

    <Route component={TeamRoutes} path='/a/:teamId/env/:environmentSlug' />
    <Route
      path='/a/:teamId'
      render={({ location }) => {
        // redirect all URLs of the form /a/:teamId/foo/bar
        // to /a/:teamId/env/prod/foo/bar
        const segments = location.pathname.split('/')
        const prefix = segments.splice(0, 3)
        const envUrl = [...prefix, 'env', LIVE_ENVIRONMENT_SLUG, ...segments].join('/')
        return <Redirect to={envUrl} />
      }}
    />

    <Route component={SignupRoutes} path='/sign-up' />

    {/* Workspace invite */}
    <Route component={WorkspaceInvitationScene} exact path='/workspace-invite/:token' />
    {/* Invite */}
    <Route component={InviteScene} exact path='/invite/:inviteToken' />
    {/* Login & logout */}
    <Route component={LoginScene} exact path='/login' />
    <Route component={LogoutScene} exact path='/logout' />
    {/*Password reset */}
    <Route component={ForgotPasswordScene} exact path='/forgot-password' />
    <Route component={ResetPasswordScene} exact path='/reset-password/:resetToken' />
    {/* Terms and privacy */}
    <Route
      path='/terms'
      component={(): null => {
        window.location.href = `${FLATFILE_PRODUCT_HOMEPAGE}/terms`
        return null
      }}
    />
    <Route
      path='/privacy'
      component={(): null => {
        window.location.href = `${FLATFILE_PRODUCT_HOMEPAGE}/privacy`
        return null
      }}
    />
    <Route component={HotLinkRoutes} path='/h' />
    <Route component={DevRoutes} path='/dev' />
    <Route component={RequestAccessScene} exact path='/request-access' />
    <Route component={NotFoundScene} />
  </Switch>
)
