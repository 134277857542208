import { useContext, useEffect } from 'react'
import { EmptyStateBlock } from 'src/applications/Oversight/components/EmptyState'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { Card } from 'src/resources/elements/Card'
import { PageHeaderContainer } from 'src/resources/elements/Header'
import { Spacing } from 'src/resources/layout'
import { OpenZendeskChat } from 'src/resources/sidebars/PlatformSidebar'
import { fontFamily, fontSizes } from 'src/resources/typography'
import styled, { css } from 'styled-components'
import { useListDataTabs } from 'src/applications/Oversight/hooks/useListDataTabs'
import { SQ_EMBEDS } from 'src/smart/queries/SQ_EMBEDS'
import { PortalV3Panel } from 'src/applications/Oversight/panels/Concierge/PortalV3Panel'
import { PortalV2Panel } from 'src/applications/Oversight/panels/Concierge/PortalV2Panel'
import { useLocalStorageState } from 'src/resources/hooks/useLocalStorageState'
import { QueryAlert } from 'src/resources/elements/QueryAlert'

const HeaderDescription = styled.p`
  margin-top: ${Spacing.basePadding};
  margin-left: ${Spacing.contentPadding};
  max-width: 600px;
`

const PanelContainer = styled.div`
  padding: 0 ${Spacing.basePadding4x} ${Spacing.basePadding4x};
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
`

export const Content = styled.div<{ contained?: boolean; centered?: boolean }>`
  font-size: ${fontSizes.type15};
  h1 {
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type24};
    font-weight: 600;
    margin: 0;
  }
  h2 {
    font-family: ${fontFamily.fontPrimary};
    font-size: ${fontSizes.type20};
    margin: 0;
  }

  p {
    font-family: ${fontFamily.fontSecondary};
    font-size: ${fontSizes.type17};
    line-height: 1.5;
    margin: ${Spacing.basePadding} 0 ${Spacing.basePadding3x};
    max-width: 600px;
  }

  ol {
    padding-left: ${Spacing.basePadding2x};
    li {
      padding-left: ${Spacing.basePadding};
      margin-bottom: ${Spacing.basePadding5x};
      h2 {
        font-family: ${fontFamily.fontPrimary};
        font-size: ${fontSizes.type20};
        margin: 0;
      }
    }
  }

  & + & {
    margin-top: ${Spacing.basePadding4x};
  }

  ${EmptyStateBlock} {
    border-bottom-left-radius: ${Spacing.halfBasePadding};
    border-bottom-right-radius: ${Spacing.halfBasePadding};
    margin: -${Spacing.basePadding2x} -${Spacing.basePadding5x} -${Spacing.basePadding8x};
  }

  ${(props) =>
    props.contained &&
    css`
      background-color: ${Colors.gray20};
      border-radius: 0.5rem;
      padding: ${Spacing.basePadding4x};
    `}
  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `}
`

const TextLink = styled.span`
  cursor: pointer;
  color: ${Colors.brandPrimary};
  :hover {
    color: ${Colors.purpleHover};
  }
`

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${Spacing.threeQuarterBasePadding};
  font-size: ${fontSizes.type24};
  font-weight: 600;
`

const VersionBadge = styled.span`
  display: flex;
  background-color: ${Colors.successBGColor};
  color: ${Colors.successStrong};
  border-radius: ${Spacing.roundBorderRadius};
  padding-top: 2px;
  font-size: ${fontSizes.type11};
  font-weight: 600;
  height: 20px;
  width: 34px;
  align-items: center;
  justify-content: center;
`

enum PortalVersion {
  Two = '2.0',
  Three = '3.0'
}

const PortalOnboardingPanel = () => {
  const team = useContext(TeamContext)
  const portal2Access = team.featureFlags.PRODUCT_ACCESS_PORTAL_V2
  const { hasValues, ready } = useListDataTabs(SQ_EMBEDS)
  const [portalView, setPortalView] = useLocalStorageState('portalView', undefined)
  useEffect(() => {
    if (!portalView && ready) {
      setPortalView(PortalVersion.Three)
    }
  }, [portalView, ready])
  return (
    <Card>
      <PageHeaderContainer
        header={
          <Header>
            <div>Portal</div>
            {portalView && <VersionBadge>{portalView}</VersionBadge>}
          </Header>
        }
      />
      <HeaderDescription>
        Flatfile Portal allows you to deploy a self-service data onboarding experience for your
        customers. If you're curious about designing the ideal flow,{' '}
        {/* eslint-disable-next-line react/forbid-component-props */}
        <TextLink onClick={OpenZendeskChat}>talk to us</TextLink>.
      </HeaderDescription>
      <PanelContainer>
        {!ready
          ? QueryAlert({ loading: true })
          : portalView &&
            panelForPortalVersion(portalView, hasValues, portal2Access, setPortalView)}
      </PanelContainer>
    </Card>
  )
}

const panelForPortalVersion = (
  version: PortalVersion,
  hasValues: boolean,
  portal2Access: boolean,
  setPortalView: (view: PortalVersion) => void
) => {
  switch (version) {
    case PortalVersion.Two:
      return (
        <PortalV2Panel
          showCTA={!hasValues}
          toggleView={() => setPortalView(PortalVersion.Three)}
        />
      )
    case PortalVersion.Three:
      return (
        <PortalV3Panel
          toggleView={portal2Access ? () => setPortalView(PortalVersion.Two) : undefined}
        />
      )
  }
}

export default PortalOnboardingPanel
