import { useContext, useRef } from 'react'

import { useToasts } from 'react-toast-notifications'
import { TeamContext } from 'src/contexts/TeamContext'
import { Colors } from 'src/resources/colors'
import { FormButton } from 'src/resources/elements/form/Button'
import { ColorInput } from 'src/resources/elements/form/ColorInput'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { Input } from 'src/resources/elements/form/Input'
import { ContentHeader } from 'src/resources/elements/Header'
import { NewWindow } from 'src/resources/elements/Icons'
import { queryAlert } from 'src/resources/elements/QueryAlert'
import { ExternalLink } from 'src/resources/elements/SecondarySidebarLinks'
import { SubContentContainer } from 'src/resources/elements/SubContentContainer'
import { Spacing } from 'src/resources/layout'
import { fontFamily } from 'src/resources/typography'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { useSmartQuery } from 'src/smart/hooks/useSmartQuery'
import { SM_UPDATE_TEAM_THEME } from 'src/smart/mutations/SM_UPDATE_TEAM_THEME'
import { SQ_TEAM } from 'src/smart/queries/SQ_TEAM'
import { SmartTeam_getTeam_themes } from 'src/smart/queries/types/SmartTeam'
import { EColorName, themeColors } from 'src/types/enums/EColorName'
import styled from 'styled-components'
import { validateHTMLColorHex } from 'validate-color'

const Container = styled.div`
  margin-bottom: ${Spacing.basePadding2x};
`

const DocumentationLink = styled(ExternalLink)`
  padding-right: 35px;
  display: inline-flex;

  svg {
    margin-left: 16px;
  }
`

const Color = ({ name, color }: { name: EColorName; color: { name: string; hex: string } }) => {
  const getInitialColor = (checkColor: EColorName) => {
    switch (checkColor) {
      case EColorName.PRIMARY:
        return Colors.brandPrimary
      default:
        return Colors.brandPrimary
    }
  }

  return (
    <>
      <ColorInput
        label={`${name.charAt(0).toUpperCase() + name.slice(1)} color`}
        name={name}
        placeholder={`${getInitialColor(name)}`}
        initialColor={color && color.hex ? color.hex : getInitialColor(name)}
      />
      <ErrorMessage name={name} />
    </>
  )
}

interface IEditThemeFormData {
  primary: string
  secondary: string
  interactive: string
}

const ThemeSection = ({ theme }: { theme: SmartTeam_getTeam_themes }) => {
  const team = useContext(TeamContext)
  const themeFormRef = useRef<HTMLFormElement>()
  const updateTeamTheme = useSmartMutation(SM_UPDATE_TEAM_THEME)
  const { addToast } = useToasts()
  const EditThemeFormElement: TForm<IEditThemeFormData> = Form
  const initialValue: {
    [key: string]: string
  } = {}

  const colors = Object.values(EColorName).map((name) => {
    const color = theme?.colors.find((c) => c.name === name)
    return color ?? { name, hex: '' }
  })

  Object.values(EColorName).map((name) => {
    const color = colors.find((c) => c.name === name)
    initialValue[name] = color ? color.hex : ''
  })

  initialValue.typeKitId = theme?.font?.typeKitId ?? ''
  initialValue.fontFamily = theme?.font?.fontFamily ?? ''

  const saveTheme = async (event: any) => {
    const errors: { [key: string]: string } = {}
    themeColors.forEach((name: string) => {
      if (event.data[name] && !validateHTMLColorHex(event.data[name])) {
        errors[name] = 'Invalid color'
      }
    })

    if (Object.keys(errors).length > 0) {
      event.formContext.setValue({
        errors
      })
      return
    }
    const formattedColors = Object.values(EColorName).map((color) => ({
      name: color,
      hex: event.data[color]
    }))

    const font = {
      typeKitId: event.data.typeKitId,
      fontFamily: event.data.fontFamily
    }

    const newTheme = {
      ...theme,
      themeId: theme ? theme.id : '',
      colors: formattedColors,
      font
    }
    await updateTeamTheme.run({
      teamId: team.id,
      theme: newTheme
    })

    addToast(`Embed customizations updated successfully`, {
      appearance: 'success',
      autoDismiss: true
    })
  }

  return (
    <EditThemeFormElement onSubmit={saveTheme} formRef={themeFormRef} initialValue={initialValue}>
      <ContentHeader
        header='Embed customization'
        description='Add colors and fonts to customize the appearance of your embeds. Changes made here will be applied to all of your embeds.'
      />
      <Container>
        {Object.values(EColorName).map((name) => {
          const color = colors.find((c) => c.name === name)
          return <Color key={name} name={name} color={color} />
        })}
        <Input label='Typekit project ID' name='typeKitId' placeholder='vql4cyq' />
        <ErrorMessage name='typeKitId' />
        <Input
          label='Font family'
          name='fontFamily'
          placeholder={fontFamily.fontPrimary.split(',')[0]}
        />
        <ErrorMessage name='fontFamily' />
      </Container>
      <FormButton
        primary
        submit
        buttonSibling={
          <DocumentationLink
            href='https://flatfile.com/docs/customizing-flatfile-embed/'
            target='_blank'
            rel='noopener roreferrer'
          >
            <div>Find out more details here.</div>
            <NewWindow />
          </DocumentationLink>
        }
      >
        Set global theme
      </FormButton>
    </EditThemeFormElement>
  )
}

export function TeamCustomizationPanel() {
  const team = useContext(TeamContext)

  const teamQuery = useSmartQuery(SQ_TEAM, {
    fetchPolicy: 'network-only',
    variables: {
      teamId: team.id
    }
  })

  if (teamQuery.alert) {
    return queryAlert(teamQuery)
  }

  const { themes } = teamQuery.result

  return (
    <SubContentContainer>
      <ThemeSection theme={themes[0]} />
    </SubContentContainer>
  )
}
