import { Redirect, RouteComponentProps, withRouter } from 'react-router'
import { useTeamRootUrl } from 'src/applications/Oversight/hooks/useTeamRootUrl'
import { BasicInfoContext, getBasicInfoContext } from 'src/contexts/BasicInfoContext'

export const HOT_LINK_PREFIX = '/h'

export const TeamHotLinkRedirect = withRouter(({ location }: RouteComponentProps) => {
  const {
    error,
    data: { myTeams },
    done
  } = getBasicInfoContext().value
  const teamRoot = useTeamRootUrl()

  if (error) {
    return <Redirect to={`/login${location.search}`} />
  }

  if (done) {
    const team = myTeams[0]
    if (!team) {
      return <Redirect to={`/sign-up/personalize${location.search}`} />
    }
    let destinationUrl
    if (location.pathname.includes('/env/')) {
      destinationUrl = location.pathname.replace(`${HOT_LINK_PREFIX}/a/`, `/a/${team.id}/`)
    } else {
      destinationUrl = location.pathname.replace(`${HOT_LINK_PREFIX}/a/`, `${teamRoot}/`)
    }

    window.location.pathname = destinationUrl
  }

  return null
})

export const TeamHotLinkScene = () => (
  <BasicInfoContext onTeamFoundRender={() => <TeamHotLinkRedirect />} />
)
