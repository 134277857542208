import { useEffect, useMemo, useState } from 'react'

import { components, OptionProps } from 'react-select'
import { Colors, ColorUtils } from 'src/resources/colors'
import { Select } from 'src/resources/elements/form/Select'
import { Spinner, SpinnerWrapper } from 'src/resources/elements/Spinner'
import { Filter, useTableFilters } from 'src/resources/hooks/useTableFilters'
import { inputStyles } from 'src/resources/inputs'
import { Spacing } from 'src/resources/layout'
import styled, { css, useTheme } from 'styled-components'

import { PrettyCount } from './PrettyCount'
import { themed } from 'src/applications/Embed/fragments/ThemeWrapper'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: auto;
`
const GroupItemBadge = styled.span<{ error?: boolean }>`
  padding: ${Spacing.quarterBasePadding} ${Spacing.basePadding};
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  vertical-align: middle;
  ${({ error }) =>
    error
      ? css`
          color: ${Colors.dangerStrong};
          background: ${Colors.dangerBGColor};
        `
      : css`
          color: ${themed('textColor')};
          background: ${(props) => {
            const rgba = ColorUtils.hexToRgba(
              props.theme.secondary ?? props.theme.primary ?? Colors.brandPrimary,
              0.1
            )
            return ColorUtils.rgbaToRgb(rgba)
          }};
        `}
  border-radius: ${themed('borderRadius')};
  margin-left: ${Spacing.basePadding};
  ${({ theme }) =>
    theme?.flexport &&
    css`
      background-color: transparent !important;
      padding-left: 0;
      padding-right: 0;
      &:before {
        content: '( ';
      }
      &:after {
        content: ' )';
      }
    `}
`
const GroupItem = styled.button<{ active?: boolean }>`
  align-items: center;
  border: none;
  border-radius: ${themed('borderRadius')};
  background-color: transparent;
  cursor: pointer;
  display: inline-flex;
  font-size: ${themed('buttonSize')};
  font-weight: ${inputStyles.fontWeight};
  letter-spacing: ${themed('buttonLetterSpacing')};
  padding: ${Spacing.basePadding} ${Spacing.baseAndHalfPadding};
  white-space: nowrap;
  margin-right: ${Spacing.basePadding};
  height: ${themed('buttonHeight')};

  ${({ active }) =>
    active
      ? css`
          background-color: ${(props) => {
            return props.theme.secondary !== ''
              ? props.theme.secondary
              : props.theme.primary ?? Colors.brandPrimary
          }};
          color: white;
          border-color: ${(props) => {
            return props.theme.secondary ?? props.theme.primary ?? Colors.brandPrimary
          }};
          z-index: 2;
          ${GroupItemBadge} {
            background: ${(props) => {
              const rgba = ColorUtils.hexToRgba(props.theme.primary ?? Colors.brandPrimary, 0.8)
              return ColorUtils.rgbaToRgb(rgba)
            }};
            color: inherit;
          }
        `
      : css`
          &:hover {
            background-color: ${Colors.pigeon100};
            z-index: 1;
          }
        `}
`

const Group = styled.div`
  display: inline-flex;
  margin-right: ${Spacing.baseAndHalfPadding};
`

const Option = ({ children, ...props }: OptionProps<{ count: number }, false>) => {
  return (
    <components.Option {...props}>
      {children}
      <GroupItemBadge error>{props.data.count}</GroupItemBadge>
    </components.Option>
  )
}

export interface FilterOption {
  value: Filter
  label: string
}

// text here uses the i18n keys instead of the actual text
// the values get interpolated into the t() hook below
// allRows, valid and invalid will be replaced by the theme values
// below when the theme hook is available - This ensures that
// Sage and Flexport get their custom messaging but tests get the
// proper values as well
export const availableFilters: FilterOption[] = [
  { value: '', label: 'textOverrides.rowFilter_allRows' },
  { value: 'valid', label: 'textOverrides.rowFilter_valid' },
  { value: 'invalid', label: 'textOverrides.rowFilter_error' },
  { value: 'merged', label: 'elements.filters.mergedRows' }
]

export interface IFilterWithCount {
  counts?: {
    valid?: number
    invalid?: number
    merged?: number
    totalRows?: number
  }
  errors?: Record<string, number>
  showMerged?: boolean
  schemaProperties?: any
  loading?: boolean
}

export const FilterWithCount = ({
  counts = {},
  errors: rawErrors = {},
  showMerged = false,
  schemaProperties,
  loading = false
}: IFilterWithCount) => {
  const { t } = useTranslation()
  const [badgeCounts, setBadgeCounts] = useState({} as IFilterWithCount['counts'])
  const [errorsCounts, setErrorsCounts] = useState({} as IFilterWithCount['errors'])
  const [{ filter, errors }, setTableFilters] = useTableFilters()
  const theme: any = useTheme()

  useEffect(() => {
    if (rawErrors && Object.keys(rawErrors).length) {
      setErrorsCounts(rawErrors)
    }
  }, [rawErrors])

  useEffect(() => {
    if (Object.keys(counts).length) {
      setBadgeCounts(counts)
    }
  }, [counts])

  const options = useMemo(() => {
    const fieldsErrors = Object.entries(errorsCounts)
      .map(([value, count]) => {
        return {
          value,
          label: t('elements.filters.showFieldErrors', {
            fieldName: schemaProperties?.[value]?.label ?? value
          }),
          count
        }
      })
      .sort((a, b) => b.count - a.count)

    return [
      {
        value: '',
        label: t('elements.filters.showAllErrors'),
        count: counts?.invalid ?? 0
      }
    ].concat(fieldsErrors)
  }, [errorsCounts])

  const themedAvailableFilters = availableFilters.map((opt) => {
    if (opt.value === '') {
      return { value: opt.value, label: theme.textOverrides.rowFilter_allRows }
    } else if (opt.value === 'valid') {
      return { value: opt.value, label: theme.textOverrides.rowFilter_valid }
    } else if (opt.value === 'invalid') {
      return { value: opt.value, label: theme.textOverrides.rowFilter_error }
    } else {
      return opt
    }
  })

  return (
    <Wrapper data-testid='filter-with-count'>
      <Group data-testid='filter-with-count--group'>
        {themedAvailableFilters.map((option) => {
          if (option.value === 'merged' && !showMerged) {
            return null
          }
          const badge = Object.keys(badgeCounts).length
            ? option.value === ''
              ? badgeCounts.valid + badgeCounts.invalid
              : badgeCounts[option.value]
            : undefined
          return (
            <GroupItem
              key={option.value}
              data-testid={`filter-with-count--${option.value || 'all'}`}
              active={filter === option.value}
              onClick={() => {
                if (filter === option.value) {
                  return
                }
                setTableFilters({ filter: option.value })
              }}
            >
              {t(`${option.label}`)}
              {typeof badge !== 'undefined' ? (
                <GroupItemBadge>
                  {loading ? (
                    <SpinnerWrapper>
                      <Spinner />
                    </SpinnerWrapper>
                  ) : (
                    <PrettyCount count={badge} suffix='records' />
                  )}
                </GroupItemBadge>
              ) : undefined}
            </GroupItem>
          )
        })}
      </Group>
      {filter === 'invalid' && Object.keys(errorsCounts).length && counts.invalid ? (
        <Select
          key={errors}
          defaultValue={errors}
          selectedValue={errors}
          components={{ Option }}
          onChange={(option) => {
            if (errors === option.value) {
              return
            }
            setTableFilters({ filter, errors: option.value })
          }}
          options={options}
          placeholder={t('components.FilterWithCount.showAllErrors')}
          styles={{
            control: (provided: any, _state: any) => ({
              ...provided,
              borderRadius: theme.borderRadius,
              height: theme.buttonHeight,
              minHeight: 'unset',
              padding: 'unset'
            }),
            placeholder: () => ({
              color: theme.textColor,
              fontSize: theme.buttonSize,
              fontWeight: 500
            }),
            indicatorSeparator: () => ({
              display: 'none'
            }),
            input: (provided: any) => ({
              ...provided,
              opacity: '0',
              position: 'absolute'
            }),
            menu: (provided: any) => ({
              ...provided,
              borderRadius: theme.borderRadius,
              width: '240px'
            }),
            singleValue: () => ({
              color: theme.textColor,
              fontSize: theme.buttonSize
            }),
            dropdownIndicator: (provided: any) => ({
              ...provided,
              alignItems: 'center',
              display: 'flex',
              height: `calc(${theme.buttonHeight} - (${theme.borderWidth} * 2))`,
              padding: `0 ${Spacing.basePadding}`
            }),
            valueContainer: () => ({
              color: theme.textColor,
              padding: 0,
              paddingLeft: Spacing.baseAndHalfPadding,
              overflow: 'visible'
            })
          }}
        />
      ) : null}
    </Wrapper>
  )
}
