import { ReactNode, useContext, useMemo } from 'react'

import { SplitFactory, SplitTreatments } from '@splitsoftware/splitio-react'
import { VENDOR_SPLIT_AUTHORIZATION_KEY, VENDOR_SPLIT_FORCE_LOCALHOST } from 'src/config'
import { TeamContext } from 'src/contexts/TeamContext'
import { VendorSplitTeamContext } from 'src/contexts/VendorSplitTeamContext'
import { QueryAlert } from 'src/resources/elements/QueryAlert'
import {
  getVendorSplitLocalhostFeatures,
  processVendorSplitFlags,
  VendorSplitFlagNames
} from 'src/vendor/vendorSplit'

export function VendorSplitManager({ children }: { children: ReactNode }) {
  const authorizationKey = useMemo(() => {
    return VENDOR_SPLIT_FORCE_LOCALHOST ? 'localhost' : VENDOR_SPLIT_AUTHORIZATION_KEY
  }, [])

  const features = useMemo(() => {
    return VENDOR_SPLIT_FORCE_LOCALHOST ? getVendorSplitLocalhostFeatures() : undefined
  }, [])

  const team = useContext(TeamContext)
  return (
    <SplitFactory
      config={{
        core: {
          authorizationKey,
          // we're Splitting on team id so that the same team always gets the same features
          key: team.id
        },
        features
      }}
    >
      <SplitTreatments names={Object.values(VendorSplitFlagNames)}>
        {({ treatments, isReady }) => {
          return !isReady ? (
            QueryAlert({ loading: true })
          ) : (
            <VendorSplitTeamContext.Provider
              value={{
                loading: false,
                flags: processVendorSplitFlags(isReady, treatments)
              }}
            >
              {children}
            </VendorSplitTeamContext.Provider>
          )
        }}
      </SplitTreatments>
    </SplitFactory>
  )
}
