import { VendorSplitFlagNames } from 'src/vendor/vendorSplit'
import { useMemo } from 'react'
import { useTeamSplitFlag } from 'src/applications/Oversight/hooks/useTeamSplitFlag'
import { ToolboxConfig, useToolbox } from 'src/applications/Oversight/hooks/useToolbox'
import { BlockTitle } from 'src/resources/elements/BlockTitle'
import { Icon } from 'src/resources/elements/Icon'
import { RestApiEndpointTool } from 'src/resources/tools/RestApiEndpointTool'
import { FLATFILE_REFINERY_URL } from 'src/config'
import { useParams } from 'src/resources/hooks/useParams'
import { useTableFilters } from 'src/resources/hooks/useTableFilters'

interface IUseSheetAPIExplorer {
  sheetId?: string
  schemaId: string
}

export const useSheetAPIExplorer = ({ sheetId, schemaId }: IUseSheetAPIExplorer): any => {
  const { workspaceId } = useParams<{
    workspaceId: string
  }>()
  const hasSheetApiAccess = useTeamSplitFlag(VendorSplitFlagNames.WorkspaceSheetApi)
  const [{ validationState }] = useTableFilters()

  const tools: ToolboxConfig = useMemo(
    () => ({
      enabled: sheetId && hasSheetApiAccess,
      toolsHeader: <BlockTitle>Sheet API</BlockTitle>,
      name: 'Workspace Sheet API Explorer',
      toolButton: (expanded: boolean) => (
        <>
          {expanded ? 'Hide' : 'Explore'} API <Icon name={expanded ? 'angle-up' : 'angle-down'} />
        </>
      ),
      tools: [
        {
          name: 'Add data',
          description: <p>Upload JSON records to the sheet</p>,
          render: () => (
            <RestApiEndpointTool
              key='add'
              initialBody={{ data: [] }}
              method='post'
              uri={`${FLATFILE_REFINERY_URL}/workspace/${workspaceId}/sheet/${
                sheetId ?? schemaId
              }/data`}
            />
          )
        },
        {
          name: 'Read data',
          description: <p>Load JSON records from the sheet</p>,
          render: () => (
            <RestApiEndpointTool
              key='read'
              initialBody={{ skip: 0, take: 10 }}
              method='get'
              uri={`${FLATFILE_REFINERY_URL}/workspace/${workspaceId}/sheet/${
                sheetId ?? schemaId
              }/records`}
              params={{ filter: validationState }}
            />
          )
        }
      ]
    }),
    [hasSheetApiAccess, schemaId, workspaceId, validationState]
  )

  const [toolbox, toolboxButton] = useToolbox(tools)

  return [toolbox, toolboxButton]
}
