import { useMutation } from '@apollo/client'
import { Span } from '@sentry/types'
import queryString from 'query-string'
import { withRouter } from 'react-router'
import { Carousel } from 'src/applications/Oversight/components/Carousel'
import { SignUpNavigation } from 'src/applications/Oversight/components/SignUpNavigation'
import {
  submitSentrySpan,
  useSentryTransaction
} from 'src/applications/Oversight/hooks/useSentryTransaction'
import { useTeamSplitFlag } from 'src/applications/Oversight/hooks/useTeamSplitFlag'
import { useSignUpCode } from 'src/applications/Oversight/scenes/SignUp/useSignUpCode'
import { SplitScreenTemplate } from 'src/applications/Oversight/templates/SplitScreenTemplate'
import { getUserContext } from 'src/contexts/UserContext'
import { CREATE_TEAM } from 'src/queries/CREATE_TEAM'
import { EDIT_USER } from 'src/queries/EDIT_USER'
import { CreateTeam, CreateTeamVariables } from 'src/queries/types/CreateTeam'
import { EditUser, EditUserVariables } from 'src/queries/types/EditUser'
import { FormButton } from 'src/resources/elements/form/Button'
import { ButtonGroup } from 'src/resources/elements/form/ButtonGroup'
import { ErrorMessage } from 'src/resources/elements/form/ErrorMessage'
import { Form, TForm } from 'src/resources/elements/form/Form'
import { Input, InputGroup } from 'src/resources/elements/form/Input'
import { OldSelect } from 'src/resources/elements/form/Select'
import { Header } from 'src/resources/elements/Header'
import { identifyPersonalizationSegment, trackCompanyCreated } from 'src/resources/utils/analytics'
import { VendorSplitFlagNames } from 'src/vendor/vendorSplit'

export const PersonalizeScene = () => <PersonalizePanel />

// Sentry performance tracking
const personalizeSpans: Partial<Span>[] = [
  {
    op: 'personalize-loaded'
  },
  {
    op: 'personalize-onsubmit'
  },
  {
    op: 'personalize-form-submit-success'
  }
]

export const PersonalizePanel = withRouter(({ history, location }) => {
  const [createTeam] = useMutation<CreateTeam, CreateTeamVariables>(CREATE_TEAM)
  const initialFormValue = useSignUpCode(location.search, { role: 'developer' })
  const portalFreeTrial = useTeamSplitFlag(VendorSplitFlagNames.PortalFreeTrial)
  const params: { plan?: string } = queryString.parse(location.search)
  const plan = params.plan ?? null
  const sentryTransaction = useSentryTransaction({
    spans: personalizeSpans
  })

  submitSentrySpan(sentryTransaction, 'personalize-loaded')

  const [editUser] = useMutation<EditUser, EditUserVariables>(EDIT_USER)

  const { user } = getUserContext().value

  const PersonalizeForm: TForm<{
    companyName: string
    role: string
    companySize?: string
    plan?: string
  }> = Form

  return (
    <SplitScreenTemplate rightSide={<Carousel initialSlides={[3, 2]} />}>
      <SignUpNavigation step={2} />
      <div>
        <Header>
          <h1>Personalize your experience</h1>
        </Header>
        <PersonalizeForm
          initialValue={initialFormValue}
          onSubmit={async (event) => {
            const { companyName, role, companySize } = event.data
            submitSentrySpan(sentryTransaction, 'personalize-onsubmit')

            const { meta } = initialFormValue

            if (!companyName?.trim()?.length) {
              event.formContext.setValue({
                errors: { companyName: 'Please enter a company name.' }
              })

              return
            }

            if (!role) {
              event.formContext.setValue({
                errors: { role: 'Please select a role.' }
              })

              return
            }

            if (!companySize) {
              event.formContext.setValue({
                errors: { companySize: 'Please select a company size.' }
              })

              return
            }

            try {
              const teamRes = await createTeam({
                variables: {
                  meta,
                  name: companyName,
                  companySize,
                  noPlan: false,
                  freeTrial: portalFreeTrial,
                  plan
                }
              })
              await editUser({
                variables: { id: user.id, type: role }
              })
              let companySizeNumber = 0
              switch (companySize) {
                case 'self-employed':
                  companySizeNumber = 0
                case '1-10-employees':
                  companySizeNumber = 1
                case '10-50-employees':
                  companySizeNumber = 10
                case '50-200-employees':
                  companySizeNumber = 50
                case '200-1000-employees':
                  companySizeNumber = 200
                case '1000-more-employees':
                  companySizeNumber = 1000
              }

              identifyPersonalizationSegment({
                companyId: teamRes.data.createTeam.id,
                companyName,
                companySize: companySizeNumber,
                email: user.email,
                id: user.id,
                name: user.name,
                intercomHash: user.intercomHash,
                role
              })
              trackCompanyCreated(user, teamRes.data.createTeam, plan)
              if (user && teamRes.data.createTeam && plan) {
                submitSentrySpan(sentryTransaction, 'personalize-formsubmit-success')
              }

              history.push(`/a/${teamRes.data.createTeam.id}/templates?welcome=true`)
            } catch (e) {
              console.error(e)
            }
          }}
        >
          <InputGroup>
            <Input placeholder='Acme Co.' label='Company name' name='companyName' />
            <ErrorMessage name='companyName' />
          </InputGroup>

          <InputGroup>
            <OldSelect placeholder='Select your role...' label='Role' name='role'>
              <option value=''>Select role</option>
              <option value='product-manager'>Product Manager</option>
              <option value='developer'>Developer</option>
              <option value='support'>Organization Support</option>
              <option value='designer'>Designer</option>
              <option value='executive'>Executive</option>
              <option value='other'>Other</option>
            </OldSelect>
            <ErrorMessage name='role' />
          </InputGroup>

          <InputGroup>
            <OldSelect placeholder='Company size' label='Company size' name='companySize'>
              <option value=''>Select size</option>
              <option value='self-employed'>Self-employed</option>
              <option value='1-10-employees'>1-10 employees</option>
              <option value='10-50-employees'>10-50 employees</option>
              <option value='50-200-employees'>50-200 employees</option>
              <option value='200-1000-employees'>200-1000 employees</option>
              <option value='1000-more-employees'>1000+ employees</option>
            </OldSelect>
            <ErrorMessage name='companySize' />
          </InputGroup>

          <ButtonGroup stretch>
            <FormButton primary submit>
              Get started
            </FormButton>
          </ButtonGroup>
        </PersonalizeForm>
      </div>
    </SplitScreenTemplate>
  )
})
