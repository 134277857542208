import { Colors } from 'src/resources/colors'
import { css } from 'styled-components'

import { IFlatButtonStyle } from '../IFlatButton'

export const DangerFlatButtonStyle = css<IFlatButtonStyle>`
  ${({ variant }) => {
    switch (variant) {
      case 'empty':
        return css`
          background-color: transparent !important;
          color: ${Colors.dangerStrong} !important;

          &:hover {
            color: ${Colors.redDark} !important;
          }
        `
      case 'outlined':
        return css`
          background-color: transparent !important;
          border-color: ${Colors.dangerStrong} !important;
          color: ${Colors.dangerStrong} !important;

          &:hover {
            background-color: ${Colors.dangerStrong} !important;
            color: white !important;
          }
        `
      default:
        return css`
          background-color: ${Colors.dangerStrong} !important;
          color: ${Colors.white} !important;

          &:hover {
            background-color: ${Colors.redDark} !important;
          }
        `
    }
  }}
`
