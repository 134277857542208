export const localeEn = {
  components: {
    DataPreview: {
      dataPreview: 'Data preview',
      dataPreviewFor: 'Data preview for {{fieldLabel}}',
      hoverLineBeginning: 'Hover over a line to the left ',
      hoverLineEnd: 'for more detail.',
      noData: 'No data',
      totalCount_one: '{{count}} total row',
      totalCount_other: '{{count}} total rows, ',
      valueCount_one: ' {{count}} has a value',
      valueCount_other: ' {{count}} have a value'
    },
    DataSourceTable: {
      sheetTitle: 'Sheet Title'
    },
    Dropzone: {
      dropFileAlt: 'Drop file here',
      dropFileBeginning: 'Drop your file ',
      dropFileEnd: 'anywhere to upload',
      uploadFailedHeader: 'Upload failed',
      uploadFailedContent_one:
        'The file {{fileName}} that you uploaded is larger than 1GB. Please reformat your file to be smaller than 1GB and reupload your file.',
      uploadFailedContent_other:
        'The files {{fileName}} that you uploaded are larger than 1GB. Please reformat your files to be smaller than 1GB and reupload your files.'
    },
    EmbedNextAction: {
      processingFile: 'Processing {{fileName}}...',
      csvConvert:
        'This might take longer than 3 min. Convert into a CSV file for faster processing.'
    },
    ExcludedBlock: {
      columnExcluded_one:
        'The {{count}} column listed below is excluded from matching. Click on a column name to add it back to the list above or',
      columnExcluded_other:
        'The {{count}} columns listed below are excluded from matching. Click on a column name to add it back to the list above or',
      addAllLink: ' add all.'
    },
    FileUploader: {
      orBlock: 'or'
    },
    FilterWithCount: {
      showAllErrors: 'Show all errors'
    },
    RecordStatusBanner: {
      rejectedDescription_one:
        'You have {{count}} record that was rejected by the server. You can either dismiss it and wrap up, or correct it to re-import.',
      rejectedDescription_other:
        'You have {{count}} record that was rejected by the server. You can either dismiss all of them and wrap up, or correct them to re-import.',
      titleSuccess_one: '{{count}} record imported successfully,',
      titleSuccess_other: '{{count}} records imported successfully,',
      titleRejected_one: ' {{count}} rejected record',
      titleRejected_other: ' {{count}} rejected records',
      discardAndFinish: 'Discard all and finish!'
    }
  },
  elements: {
    buttons: {
      cancel: 'Cancel',
      continue: 'Continue',
      selectFile: 'Select file',
      backToMatching: 'Back to matching',
      reviewRejected: 'Review rejected records',
      startOver: 'Start over',
      submit: 'Submit'
    },
    filters: {
      allRows: 'All rows',
      validRows: 'Valid',
      invalidRows: 'Error',
      mergedRows: 'Most recently merged',
      showAllErrors: 'Show all errors',
      showFieldErrors: '{{fieldName}} errors'
    },
    loaders: {
      importing: 'Importing',
      initializing: 'Initializing your importer',
      loading: 'Loading',
      loadingData: 'Loading your data',
      normalizing: 'Normalizing data',
      preparing: 'Preparing your data for review',
      processing: 'Processing',
      saving: 'Saving',
      scanning: 'Scanning for inconsistencies'
    }
  },
  errors: {
    toasts: {
      cancelProcessing: 'Unable to cancel processing'
    }
  },
  fragments: {
    EmbedsErrorBanner: {
      dataHooksErr: {
        title: 'Auto reformatting error',
        message:
          'Unable to complete automated reformatting. Hover over any error for guidance on correcting a value manually. Reach out to us if you need further assistance.'
      },
      allInvalidErr: {
        title: 'No valid records',
        message: 'You need at least one valid record to be able to import data.'
      }
    },
    Layout: {
      mobileDevice: 'Please use a device with a larger screen to continue this workflow.'
    },
    SkipHeaderRow: {
      skipHeaderRow: 'Skip and use alphabets as headers.',
      noHeaderRow: "Don't have a header row?"
    }
  },
  hooks: {
    useEmbedContinueFlow: {
      pickUp: 'Pick up where you left off?'
    },
    useConfirmDismissModal: {
      dismissHeader: 'Dismiss record(s)',
      dismissLabel: 'Dismiss',
      paragraphOne: 'Dismissing these records will remove linked values for {{message}}.',
      paragraphTwo: 'Do you wish to dismiss them?',
      linkRecords: '{{countRecords}} records in {{schemaName}} sheet'
    }
  },
  scenes: {
    '1_InitialScene': {
      uploadingFile: 'Uploading your file',
      nonWhiteLabel: "You're securely onboarding data with {{company}}"
    },
    '5_ReviewScene': {
      loadingData: 'Processing your beautiful data...',
      cancelLabel: 'Discard and continue',
      confirmLabel: 'Resolve errors',
      header: 'Discard records with errors',
      discardBeginning: 'To continue, discard ',
      discardMessage_one: '{{count}} record ',
      discardMessage_other: '{{count}} records ',
      discardEnd: 'with unresolved errors.',
      discardSelected_one: 'Discard {{count}} selected row',
      discardSelected_other: 'Discard {{count}} selected rows',
      importRecords_zero: 'No valid records',
      importRecords_one: 'Import {{count}} record',
      importRecords_other: 'Import {{count}} records',
      saveAndFinalize: 'Save and finalize',
      generalEmptyState: 'Something went wrong',
      specificEmptyState:
        "We're having trouble loading your data. You may be trying to import a file with no data, or having a problem with a data hook. Please try refreshing the page, and contact us if the problem persists."
    },
    '6_ErrorScene': {
      importError: 'Something went wrong during the import.'
    },
    '7_FinalScene': {
      showReviewMessage:
        'Some of your data was not accepted by {{teamName}}. Preview rejected data in the table below and choose whether to review and correct this data or discard this data and finish your import.',
      successfulImport_one: 'You have imported {{count}} record!',
      successfulImport_other: 'You have imported {{count}} records!',
      numberSubmitted_one: '{{count}} record submitted!',
      numberSubmitted_other: '{{count}} records submitted!',
      importedStatusLabel: 'Imported',
      rejectedStatusLabel: 'Rejected',
      rowsRemaining: 'Rows remaining'
    },
    MatchingV3: {
      header: 'Are you sure?',
      confirmLabel: 'Yes, proceed',
      cancelLabel: 'No, cancel',
      contentsBeforeBreak: 'Your changes will affect the data in the next step.',
      contentsAfterBreak: 'Would you like to proceed?',
      columnHeaders: 'Columns in your file',
      unmatchedRequiredFields: 'Unmatched required field(s):',
      numberUnmatched: ' unmatched',
      emptyState: "You don't have any active columns.",
      excludeColumnMatch: 'Exclude this column from matching'
    }
  },
  textOverrides: {
    dragAndDropHint: 'Drop your file here',
    fileTypeHint: 'And other similar file types',
    headerCheck: 'Change or confirm header selection',
    headerCheckDesc: '',
    headerInitial: 'Upload your file',
    headerMatch: 'Change or confirm column matches',
    headerMatchDesc: '',
    headerReview: 'Review and finalize',
    headerReviewDesc: '',
    headerSource: 'Select the sheet you want to upload',
    headerSourceDesc: '',
    templateFieldsLabel: 'Template fields',
    ctaLabelFinal: '',
    rowFilter_allRows: 'All rows',
    rowFilter_valid: 'Valid',
    rowFilter_error: 'Error'
  },
  themes: {
    flexport: {
      dragAndDropHint: '',
      headerInitial: 'Upload your file',
      fileTypeHint: 'Drag and drop your file here',
      headerSource: 'Select the sheet you want to upload',
      headerSourceDesc: '',
      headerCheck: 'Select your header row',
      headerCheckDesc: '',
      headerCheckAction: '',
      headerMatch: 'Match file columns to our fields',
      headerMatchDesc: '',
      headerReview: 'Review and make final changes',
      headerReviewDesc: '',
      templateFieldsLabel: 'Our fields',
      ctaLabelFinal: 'Continue',
      rowFilter_allRows: 'All rows',
      rowFilter_valid: 'Valid rows',
      rowFilter_error: 'Rows with errors'
    },
    sage: {
      headerInitial: 'Upload your file',
      dragAndDropHint: 'Drop your file here',
      fileTypeHint: 'And other similar file types',
      headerSource: 'Select the sheet you want to upload',
      headerSourceDesc:
        'We found multiple sheets in this file. Which sheet contains the client data to import?',
      headerCheck: 'Select your header row',
      headerCheckDesc: 'Select the header row in your data to make column labels, or',
      headerCheckAction: 'skip and use letters as headers.',
      headerMatch: 'Match file columns to our fields',
      headerMatchDesc:
        'To reduce the potential for errors, tell us which of our fields relate to which of your spreadsheet columns.',
      headerReview: 'Review and make final changes',
      headerReviewDesc:
        'If we’ve highlighted any errors, resolve these before completing the import.',
      templateFieldsLabel: 'Our fields',
      ctaLabelFinal: 'Continue to Sage',
      rowFilter_allRows: 'All rows',
      rowFilter_valid: 'Valid rows',
      rowFilter_error: 'Rows with errors'
    }
  },
  miscEmbeds: {
    devMode: 'Developer mode'
  }
}

export const localeFr = {
  components: {
    DataPreview: {
      dataPreview: 'Aperçu des données',
      dataPreviewFor: 'Aperçu des données pour {{fieldLabel}}',
      hoverLineBeginning: 'Survolez une ligne à gauche ',
      hoverLineEnd: 'pour plus de détails.',
      noData: 'Aucune donnée',
      totalCount_one: '{{count}}  ligne au total',
      totalCount_other: '{{count}} lignes au total, ',
      valueCount_one: ' {{count}} possède une valeur',
      valueCount_other: ' {{count}}  possèdent une valeur'
    },
    DataSourceTable: {
      sheetTitle: 'Titre de la feuille'
    },
    Dropzone: {
      dropFileAlt: 'Déposez un fichier ici',
      dropFileBeginning: 'Déposez votre fichier ',
      dropFileEnd: "n'importe où pour l'importer",
      uploadFailedContent_one:
        "Le fichier {{fileName}} que vous avez importé fait plus de 1 Go. Veuillez le reformater afin qu'il soit inférieur à 1 Go et l'importer de nouveau.",
      uploadFailedContent_other:
        "Les fichiers {{fileName}} que vous avez importés font plus de 1 Go. Veuillez les reformater afin qu'ils soient inférieurs à 1 Go et les importer de nouveau.",
      uploadFailedHeader: "Échec de l'importation"
    },
    EmbedNextAction: {
      csvConvert:
        'Il se peut que cela prenne plus de trois minutes. Convertissez le fichier en CSV pour que le traitement soit plus rapide.',
      processingFile: '{{fileName}} en cours de traitement...'
    },
    ExcludedBlock: {
      addAllLink: ' ajoutez-les toutes.',
      columnExcluded_one:
        "La {{count}} colonne listée ci-dessous est exclue de la correspondance. Cliquez sur le nom d'une colonne pour la rajouter à la liste ci-dessus, ou",
      columnExcluded_other:
        "Les {{count}} colonnes listées ci-dessous sont exclues de la correspondance. Cliquez sur le nom d'une colonne pour la rajouter à la liste ci-dessus, ou"
    },
    FileUploader: {
      orBlock: 'ou'
    },
    FilterWithCount: {
      showAllErrors: 'Afficher toutes les erreurs'
    },
    RecordStatusBanner: {
      discardAndFinish: 'Tout abandonner et terminer !',
      rejectedDescription_one:
        "{{count}} enregistrement a été rejeté par le serveur. Vous pouvez soit l'ignorer et poursuivre, soit le corriger pour l'importer de nouveau.",
      rejectedDescription_other:
        "{{count}} enregistrements ont été rejetés par le serveur. Vous pouvez soit les ignorer et poursuivre, soit les corriger pour l'importer de nouveau.",
      titleRejected_one: ' {{count}} enregistrement rejeté',
      titleRejected_other: ' {{count}} enregistrements rejetés',
      titleSuccess_one: '{{count}} enregistrement importé avec succès,',
      titleSuccess_other: '{{count}} enregistrements importés avec succès,'
    }
  },
  elements: {
    buttons: {
      backToMatching: 'Revenir à la correspondance',
      cancel: 'Annuler',
      continue: 'Continuer',
      reviewRejected: 'Examiner les enregistrements rejetés',
      selectFile: 'Sélectionner un fichier',
      startOver: 'Recommencer',
      submit: 'Envoyer'
    },
    filters: {
      allRows: 'Toutes les lignes',
      invalidRows: 'Erreur',
      mergedRows: 'Les plus récemment fusionnées',
      showAllErrors: 'Afficher toutes les erreurs',
      showFieldErrors: 'erreurs de {{fieldName}}',
      validRows: 'Valides'
    },
    loaders: {
      importing: "En cours d'importation",
      initializing: 'Initialisation de votre importateur en cours',
      loading: 'Chargement',
      loadingData: 'Chargement de vos données',
      normalizing: 'Réduction de vos données',
      preparing: 'Préparation de vos données en cours pour vérification',
      processing: 'En cours de traitement',
      saving: 'Enregistrement',
      scanning: 'Recherche des incohérences'
    }
  },
  errors: {
    toasts: {
      cancelProcessing: "Impossible d'annuler le traitement"
    }
  },
  fragments: {
    EmbedsErrorBanner: {
      allInvalidErr: {
        messsage:
          'Vous devez avoir au moins un enregistrement valide pour pouvoir importer des données.',
        title: 'Aucun enregistrement valide'
      },
      dataHooksErr: {
        messsage:
          "Impossible de terminer le reformatage automatisé. Faites passer la souris sur une erreur pour obtenir des indications permettant de corriger manuellement une valeur. N'hésitez pas à nous contacter si vous avez besoin d'une assistance supplémentaire.",
        title: 'Erreur de reformatage automatique'
      }
    },
    Layout: {
      mobileDevice:
        'Veuillez utiliser un appareil avec un écran plus grand pour continuer sur ce flux de travail.'
    },
    SkipHeaderRow: {
      noHeaderRow: "Vous n'avez pas de ligne d'en-tête ?",
      skipHeaderRow: 'Ignorez et utilisez des signes alphabétiques comme en-têtes.'
    }
  },
  hooks: {
    useConfirmDismissModal: {
      dismissHeader: 'Rejeter les enregistrements',
      dismissLabel: 'Rejeter',
      linkRecords: '{{countRecords}} enregistrements dans la feuille {{schemaName}}',
      paragraphOne: 'Rejeter ces enregistrements supprimera les valeurs liées pour {{message}}.',
      paragraphTwo: 'Souhaitez-vous les rejeter ?'
    },
    useEmbedContinueFlow: {
      pickUp: 'Reprendre où vous en étiez ?'
    }
  },
  miscEmbeds: {
    devMode: 'Mode développeur'
  },
  scenes: {
    '1_InitialScene': {
      nonWhiteLabel:
        "Vous êtes en train d'intégrer des données en toute sécurité avec {{company}}",
      uploadingFile: 'Téléchargement de votre fichier'
    },
    '5_ReviewScene': {
      cancelLabel: 'Rejeter et continuer',
      confirmLabel: 'Résoudre les erreurs',
      discardBeginning: 'Pour continuer, rejeter ',
      discardEnd: 'avec des erreurs non résolues',
      discardMessage_one: '{{count}} enregistrement ',
      discardMessage_other: '{{count}} enregistrements ',
      discardSelected_one: 'Rejeter {{count}} ligne sélectionnée',
      discardSelected_other: 'Rejeter {{count}} lignes sélectionnées',
      generalEmptyState: "Une erreur s'est produite",
      header: 'Rejeter les enregistrements contenant des erreurs',
      importRecords_one: 'Importer {{count}} enregistrement',
      importRecords_other: 'Importer {{count}} enregistrements',
      importRecords_zero: 'Aucun enregistrement valide',
      loadingData: 'Traitement de vos belles données en cours...',
      saveAndFinalize: 'Enregistrer et finaliser',
      specificEmptyState:
        "Nous avons des difficultés à charger vos données. Il se peut que vous essayiez d'importer un fichier sans données, ou que vous ayez un problème avec un crochet de données. Essayez de rafraîchir la page et contactez-nous si le problème persiste."
    },
    '6_ErrorScene': {
      importError: "Une erreur s'est produite au moment de l'importation"
    },
    '7_FinalScene': {
      importedStatusLabel: 'Importé(s)',
      numberSubmitted_one: '{{count}} enregistrement envoyé !',
      numberSubmitted_other: '{{count}} enregistrements envoyés !',
      rejectedStatusLabel: 'Rejeté(s)',
      rowsRemaining: 'Lignes restantes',
      showReviewMessage:
        "Certaines de vos données n'ont pas été acceptées par {{teamName}}. Prévisualisez les données rejetées dans le tableau ci-dessous et décidez si vous  souhaitez les examiner et les corriger ou les rejeter et mettre fin à votre importation.",
      successfulImport_one: 'Vous avez importé {{count}} enregistrement !',
      successfulImport_other: 'Vous avez importé {{count}} enregistrements !'
    },
    MatchingV3: {
      cancelLabel: 'Non, annuler',
      columnHeaders: 'Nombre de colonnes dans votre fichier',
      confirmLabel: 'Oui, continuer',
      contentsAfterBreak: 'Voulez-vous continuer ?',
      contentsBeforeBreak: 'Vos modifications affecteront les données lors de la prochaine étape.',
      emptyState: "Vous n'avez aucune colonne active.",
      excludeColumnMatch: 'Exclure cette colonne de la mise en correspondance',
      header: 'Êtes-vous sûr ?',
      numberUnmatched: ' sans correspondance',
      unmatchedRequiredFields: 'Champ(s) requis non apparié(s) :'
    }
  },
  textOverrides: {
    dragAndDropHint: 'Déposez votre fichier ici',
    fileTypeHint: "Et d'autres types de fichiers similaires",
    headerCheck: 'Modifiez ou confirmez la sélection de titres',
    headerCheckDesc: '',
    headerInitial: 'Téléversez votre fichier',
    headerMatch: 'Modifiez ou confirmez les correspondances des colonnes',
    headerMatchDesc: '',
    headerReview: 'Vérifiez et finalisez',
    headerReviewDesc: '',
    headerSource: 'Sélectionnez la feuille que vous souhaitez téléverser',
    headerSourceDesc: '',
    templateFieldsLabel: 'Champs du modèle',
    ctaLabelFinal: ''
  },
  themes: {
    flexport: {
      dragAndDropHint: '',
      ctaLabelFinal: 'Continuer',
      fileTypeHint: 'Faites glisser et déposez votre fichier ici',
      headerCheck: 'Sélectionnez votre ligne de titres',
      headerCheckDesc: '',
      headerInitial: 'Téléversez votre fichier',
      headerMatch: 'Faites correspondre les colonnes du fichier à nos champs',
      headerMatchDesc: '',
      headerReview: 'Vérifiez et apportez les modifications finales',
      headerReviewDesc: '',
      headerSource: 'Sélectionnez la feuille que vous souhaitez téléverser',
      headerSourceDesc: '',
      templateFieldsLabel: 'Nos champs'
    },
    sage: {
      ctaLabelFinal: 'Continuer sur Sage',
      dragAndDropHint: 'Déposez votre fichier ici',
      fileTypeHint: "Et d'autres types de fichiers similaires",
      headerCheck: 'Sélectionnez votre ligne de titres',
      headerCheckDesc:
        "Une ligne de titres sert à intituler chaque colonne. Cliquez sur le numéro de la ligne contenant les intitulés de vos colonnes. Vous ne disposez pas d'une telle ligne ?",
      headerInitial: 'Téléversez votre fichier',
      headerMatch: 'Faites correspondre les colonnes du fichier à nos champs',
      headerMatchDesc:
        "Pour réduire le risque d'erreurs, indiquez-nous les correspondances entre nos champs et les colonnes de votre feuille de calcul.",
      headerReview: 'Vérifiez et apportez les modifications finales',
      headerReviewDesc:
        "Si nous avons mis en évidence des erreurs, corrigez-les avant de terminer l'importation.",
      headerSource: 'Sélectionnez la feuille que vous souhaitez téléverser',
      headerSourceDesc:
        'Nous avons trouvé plusieurs feuilles dans ce fichier. Laquelle contient les données client à importer ?',
      templateFieldsLabel: 'Nos champs'
    }
  }
}
