import { DefaultTheme } from 'src/applications/Embed/fragments/ThemeWrapper'
import {
  FlexportFileTypeIcon,
  FlexportUploadIcon
} from 'src/applications/Embed/themes/Flexport/Icons'

const FlexportFontStack = `"gt-america", "GT America", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif`

export const FlexportTheme: Partial<typeof DefaultTheme & { fontFamily: string }> = {
  // General
  showDataSourceStatus: false,
  showMatchIndicator: true,
  placeholderColor: '#67768D',
  rowHoverColor: '#F7F9FD',
  borderColor: '#CAD0DC',
  borderWidth: '2px',
  primary: '#566AE5',
  primaryAccent: 'transparent',
  secondary: '#4B5564',
  textColor: '#39414D',
  contentFontSize: '16px',
  contentSmallFontSize: '14px',
  borderRadius: 'initial',
  largeBorderRadius: '32px',
  boxShadow: false,
  boxShadowSpec: '0px 2px 16px rgba(0, 0, 0, 0.05)',
  largeBoxShadow: '0 0 2px rgba(0, 0, 0, 0.12), 0 10px 10px rgba(0, 0, 0, 0.12)',
  customFontStack: FlexportFontStack,
  cardsHaveBorder: false,
  invertedFileUpload: true,
  sidebarBackground: '#F7F9FD',
  // Buttons
  buttonHeight: '30px',
  buttonWeight: '600',
  buttonSize: '14px',
  buttonPadding: '4px 12px',
  buttonLineHeight: '1.3',
  buttonLetterSpacing: '0',
  // Footer
  footerBackgroundColor: '#FCFCFC',
  footerHeight: '64px',
  footerGutter: '56px',
  footerDirection: 'row-reverse',
  footerAlignment: 'flex-end',
  gutterSpacing: '56px',
  footerButtonHeight: 'initial',
  footerButtonWidth: 'initial',
  // Page header
  headerIconBoxSize: '32px',
  headerIconSize: '32px',
  invertedIcon: true,
  titleFontSize: '32px',
  titleFontWeight: '700',
  titleFontColor: '#39414D',
  // Menu select
  dropdownHeader_textTransform: 'capitalize',
  dropdownHeader_textColor: '#39414D',
  dropdownHeader_fontSize: '14px',
  dropdownHeader_fontWeight: '600',
  dropdownHeader_letterSpacing: '0',
  // Modals
  closeModalButtonStyles: { top: 30 },
  modalContentStyle: {
    background: '#FFF',
    boxShadow: '0 -4px 12px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '-24px',
    marginLeft: '-24px',
    marginRight: '-24px',
    padding: '16px 24px'
  },
  modalHeaderStyles: {
    marginRight: '-24px',
    marginLeft: '-24px',
    padding: '0 24px'
  },
  modalHeaderTitleStyles: { fontWeight: 700, fontSize: 24 },
  modalPrimaryButtonStyle: {
    border: '2px solid transparent',
    padding: '4px 12px',
    lineHeight: 1.3
  },
  modalSecondaryButtonStyle: {
    border: '2px solid #566AE5 !important',
    color: '#566AE5 !important',
    background: 'transparent !important',
    padding: '4px 12px',
    lineHeight: 1.3
  },
  modalBackgroundColor: '#FFF',
  // Turntable
  dataTable: {
    fontFamily: FlexportFontStack,
    border: {
      active: '#566AE5'
    },
    indexColumn: {
      backgroundColor: '#F7F9FD',
      selected: {
        color: 'rgba(86, 105, 229, 1)'
      }
    },
    table: {
      borderColor: '#C5D2E7'
    },
    cell: {
      borderColor: '#C5D2E7',
      color: '#67768D',
      active: {
        borderColor: '#5669e5'
      },
      selected: {
        backgroundColor: 'rgba(86, 105, 229, 0.1)'
      }
    },
    column: {
      header: {
        backgroundColor: '#F7F9FD',
        color: '#39414D'
      }
    }
  },
  // TH on matching, header selection, etc.
  tableListHeader_FontSize: '14px',
  tableListHeader_FontWeight: '700',
  tableListHeader_LineHeight: '1.7',
  tableListHeader_LetterSpacing: '0',
  tableListHeader_Color: '#39414D',
  // TD in matching, header selection, etc.
  tableListItem_FontSize: '16px',
  tableListItem_FontWeight: '700',
  tableListItem_LineHeight: '1.5',
  tableListItem_LetterSpacing: '0',
  tableListItem_Color: '#67768D',
  // Text overrides
  textOverrides: {
    headerInitial: 'themes.flexport.headerInitial',
    dragAndDropHint: 'themes.flexport.dragAndDropHint',
    fileTypeHint: 'themes.flexport.fileTypeHint',
    headerSource: 'themes.flexport.headerSource',
    headerSourceDesc: 'themes.flexport.headerSourceDesc',
    headerCheck: 'themes.flexport.headerCheck',
    headerCheckDesc: 'themes.flexport.headerCheckDesc',
    headerCheckAction: 'themes.flexport.headerCheckAction',
    headerMatch: 'themes.flexport.headerMatch',
    headerMatchDesc: 'themes.flexport.headerMatchDesc',
    headerReview: 'themes.flexport.headerReview',
    headerReviewDesc: 'themes.flexport.headerReviewDesc',
    templateFieldsLabel: 'themes.flexport.templateFieldsLabel',
    ctaLabelFinal: 'themes.flexport.ctaLabelFinal',
    rowFilter_allRows: 'themes.flexport.rowFilter_allRows',
    rowFilter_valid: 'themes.flexport.rowFilter_valid',
    rowFilter_error: 'themes.flexport.rowFilter_error'
  },
  iconOverrides_Upload: FlexportFileTypeIcon,
  iconOverrides_Header: FlexportUploadIcon,
  externalCSS:
    'https://static.flexport.com/assets/1/app/gt_america_import-f303ba8e6d9709d236d80ff2dc840cc6e007d59c79f62ca5bdb51b2597d23585.css'
}
