import { useCallback } from 'react'

import { EditDataHook } from 'src/applications/Oversight/components/EditDataHook'
import { useActiveArchivedTabs } from 'src/applications/Oversight/hooks/useActiveArchivedTabs'
import { getSchemaFunctions } from 'src/queries/GET_SCHEMA_FUNCTIONS'
import { Colors } from 'src/resources/colors'
import { ResourceBrowser, TResourceBrowser } from 'src/resources/elements/ResourceBrowser'
import { TextSymbol } from 'src/resources/elements/TextSymbol'
import { useSearchParam } from 'src/resources/hooks/useSearchParam'
import { Spacing } from 'src/resources/layout'
import { useSmartMutation } from 'src/smart/hooks/useSmartMutation'
import { SM_CREATE_SCHEMA_FUNCTION } from 'src/smart/mutations/SM_CREATE_SCHEMA_FUNCTION'
import { ISchema, ISchemaFunction } from 'src/types/interfaces/ISchema'
import styled from 'styled-components'

const DataHookResourceBrowser: TResourceBrowser<ISchemaFunction> = ResourceBrowser

const Container = styled.div`
  height: calc(100vh - 300px);
`

const StyledLink = styled.a`
  cursor: pointer;
`

const ListItem = styled.div<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? Colors.blue : Colors.transparent)};
  color: ${({ selected }) => (selected ? Colors.white : Colors.defaultText)};
  cursor: default;
  padding: ${Spacing.basePadding};
  user-select: none;
`

function DataHookListItem({
  onSelect,
  resource,
  selected
}: {
  resource: ISchemaFunction
  selected: boolean
  onSelect(id: string): void
}) {
  return (
    <ListItem selected={selected} onClick={() => onSelect(resource.id)}>
      {resource.name ?? <i>Untitled</i>}
    </ListItem>
  )
}

export function ManageDataHooksPanel({
  schema,
  onSchemaIdUpdate
}: {
  schema: ISchema
  onSchemaIdUpdate(id: number | string, tab: string): void
}) {
  const initialSelectedResourceId = useSearchParam.string('dataHookId', null)
  const activeArchived = useActiveArchivedTabs({ center: true, removeAllParams: false })
  const dataHooks = getSchemaFunctions(schema.id, activeArchived.showArchived)
  const createDataHook = useSmartMutation(SM_CREATE_SCHEMA_FUNCTION)

  const create = useCallback(async () => {
    const hooks = dataHooks?.data?.getSchema?.getSchemaFunctions ?? []
    const highestOrder = Math.max(0, hooks.length, ...hooks.map((x) => x.order))
    const newHook = await createDataHook.run({
      order: highestOrder + 1,
      schemaId: schema.id
    })
    if (String(newHook.schemaId) !== String(schema.id)) {
      onSchemaIdUpdate(newHook.schemaId.toString(10), `data-hooks`)
    }
    await dataHooks.refetch({ archived: activeArchived.showArchived, schemaId: schema.id })
    return newHook
  }, [dataHooks?.data?.getSchema?.getSchemaFunctions])

  return (
    <Container>
      <DataHookResourceBrowser
        initialSelectedResourceId={initialSelectedResourceId}
        resourceListWidth={320}
        onCreateResource={create}
        renderDetail={({ resource }) => <EditDataHook key={resource.id} resource={resource} />}
        renderListHead={activeArchived.tabs}
        renderListItem={DataHookListItem}
        resourceList={dataHooks?.data?.getSchema?.getSchemaFunctions}
        resourceName={
          <>
            Data Hook
            <TextSymbol reg />
          </>
        }
        noResourceSelected={
          <>
            Not sure where to start?{' '}
            <StyledLink href='https://flatfile.com/docs/data-hooks-v3/' target='_blank'>
              Learn more here
            </StyledLink>
          </>
        }
        showAddResource={activeArchived.showActive}
      />
    </Container>
  )
}
